import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { FC } from "react";
import { AssetCatList, CommonType, WoTypesData } from "../types/workOrderTypes";
import MediumTypography from "../../../components/formlib/MediumTypography";

interface AssetCategoryProps {
  assetCategoryList: AssetCatList[];
  id: number;
  frequencyId: number;
  woTypeId: number;
  woTypeList: WoTypesData[];
  subTypeId: number;
  handleSelectedAssetCategory: (id: number) => void;
  handleSelectedFrequency: (id: number) => void;
  handleApply: () => void;
  frequencyList: CommonType[];
}

const muiContainer = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const muiItems = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 2,
};

const AssetCategorySelection: FC<AssetCategoryProps> = ({
  assetCategoryList,
  id,
  frequencyId,
  handleSelectedAssetCategory,
  handleSelectedFrequency,
  handleApply,
  woTypeId,
  woTypeList,
  frequencyList,
  subTypeId,
}) => {
  const selectedWOType = woTypeList.find((woType) => woType.id === woTypeId);
  return (
    <>
      <Grid item {...muiContainer}>
        <Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid #545558",
            }}
          />
        </Box>
      </Grid>
      <Grid container direction={"row"} className="p-md" spacing={2}>
        {assetCategoryList.map((item) => {
          return (
            <Grid item key={item.id} {...muiItems}>
              <Box
                sx={{
                  backgroundColor: id === item.id ? "#0C66E4" : "#22272B",
                  borderRadius: "4px",
                  border: "1px solid rgba(166, 197, 226, 0.16)",
                }}
                className="p-sm cursor__pointer"
                onClick={() => handleSelectedAssetCategory(item.id)}
              >
                <Box className="flex__ p-sm" sx={{ justifyContent: "center" }}>
                  <MediumTypography
                    label={item.name}
                    className={"ml-sm"}
                    textColor={id === item.id ? "#fff" : "#8C9BAB"}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {id !== null &&
        id !== 0 &&
        woTypeId !== woTypeList.find((item) => item.code === "CM")?.id &&
        frequencyList &&
        frequencyList.length > 0 && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{
                    borderTop: "1px solid #545558",
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction={"row"}
              className=" mt-sm ml-md"
              spacing={2}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={frequencyId || ""}
                  onChange={(e) =>
                    handleSelectedFrequency(Number(e.target.value))
                  }
                >
                  {frequencyList.map((type) => (
                    <Box
                      className=" pl-sm pr-sm ml-sm"
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        key={type.id}
                        value={type.id}
                        control={<Radio sx={{ color: "#fff" }} />}
                        label={type.name}
                        style={{ color: "#fff" }}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </>
        )}

      <Box className="mt-sm mb-sm flex__ width__100 justifyContent-FlexEnd">
        <ButtonComponent
          className={
            // !frequencyId ||
            selectedWOType?.code !== "CM" &&
            (((selectedWOType?.code === "MC" ||
              selectedWOType?.code === "GC") &&
              !subTypeId) ||
              !frequencyId)
              ? // ((selectedWOType?.code === "MC" || selectedWOType?.code === "GC") &&
                //   !subTypeId)
                "btn-primary btn-disabled mr-md"
              : "btn-primary btn-submit mr-md"
          }
          variantType="contained"
          defaultLabelId={"Apply"}
          labelId={"Apply"}
          disabled={
            // !id ||
            selectedWOType?.code !== "CM" &&
            (((selectedWOType?.code === "MC" ||
              selectedWOType?.code === "GC") &&
              !subTypeId) ||
              !frequencyId)
          }
          onClick={() => {
            console.log(
              "selectedWOType?.code",
              selectedWOType?.code,
              id,
              frequencyId
            );
            if ((selectedWOType?.code === "CM" && id) || (id && frequencyId)) {
              handleApply();
            }
          }}
        />
      </Box>
    </>
  );
};

export default AssetCategorySelection;
