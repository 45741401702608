import { useContext, useEffect, useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import Details from "./components/Details";
import useAvailableHeight from "../../utils/useAvailableHeight";
import MediumTypography from "../../components/formlib/MediumTypography";
import { ReactComponent as ReplyIcon } from "../../assets/images/ticket_details/action_icons/reply.svg";
import { ReactComponent as ForwardIcon } from "../../assets/images/ticket_details/action_icons/forward.svg";
import { ReactComponent as NoteIcon } from "../../assets/images/ticket_details/action_icons/note.svg";
import { ReactComponent as WatchIcon } from "../../assets/images/ticket_details/action_icons/watch.svg";
import { ReactComponent as BranchingIcon } from "../../assets/images/ticket_details/action_icons/branching.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChildTickets from "./components/ChildTickets";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../api/UserApi/User";
import {
  fetchTicketDetailsById,
  saveWatch,
} from "../../api/ticketing/TicketDetails";
import Attachements from "./components/Attachements";
import Gallery from "./components/Gallery";
import "./css/styles.css";
import ActivityMenu from "./components/ActivityMenu";
import StatusTransition from "./components/StatusTransition";
import { Link, useParams } from "react-router-dom";
import { LIST_VIEW, TICKET } from "../../routes/Routing";
import TextInput from "../../components/formlib/TextInput";
import {
  AssetCategoryType,
  AssetGroupType,
  categorizedTemplateType,
  CategoryType,
  MessageType,
  MixedStatusDataType,
  NumericOptionType,
  SiteMapType,
  SiteType,
  StatusActionType,
  StatusDataType,
  StatusTransitionType,
  SubCategoryType,
  SubgroupType,
  TemplateFieldType,
  TicketConversationType,
  TicketDetailApiType,
  TicketDetailsType,
  TicketMetadataType,
  TicketVariantType,
  TransitionType,
  UpdatedStatusActionType,
  UpdateStatusInputType,
} from "./types/Types";
import DetailsSidebar from "./components/DetailsSidebar";
import {
  editTicket,
  fetchAssestByAssestGroupAssestCatId,
  fetchMetaData,
} from "../../api/createTicket/CreateTicketAPI";
import { AssigneeType } from "../../api/moduleCleaning/ModuleCleaningApi";
import ConversationModal from "./components/ConversationModal";
import { updateStatusApi } from "../../api/ticketing/TicketingAPI";
import RelatedTickets from "./components/RelatedTickets";
import CommonModal from "./components/CommonModal";
import { hasTicketEditPermission } from "./helpers/helper";

interface DependantFieldMappingType {
  [key: string]: string[];
}

const dependantFieldMapping: DependantFieldMappingType = {
  account: [
    "site",
    "subGroup",
    "assetGroup",
    "assetCategory",
    "asset",
    "type",
    "category",
    "subCategory",
  ],
  site: [
    "subGroup",
    "assetGroup",
    "assetCategory",
    "asset",
    "type",
    "category",
    "subCategory",
  ],
  subGroup: [
    "assetGroup",
    "assetCategory",
    "asset",
    "type",
    "category",
    "subCategory",
  ],
  assetGroup: ["assetCategory", "asset"],
  assetCategory: ["asset"],
  type: ["category", "subCategory"],
  category: ["subCategory"],
  department: ["assignee"],
};

const mandatoryFields = ["account", "site", "subGroup", "type", "status"];

const getOverridenTemplateFields = (
  templateFields: TemplateFieldType[],
  isInitialStatus: boolean
): TemplateFieldType[] => {
  const isReadOnly = !hasTicketEditPermission();
  return templateFields.map((item) => {
    let showInFormField = item.showInForm;
    let isRequiredField = item.isRequired;
    let isDisabledField = false;

    if (isRequiredField) {
      showInFormField = true;
    }

    if (item.isMandatory) {
      showInFormField = true;
      isRequiredField = true;
    }

    if (item.editableOn === "NONE" || item.editableOn === "CREATE") {
      isRequiredField = false;
      isDisabledField = true;
    }

    if (mandatoryFields.includes(item.code)) {
      showInFormField = true;
      isRequiredField = true;
    }

    if (
      isInitialStatus &&
      ["account", "site", "subGroup", "type"].includes(item.code)
    ) {
      isDisabledField = false;
    }

    if (isReadOnly) {
      isDisabledField = true;
    }

    return {
      ...item,
      showInForm: showInFormField,
      isRequired: isRequiredField,
      isDisabled: isDisabledField,
    };
  });
};

export const getActionsByStatusId = (
  statusActionsInput: StatusActionType[],
  transitionList: TransitionType[],
  statusId: number
) => {
  const statusActions = statusActionsInput?.find(
    (status) => status.statusId === statusId
  );

  if (statusActions) {
    const actionObjects = statusActions.actions
      .map(
        (actionId) =>
          transitionList?.find((transition) => transition.id === actionId) ??
          null
      )
      .filter((transition) => transition !== null);

    return {
      ...statusActions,
      actions: actionObjects,
    } as UpdatedStatusActionType;
  }
};

export const getStatusById = (
  statusList: StatusDataType[],
  statusId: number
) => {
  return statusList.find((status) => status.id === statusId);
};

export const getNextPossibleStatuses = (
  statusActionsInput: StatusActionType[],
  transitionList: TransitionType[],
  statusList: StatusDataType[],
  statusId: number
) => {
  const statusActions = getActionsByStatusId(
    statusActionsInput,
    transitionList,
    statusId
  );

  let nextStatuses: StatusDataType[] | undefined = undefined;

  if (statusActions) {
    nextStatuses = statusActions.actions
      .map((action) => {
        const nextStatus = getStatusById(statusList, action.toStatus);
        return nextStatus ? { ...nextStatus, transition: action } : null;
      })
      .filter((status) => status !== null) as StatusDataType[];
  }

  const currentStatus = getStatusById(statusList, statusId);

  if (currentStatus) {
    return nextStatuses ? [...nextStatuses, currentStatus] : [currentStatus];
  }

  return nextStatuses ?? [];
};

export interface SelectedObjectType {
  account: number;
  site: number;
  subGroup: number;
  assetGroup: number;
  assetCategory: number;
  asset: number;
  type: number;
  category: number;
  subCategory: number;
  department: number;
  assignee: number;
  channel: number;
  priority: number;
  severity: number;
  state: number;
  status: number;
}

const selectedInitialValues: SelectedObjectType = {
  account: 0,
  site: 0,
  subGroup: 0,
  assetGroup: 0,
  assetCategory: 0,
  asset: 0,
  type: 0,
  category: 0,
  subCategory: 0,
  department: 0,
  assignee: 0,
  channel: 0,
  priority: 0,
  severity: 0,
  state: 0,
  status: 0,
};

export interface OptionsObjectType {
  account: NumericOptionType[];
  site: NumericOptionType[];
  subGroup: NumericOptionType[];
  assetGroup: NumericOptionType[];
  assetCategory: NumericOptionType[];
  asset: NumericOptionType[];
  type: NumericOptionType[];
  category: NumericOptionType[];
  subCategory: NumericOptionType[];
  department: NumericOptionType[];
  assignee: NumericOptionType[];
  channel: NumericOptionType[];
  priority: NumericOptionType[];
  severity: NumericOptionType[];
  state: NumericOptionType[];
  status: NumericOptionType[];
  tags: NumericOptionType[];
}

const optionsInitialValues: OptionsObjectType = {
  account: [],
  site: [],
  subGroup: [],
  assetGroup: [],
  assetCategory: [],
  asset: [],
  type: [],
  category: [],
  subCategory: [],
  department: [],
  assignee: [],
  channel: [],
  priority: [],
  severity: [],
  state: [],
  status: [],
  tags: [],
};

interface ShowCreateInputType {
  child: boolean;
  related: boolean;
}

const TicketDetails = () => {
  const { ticketUniqueId: tid } = useParams();

  const ticketUniqueId = tid && Number(tid) ? Number(tid) : 0;

  const [apiData, setApiData] = useState<TicketDetailApiType | null>(null);
  const [ticketMetadata, setTicketMetadata] =
    useState<TicketMetadataType | null>(null);
  const [templateFields, setTemplateFields] = useState<TemplateFieldType[]>([]);
  const [ticketDetails, setTicketDetails] = useState<TicketDetailsType>({});
  const [conversations, setConversations] = useState<TicketConversationType[]>(
    []
  );

  const [showCreateInput, setShowCreateInput] = useState<ShowCreateInputType>({
    child: false,
    related: false,
  });

  const targetScrollRef = useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});

  const ticketId = ticketDetails?.id || 0;

  // State for selected values and available options
  const [selected, setSelected] = useState<SelectedObjectType>(
    selectedInitialValues
  );
  const [options, setOptions] =
    useState<OptionsObjectType>(optionsInitialValues);

  const availableHeight = useAvailableHeight(350);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [openCommonModal, setOpenCommonModal] = useState<boolean>(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [converationModalStatus, setConverationModalStatus] = useState<{
    open: boolean;
    type: MessageType;
  }>({
    open: false,
    type: null,
  });

  // Derived States
  const isInitialStatus =
    apiData?.stateTransition?.status?.length &&
    apiData.stateTransition.status.length === 1
      ? true
      : false; // Check for initial (New) status

  const incompleteFields = templateFields.filter(
    (field) =>
      field.isRequired &&
      ["", "0", 0, null, undefined].includes(ticketDetails[field.code])
  );

  const isFormCompleted =
    templateFields.length > 0 && incompleteFields.length === 0;

  const currentStatusRoleId =
    apiData?.stateTransition?.scheduleExecution?.find(
      (st) => st.statusId === ticketDetails.status
    )?.roleId ?? 0;

  const usefulFields = templateFields.filter(
    (item) =>
      ![
        "id",
        "ticketUniqueId",
        "summary",
        "description",
        "createdDatetime",
        "reporterEmail",
        "modifiedBy",
      ].includes(item.code) && !["text", "rich_text"].includes(item.type)
  );

  const textFields = templateFields.filter(
    (item) =>
      (item.type === "text" || item.type === "rich_text") &&
      !["summary", "description"].includes(item.code)
  );

  const summaryDesiredOrder = [
    "status",
    "severity",
    "type",
    "department",
    "assignee",
    "reporterId",
  ];

  const summaryFields = usefulFields
    .filter((item) => summaryDesiredOrder.includes(item.code)) // Filter to include only desired items
    .sort(
      (a, b) =>
        summaryDesiredOrder.indexOf(a.code) -
        summaryDesiredOrder.indexOf(b.code)
    ); // Sort based on desired order

  const effortsDesiredOrder = ["estimatedEffort", "actualEffort"];

  const effortsFields = usefulFields
    .filter((item) => effortsDesiredOrder.includes(item.code)) // Filter to include only desired items
    .sort(
      (a, b) =>
        effortsDesiredOrder.indexOf(a.code) -
        effortsDesiredOrder.indexOf(b.code)
    ); // Sort based on desired order

  const accountInfoDesiredOrder = ["account", "site", "subGroup", "type"];

  const accountInfoFields = usefulFields
    .filter((item) => accountInfoDesiredOrder.includes(item.code)) // Filter to include only desired items
    .sort(
      (a, b) =>
        accountInfoDesiredOrder.indexOf(a.code) -
        accountInfoDesiredOrder.indexOf(b.code)
    ); // Sort based on desired order

  const detailsFields = usefulFields.filter(
    (item) =>
      ![
        ...summaryDesiredOrder,
        ...effortsDesiredOrder,
        ...accountInfoDesiredOrder,
      ].includes(item.code)
  ); // Filter to exclude pervious desired items

  const categorizedTemplateFields: categorizedTemplateType = {
    summaryFields,
    detailsFields,
    effortsFields,
    accountInfoFields,
  };

  const accountOptions = ticketMetadata?.accountSiteMapList || [];
  const departmentOptions = ticketMetadata?.departmentList || [];
  const channelOptions = ticketMetadata?.channelList || [];
  const priorityOptions = ticketMetadata?.priorityList || [];
  const severityOptions = ticketMetadata?.severityList || [];
  const stateOptions = ticketMetadata?.stateList || [];

  useEffect(() => {
    fetchTicketMetadata();
  }, []);

  useEffect(() => {
    if (ticketMetadata) {
      fetchTicketDetails();
    }
  }, [ticketMetadata]);

  useEffect(() => {
    if (selected.assetGroup && selected.assetCategory) {
      fetchAssetOptions(selected.assetGroup, selected.assetCategory)
        .then((data) => {
          setOptions((prevOptions) => ({
            ...prevOptions,
            asset: data,
          }));
        })
        .catch(() => {
          setOptions((prevOptions) => ({
            ...prevOptions,
            asset: [],
          }));
        });
    } else {
      setOptions((prevOptions) => ({
        ...prevOptions,
        asset: [],
      }));
    }
  }, [selected.assetGroup, selected.assetCategory]);

  const fetchTicketDetails = () => {
    toggleLoader(true);
    fetchTicketDetailsById<TicketDetailApiType>(ticketUniqueId)
      .then((response) => {
        const isInitialStatusValue =
          response?.stateTransition?.status?.length &&
          response.stateTransition.status.length === 1
            ? true
            : false; // Check for initial (New) status

        const overridenTemplateFields = getOverridenTemplateFields(
          response.ticketTypeTemplateBean.ticketTemplateList,
          isInitialStatusValue
        );

        const filteredTemplateFields = overridenTemplateFields.filter(
          (fields) => fields.isVisible === "STANDARD"
        );

        const updatedSelected = Object.fromEntries(
          Object.entries(selectedInitialValues).map(([key, _]) => [
            key,
            response.ticketDetails[key as keyof TicketDetailsType] ?? 0,
          ])
        ) as SelectedObjectType;

        const updatedOptions = getAllDropdownOptions(
          updatedSelected,
          response.stateTransition
        );

        updatedOptions.tags = response?.tagsList ?? [];

        setApiData(response);
        setTicketDetails({
          ...response.ticketDetails,
          ...(response.ticketDetails?.customField || {}),
        });
        setConversations(response.ticketConversationList);
        setTemplateFields(filteredTemplateFields);
        setSelected(updatedSelected);
        setOptions(updatedOptions);
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
        setOpenErrorModal(true);
      })
      .finally(() => {
        toggleLoader(false);
      });
  };

  const fetchTicketMetadata = () => {
    toggleLoader(true);
    fetchMetaData<TicketMetadataType>()
      .then((response) => {
        if (response) {
          setTicketMetadata(response);
        }
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
        setOpenErrorModal(true);
      })
      .finally(() => {
        toggleLoader(false);
      });
  };

  const saveWatchToggle = () => {
    toggleLoader(true);

    let url = `ticket/${ticketId}/addWatchUser?isWatcher=${!ticketDetails.watcher}`;
    saveWatch(url)
      .then(() => {
        setTicketDetails({
          ...ticketDetails,
          watcher: !ticketDetails.watcher,
        });
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
        setOpenErrorModal(true);
      })
      .finally(() => {
        toggleLoader(false);
      });
  };

  const scrollToTarget = (
    target: string,
    position: "start" | "end" | "center" | "nearest" = "start"
  ) => {
    if (targetScrollRef.current[target]) {
      targetScrollRef.current[target]?.scrollIntoView({
        behavior: "smooth",
        block: position,
      });
    }
  };

  const getAllDropdownOptions = (
    selectedValues: SelectedObjectType,
    statusTransition: StatusTransitionType
  ) => {
    const updatedOptions: OptionsObjectType = { ...options };

    updatedOptions.account = [...accountOptions];
    updatedOptions.department = [...departmentOptions];
    updatedOptions.channel = [...channelOptions];
    updatedOptions.priority = [...priorityOptions];
    updatedOptions.severity = [...severityOptions];
    updatedOptions.state = [...stateOptions];

    // Status Options
    updatedOptions.status = getNextPossibleStatuses(
      statusTransition.scheduleExecution,
      statusTransition.transitionDetails,
      statusTransition.availableStatus,
      selectedValues.status
    );

    if (selectedValues.account) {
      updatedOptions.site = getSiteOptions(selectedValues.account);
    }

    if (selectedValues.site) {
      updatedOptions.subGroup = getSubGroupOptions(selectedValues.site);
    }

    if (selectedValues.subGroup) {
      updatedOptions.assetGroup = getAssetGroupOptions(selectedValues.subGroup);
      updatedOptions.type = getTicketTypeOptions(selectedValues.subGroup);
    }

    if (selectedValues.assetGroup) {
      updatedOptions.assetCategory = getAssetCategoryOptions(
        selectedValues.assetGroup
      );
    }

    if (selectedValues.assetCategory) {
      // API call handled on useEffect
    }

    if (selectedValues.type) {
      updatedOptions.category = getCategoryOptions(selectedValues.type);
    }

    if (selectedValues.category) {
      updatedOptions.subCategory = getSubCategoryOptions(
        selectedValues.category
      );
    }

    if (selectedValues.department) {
      updatedOptions.assignee = getAssigneeOptions(selectedValues.department);
    }

    return updatedOptions;
  };

  const fetchAssetOptions = async (
    assetGroupId: number,
    assetCategoryId: number
  ): Promise<NumericOptionType[]> => {
    toggleLoader(true);
    try {
      const response =
        await fetchAssestByAssestGroupAssestCatId<NumericOptionType>(
          "/ticket/getDataByType?",
          "ASSET",
          assetGroupId,
          assetCategoryId
        );
      if (Array.isArray(response)) {
        return response;
      }
      return [];
    } catch (error: any) {
      if (isCustomError(error)) {
        const apiError = error as ApiErrorResponse;
        setErrorDesc(apiError.issue[0].diagnostics);
      } else {
        setErrorDesc(error.id);
      }
      setOpenErrorModal(true);
      return [];
    } finally {
      toggleLoader(false);
    }
  };

  const updateDetails = (
    updatedDetails: Partial<TicketDetailsType>,
    updateType: "independant_selection" | "dependant_selection" | "detail"
  ) => {
    toggleLoader(true);
    editTicket(ticketId, updatedDetails)
      .then(() => {
        fetchTicketDetails();
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      })
      .finally(() => {
        toggleLoader(false);
      });
  };

  const updateStatus = (transition: TransitionType, remarks?: string) => {
    if (transition.position === "START" && !isFormCompleted) {
      setOpenCommonModal(true);
      return;
    }

    toggleLoader(true);
    const payloadData: UpdateStatusInputType = {
      ticketId: ticketId,
      ticketUniqueId: ticketDetails.ticketUniqueId,
      transitionId: transition.id,
      remarks,
    };
    updateStatusApi(payloadData)
      .then(() => {
        fetchTicketDetails();
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      })
      .finally(() => {
        toggleLoader(false);
      });
  };

  const getSiteOptions = (accountId: number): SiteType[] => {
    const accountData = accountOptions.find(
      (account) => account?.id === Number(accountId)
    );

    return accountData?.sites ?? [];
  };

  const getSubGroupOptions = (siteId: number): SubgroupType[] => {
    const siteData = accountOptions
      .flatMap((account) => account.sites)
      .find((site) => site?.id === Number(siteId));

    return siteData?.subgroups ?? [];
  };

  const getAssetGroupOptions = (subGroupId: number): AssetGroupType[] => {
    const subGroup = accountOptions
      .flatMap((account) => account.sites?.flatMap((site) => site.subgroups))
      .find((subGroupItem) => subGroupItem?.id === Number(subGroupId));

    return subGroup?.assetGroups ?? [];
  };

  const getAssetCategoryOptions = (
    assetGroupId: number
  ): AssetCategoryType[] => {
    const assetGroup = accountOptions
      .flatMap((account) =>
        account.sites?.flatMap((site) =>
          site.subgroups?.flatMap((subgroup) => subgroup.assetGroups)
        )
      )
      .find((assetGroup) => assetGroup?.id === Number(assetGroupId));

    return assetGroup?.assetCategory ?? [];
  };

  const getTicketTypeOptions = (subGroupId: number): TicketVariantType[] => {
    const subgroup = accountOptions
      .flatMap((account) => account.sites?.flatMap((site) => site.subgroups))
      .find((subgroup) => subgroup?.id === Number(subGroupId));

    return subgroup?.typeList ?? [];
  };

  const getCategoryOptions = (ticketTypeId: number): CategoryType[] => {
    const typeItem = accountOptions
      .flatMap((account) =>
        account.sites?.flatMap((site) =>
          site.subgroups?.flatMap((subgroup) => subgroup.typeList)
        )
      )
      .find((typeItem) => typeItem?.id === Number(ticketTypeId));

    return typeItem?.categoryList ?? [];
  };

  const getSubCategoryOptions = (categoryId: number): SubCategoryType[] => {
    const categoryItem = accountOptions
      .flatMap((account) =>
        account.sites?.flatMap((site) =>
          site.subgroups?.flatMap((subgroup) =>
            subgroup.typeList?.flatMap((typeItem) => typeItem.categoryList)
          )
        )
      )
      .find((categoryItem) => categoryItem?.id === Number(categoryId));

    return categoryItem?.subCategoryList ?? [];
  };

  const getAssigneeOptions = (departmentId: number): AssigneeType[] => {
    const department = departmentOptions.find(
      (department) => department.id === Number(departmentId)
    );
    return department?.assigneeList ?? [];
  };

  const handleSelectionChange = (
    updatedSelections: Partial<SelectedObjectType>
  ) => {
    const changedFields = Object.keys(
      updatedSelections
    ) as (keyof SelectedObjectType)[];

    const changedField = changedFields[0];

    const newSelections = { ...updatedSelections };

    // Reset all dependant fields
    let selectionType: "independant_selection" | "dependant_selection" =
      "independant_selection";
    dependantFieldMapping[changedField]?.forEach((field) => {
      newSelections[field as keyof SelectedObjectType] = 0;
      selectionType = "dependant_selection";
    });

    updateDetails(newSelections, selectionType);
  };

  const handleDetailsChange = (updatedDetails: Partial<TicketDetailsType>) => {
    updateDetails(updatedDetails, "detail");
  };

  return (
    <Box
      id="ticketing_details"
      className="ticketing-details"
      sx={{ ml: "-14px" }}
    >
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openCommonModal && (
        <CommonModal
          open={openCommonModal}
          handleClose={() => {
            setOpenCommonModal(false);
          }}
          onPositiveClick={() => {
            setOpenCommonModal(false);
          }}
          headerElement={
            <MediumTypography
              labelId={"ErrorModal.Header"}
              defaultLabel="Error"
              textColor="#FFF"
              fontSize="20px"
              fontWeight={700}
            />
          }
          contentElement={
            <Box>
              <MediumTypography
                labelId="Following fields are required to proceed:"
                defaultLabel="Following fields are required to proceed:"
                className="ticket-grey-text"
                sxProps={{ mb: "8px" }}
              />
              {incompleteFields.map((field) => (
                <Box className="common-flex-row">
                  <span className="ticket-asterisk">*</span>
                  <MediumTypography
                    label={field.alias}
                    className="ticket-grey-text"
                  />
                </Box>
              ))}
            </Box>
          }
        />
      )}
      {converationModalStatus.open && (
        <ConversationModal
          open={converationModalStatus.open}
          handleClose={() => {
            setConverationModalStatus({ open: false, type: null });
          }}
          ticketId={ticketId}
          users={apiData?.users ?? []}
          messageType={converationModalStatus.type}
          afterSendCallback={() => {
            fetchTicketDetails();
          }}
        />
      )}

      {apiData && (
        <>
          <Box className="added-margin">
            {apiData.stateTransition.definedScheduleStatus?.length > 0 && (
              <StatusTransition
                defaultStatusFlowInput={
                  apiData.stateTransition.definedScheduleStatus
                }
                pastStatusFlowInput={apiData.stateTransition.status}
                statusActionsInput={apiData.stateTransition.scheduleExecution}
                statusList={apiData.stateTransition.availableStatus}
                transitionList={apiData.stateTransition.transitionDetails}
                statusCallBack={(transition, remarks) => {
                  updateStatus(transition, remarks);
                }}
              />
            )}
          </Box>

          <Divider className="details-divider added-margin" />

          <Box
            className="flex__"
            sx={{
              flexDirection: "row",
              mt: 2,
            }}
          >
            <Box display={"flex"} gap={2} width={"100%"}>
              <Box flex={1}>
                <Box
                  mb={1}
                  className="added-margin flex__"
                  justifyContent={"left"}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Link
                      to={TICKET + "/" + LIST_VIEW}
                      className="ticket-anchor ticket-link ticket-link-slim"
                    >
                      <MediumTypography
                        defaultLabel="Tickets"
                        labelId={"ticket.title"}
                        textColor="#626F86"
                      />
                    </Link>

                    <MediumTypography label="/" textColor="#626F86" />

                    {ticketDetails.parentTicketData && (
                      <>
                        <Link
                          to={
                            TICKET +
                            "/" +
                            ticketDetails.parentTicketData.ticketUniqueId
                          }
                          className="ticket-anchor ticket-link ticket-link-slim"
                        >
                          <MediumTypography
                            label={
                              ticketDetails.parentTicketData.ticketUniqueId
                            }
                            textColor="#626F86"
                          />
                        </Link>

                        <MediumTypography label="/" textColor="#626F86" />
                      </>
                    )}

                    <MediumTypography
                      labelId={`#${ticketDetails.ticketUniqueId}`}
                      fontWeight={600}
                      fontSize="14px"
                    />
                  </Box>
                </Box>

                <TextInput
                  inline
                  className="text-input-field inline-text-input no-side-padding ticket-summary"
                  type="text"
                  variant="outlined"
                  disabled={!hasTicketEditPermission()}
                  labelId="common.placeHolderText"
                  defaultLabelId="--- type here ---"
                  Value={ticketDetails?.summary ?? ""}
                  handlechange={(value) => {
                    handleDetailsChange({ summary: value });
                  }}
                />

                <Box
                  className="flex__ scroller-with-padding"
                  flexDirection={"column"}
                  // gap={3}
                  sx={{
                    height: availableHeight,
                    overflowY: "auto",
                  }}
                >
                  {!isInitialStatus && hasTicketEditPermission() && (
                    <Box
                      mt={1}
                      className="added-margin flex__"
                      flexDirection={"column"}
                      gap={1}
                    >
                      <Box className="flex__" gap={2}>
                        <Box
                          className="action_button"
                          onClick={() =>
                            setConverationModalStatus({
                              open: true,
                              type: "REPLY",
                            })
                          }
                        >
                          <Box className="action-icon">
                            <ReplyIcon />
                          </Box>
                          <MediumTypography
                            labelId={"ticket.reply"}
                            defaultLabel="Reply"
                          />
                        </Box>
                        <Box
                          className="action_button"
                          onClick={() =>
                            setConverationModalStatus({
                              open: true,
                              type: "FORWARD",
                            })
                          }
                        >
                          <Box className="action-icon">
                            <ForwardIcon />
                          </Box>
                          <MediumTypography
                            labelId={"ticket.forward"}
                            defaultLabel="Forward"
                          />
                        </Box>
                        <Box
                          className="action_button"
                          onClick={() =>
                            setConverationModalStatus({
                              open: true,
                              type: "COMMENT",
                            })
                          }
                        >
                          <Box className="action-icon">
                            <NoteIcon />
                          </Box>
                          <MediumTypography
                            labelId={"ticket.addNote"}
                            defaultLabel="Add Note"
                          />
                        </Box>
                        <Box
                          className="action_button"
                          onClick={() => saveWatchToggle()}
                        >
                          <Box className="action-icon">
                            {!ticketDetails.watcher && (
                              <VisibilityIcon
                                sx={{
                                  color: "#25bafa",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            )}
                            {ticketDetails.watcher && (
                              <VisibilityOffIcon
                                sx={{
                                  color: "#25bafa",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            )}
                          </Box>
                          <MediumTypography
                            labelId={
                              ticketDetails.watcher
                                ? "ticket.unwatch"
                                : "ticket.watch"
                            }
                            defaultLabel={
                              ticketDetails.watcher
                                ? "Unsubscribe"
                                : "Subscribe"
                            }
                          />
                        </Box>
                        <Box
                          className="action_button"
                          onClick={() => {
                            setShowCreateInput((prev) => ({
                              ...prev,
                              child: true,
                            }));
                            // A timeout to ensure the scroll is triggered after state updates
                            setTimeout(() => {
                              scrollToTarget("createChildIssue", "end");
                            }, 0);
                          }}
                        >
                          <Box className="action-icon">
                            <BranchingIcon />
                          </Box>
                          <MediumTypography
                            labelId={"ticket.addchildIssue"}
                            defaultLabel="Add Child Issue"
                          />
                        </Box>
                        <Box
                          className="action_button"
                          onClick={() => {
                            setShowCreateInput((prev) => ({
                              ...prev,
                              related: true,
                            }));
                            // A timeout to ensure the scroll is triggered after state updates
                            setTimeout(() => {
                              scrollToTarget("createRelatedIssue", "end");
                            }, 0);
                          }}
                        >
                          <Box className="action-icon">
                            <BranchingIcon />
                          </Box>
                          <MediumTypography
                            labelId={"ticket.addRelatedIssue"}
                            defaultLabel="Add Related Issue"
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <Box mt={2} mb={1} className="common-flex-column" gap={2}>
                    <Details
                      fieldValue={ticketDetails?.description ?? ""}
                      textFields={textFields}
                      ticketDetails={ticketDetails}
                      handleDetailsChange={handleDetailsChange}
                      saveCallback={(value) => {
                        handleDetailsChange({ description: value });
                      }}
                    />
                  </Box>

                  <Box
                    className="added-margin"
                    ref={(el: HTMLDivElement) =>
                      (targetScrollRef.current["createChildIssue"] = el)
                    }
                  >
                    <ChildTickets
                      childTickets={apiData.childTicketList ?? []}
                      parentDetails={ticketDetails}
                      getAssigneeOptions={getAssigneeOptions}
                      severityOptions={options.severity}
                      ticketTypeOptions={options.type}
                      updateShowCreateInput={(val: boolean) =>
                        setShowCreateInput((prev) => ({
                          ...prev,
                          child: val,
                        }))
                      }
                      showCreateInput={showCreateInput.child}
                    />
                  </Box>

                  <Box
                    className="added-margin"
                    ref={(el: HTMLDivElement) =>
                      (targetScrollRef.current["createRelatedIssue"] = el)
                    }
                  >
                    <RelatedTickets
                      relatedTickets={apiData.relatedIssues ?? []}
                      parentDetails={ticketDetails}
                      saveCallback={(values) => {
                        handleDetailsChange({ relatedIssues: values });
                        fetchTicketDetails();
                      }}
                      getAssigneeOptions={getAssigneeOptions}
                      severityOptions={options.severity}
                      ticketTypeOptions={options.type}
                      updateShowCreateInput={(val: boolean) =>
                        setShowCreateInput((prev) => ({
                          ...prev,
                          related: val,
                        }))
                      }
                      showCreateInput={showCreateInput.related}
                    />
                  </Box>

                  <Box className="added-margin">
                    <Divider className="details-divider" />
                    <Box mt={1} mb={1}>
                      <MediumTypography
                        labelId={"ticket.attachments"}
                        className="ticket-header"
                      />
                      <Attachements
                        attachment={conversations.flatMap(
                          (conversation) => conversation.attachments ?? []
                        )}
                        afterSendCallback={() => {
                          fetchTicketDetails();
                        }}
                        ticketId={ticketId}
                      />
                    </Box>
                  </Box>

                  <Box className="added-margin ticket-gallery">
                    <Divider className="details-divider" />
                    <Box mt={1}>
                      <MediumTypography
                        labelId={"ticket.gallery"}
                        className="ticket-header"
                        sxProps={{ pb: 1 }}
                      />
                      <Gallery
                        attachment={conversations.flatMap(
                          (conversation) => conversation.attachments ?? []
                        )}
                        afterSendCallback={() => {
                          fetchTicketDetails();
                        }}
                        ticketId={ticketId}
                      />
                    </Box>
                  </Box>

                  <Divider className="details-divider added-margin" />

                  <Box className="added-margin" mt={1}>
                    <ActivityMenu
                      comments={conversations ?? []}
                      users={apiData?.users ?? []}
                      ticketId={ticketId}
                      afterSendCallback={() => {
                        fetchTicketDetails();
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: "350px" }}>
                <Box
                  sx={{
                    height: availableHeight + 70,
                    overflowY: "auto",
                  }}
                  className="scroller-with-padding"
                >
                  <DetailsSidebar
                    templateFields={templateFields}
                    categorizedTemplateFields={categorizedTemplateFields}
                    ticketDetails={ticketDetails}
                    ticketMetadata={ticketMetadata}
                    isInitialStatus={isInitialStatus}
                    currentStatusRoleId={currentStatusRoleId}
                    selected={selected}
                    options={options}
                    users={apiData?.users ?? []}
                    updateOptions={setOptions}
                    handleSelectionChange={handleSelectionChange}
                    handleDetailsChange={handleDetailsChange}
                    handleStatusChange={updateStatus}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default TicketDetails;
