import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import {
  FC,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import DayPagination from "../../components/formlib/DayPagination";
import {
  ShiftCardTypes,
  TeamViewFormattedType,
  TeamViewDashboardType,
  TechnicianType,
} from "../../utils/type";
import _, { debounce } from "lodash";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction"; // Required for drag & drop
import { EventDropArg, EventInput } from "@fullcalendar/core";
import ScheduleItem from "../../components/scheduler/ScheduleItem";
import "./css/scheduler.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import "./css/TeamMembers.css";
import TeamMembersComponent from "../../components/formlib/TeamMemberComponent";
import SearchBox from "../../components/formlib/SearchBox";
import { ReactComponent as SwapIcon } from "../../assets/images/swap.svg";
import { ReactComponent as DragIcon } from "../../assets/images/DragIcon.svg";
import { ReactComponent as NotAvialableIcon } from "../../assets/images/woNotFound.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ResourceInput, ResourceLaneMountArg } from "@fullcalendar/resource";
import { SchedulerViewContext, dateContext } from "./SchedulerDashboard";
import moment from "moment";
import {
  CardMovementActionType,
  MoveWorkOrderParams,
  moveWorkOrderApi,
  swapMembersApi,
} from "../../api/workOrderApi/schedulerApi/Scheduler";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../api/UserApi/User";
import Loader from "../../components/shared/Loader";
import { hasPermission } from "../../utils/checkPermission";
import { handleError } from "../../utils/commonFunctions";
import useAvailableHeight from "../../utils/useAvailableHeight";

interface TechnicianWithIconType {
  member: TechnicianType;
  icon: string;
}

interface SchedulerTeamViewProps {
  teamViewDetails: TeamViewDashboardType;
  callApiCallBack: () => void;
  isVisible: boolean;
}

const SchedulerTeamView: FC<SchedulerTeamViewProps> = ({
  teamViewDetails,
  callApiCallBack,
  isVisible,
}) => {
  const schedulerViewContext = useContext(SchedulerViewContext);
  const dateCtx = useContext(dateContext);

  const [isLoading, setIsLoading] = useState(true);

  const teamViewMembersApiDataInitial = getTeamViewMembersApiData();

  const [teamViewMembersData, setTeamViewMembersData] = useState<
    TeamViewFormattedType[]
  >(teamViewMembersApiDataInitial);

  // Original data to revert back on filter reset
  const [teamViewMembersDataOriginal, setTeamViewMembersDataOriginal] =
    useState<TeamViewFormattedType[]>(teamViewMembersApiDataInitial);

  const [teamMembers, setTeamMembers] = useState<TechnicianWithIconType[]>([]);
  const [teamMembersBackup, setTeamMembersBackup] = useState<
    TechnicianWithIconType[]
  >([]);

  const [destinationMember, setDestinationMember] =
    useState<TechnicianWithIconType | null>(null);
  const [sourceMember, setSourceMember] =
    useState<TechnicianWithIconType | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [swapSearchTerm, setSwapSearchTerm] = useState("");

  const [resources, setResources] = useState<ResourceInput[]>([]);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [slotMinWidth, setSlotMinWidth] = useState(30);
  const [scrollTime, setScrollTime] = useState("00:00:00");
  const shiftStartTimeRef = useRef<string>("");
  const shiftEndTimeRef = useRef<string>("");

  const calendarRef = useRef<FullCalendar>(null);

  // State to store the resource row heights using a Map
  const [resourceRowHeights, setResourceRowHeights] = useState<
    Map<string, number>
  >(new Map());

  // Ref to store ResizeObservers by resource ID
  const resizeObserversRef = useRef<Map<string, ResizeObserver>>(new Map());

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    description: "",
    onNegative: () => {},
    onPositive: () => {},
  });
  const currentStatusRef = useRef<string>("");

  const openConfirmationModal = (
    description: string,
    onNegative: () => void,
    onPositive: () => void
  ) => {
    setModalContent({ description, onNegative, onPositive });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalNegative = () => {
    modalContent.onNegative();
    handleModalClose();
  };

  const handleModalPositive = () => {
    modalContent.onPositive();
    handleModalClose();
  };

  // << Action handlers
  function handleSwapClick(memberInfo: TechnicianWithIconType) {
    let updatedTeamMembers: TechnicianWithIconType[] =
      _.cloneDeep(teamMembersBackup);
    let updatedDestinationMember = null;
    let updatedSourceMember = null;

    if (!destinationMember) {
      let newUpdatedTeamMembers = [];

      newUpdatedTeamMembers.push({
        ...memberInfo,
        icon: "swap_blue",
      });

      updatedTeamMembers
        .filter((memberData) => memberData.member.id !== memberInfo.member.id)
        .forEach((memberData) => {
          newUpdatedTeamMembers.push({
            ...memberData,
            icon: "drag",
          });
        });

      updatedTeamMembers = newUpdatedTeamMembers;
      updatedDestinationMember = memberInfo;
      updatedSourceMember = null;
    }

    setTeamMembers(updatedTeamMembers);
    setDestinationMember(updatedDestinationMember);
    setSourceMember(updatedSourceMember);
    setSearchTerm("");
    setSwapSearchTerm("");
  }

  const onDragEnd = (result: any) => {
    const { source } = result;

    let updatedTeamMembers = _.cloneDeep(teamMembers); // Array.from(teamMembers);
    const [removed] = updatedTeamMembers.splice(source.index, 1);

    updatedTeamMembers = updatedTeamMembers.map((memberData) => {
      if (memberData.icon !== "swap_blue") {
        return {
          ...memberData,
          icon: "",
        };
      }
      return memberData;
    });

    // updatedTeamMembers.splice(destination.index, 0, removed);

    const updatedSourceMember = { ...removed, icon: "" };

    setSourceMember(updatedSourceMember);
    setTeamMembers(updatedTeamMembers);
  };

  const handleSwapSubmit = () => {
    if (sourceMember?.member && destinationMember?.member) {
      let obj = {
        firstMember: sourceMember.member.id,
        secondMember: destinationMember.member.id,
        subGroupId: teamViewDetails.selectedSubgroupId,
        shiftId: teamViewDetails.selectedShiftIds,
        swapOn: eventFormattedDate(),
      };

      swapMembersApi(obj)
        .then(() => {
          callApiCallBack();
        })
        .catch((error) => {
          if (isCustomError(error)) {
            const apiError = error as ApiErrorResponse;

            if (apiError.issue[0]?.severity === "WARNING") {
              // Confirmation popup
              openConfirmationModal(
                "teamView.skillMissmatch",
                () => {
                  setTeamMembers(teamMembersBackup);
                  setDestinationMember(null);
                  setSourceMember(null);
                },
                () => {
                  swapMembersApi(obj, true)
                    .then(() => {
                      callApiCallBack();
                    })
                    .catch((error2) => {
                      setOpenErrorModal(true);
                      handleError(error2, setErrorDesc);
                    });
                }
              );
            } else {
              // Error popup
              setErrorDesc(apiError.issue[0].diagnostics);
              setOpenErrorModal(true);
              setTeamMembers(() => _.cloneDeep(teamMembersBackup));
              setDestinationMember(null);
              setSourceMember(null);
            }
          } else {
            setErrorDesc(error.id);
            setOpenErrorModal(true);
            setTeamMembers(() => _.cloneDeep(teamMembersBackup));
            setDestinationMember(null);
            setSourceMember(null);
          }
        });
    } else {
      setErrorDesc("teamView.memberSwapError");
      setOpenErrorModal(true);
    }
  };

  const findCardById = (cardId: number) => {
    return events.find((event) => event.id === cardId.toString()) || null;
  };

  const getMembersCardsByCardId = (cardId: number, except = false) => {
    const card = findCardById(cardId);
    if (!card) {
      return [];
    }

    // Extract member IDs from the given card.
    const memberIds = card.workOrder.members.map((member: TechnicianType) =>
      member.id.toString()
    );

    // Create a set to track unique card IDs.
    const uniqueCardIds = new Set<string>();

    // Find all unique cards that have any overlapping member IDs.
    const memberCards = events.filter((event) => {
      const isMember = event.workOrder.members.some((member: TechnicianType) =>
        memberIds.includes(event.resourceId)
      );
      const isUnique = !uniqueCardIds.has(String(event.id));
      if (isMember && isUnique) {
        uniqueCardIds.add(String(event.id));
        return true;
      }
      return false;
    });

    if (except) {
      return memberCards.filter((c) => c.id !== cardId.toString());
    }

    return memberCards;
  };

  const isTimeRangeOverlapping = (
    start1: moment.Moment,
    end1: moment.Moment,
    start2: moment.Moment,
    end2: moment.Moment
  ): boolean => {
    return start1.isBefore(end2) && start2.isBefore(end1);
  };

  const isCardOverlapping = (
    cardId: number,
    scheduledStart: Date,
    scheduledEnd: Date,
    memberId?: string
  ) => {
    let membersCards = [];

    if (memberId) {
      membersCards = events.filter((event) => event.resourceId === memberId);
    } else {
      membersCards = getMembersCardsByCardId(cardId, true);
    }

    if (membersCards.length === 0) {
      return false;
    }

    const startMoment = moment(scheduledStart);
    const endMoment = moment(scheduledEnd);

    return membersCards.some((card) => {
      const cardStart = moment(card.start);
      const cardEnd = moment(card.end);
      return isTimeRangeOverlapping(startMoment, endMoment, cardStart, cardEnd);
    });
  };

  const shiftSortOrder = ["A", "G", "B", "C"];

  const getSortedShifts = () => {
    const allShifts = [...teamViewDetails.shiftTypes];
    // Sort the shiftTypes array based on the sortOrder
    allShifts.sort((a, b) => {
      return (
        shiftSortOrder.indexOf(a.shiftName) -
        shiftSortOrder.indexOf(b.shiftName)
      );
    });
    return allShifts;
  };

  const getShiftByScheduledStart = (scheduledStart: Date | null) => {
    if (!scheduledStart) {
      return null;
    }

    for (const shiftData of getSortedShifts()) {
      if (
        timeIsBetweenShift(
          scheduledStart,
          shiftData.shiftStartTime,
          shiftData.shiftEndTime
        )
      ) {
        return shiftData;
      }
    }
    return null;
  };

  const isNewScheduleWithinSameShift = (
    scheduledStart: Date | null,
    shiftId: number
  ) => {
    if (!scheduledStart) {
      return null;
    }

    const currentShiftData = teamViewDetails.shiftTypes.find(
      (shift) => shift.shiftId === shiftId
    );

    if (currentShiftData) {
      return timeIsBetweenShift(
        scheduledStart,
        currentShiftData.shiftStartTime,
        currentShiftData.shiftEndTime
      );
    }

    return null;
  };

  const moveWorkOrderApiRequest = (
    requestParams: MoveWorkOrderParams,
    cardId: number,
    action: CardMovementActionType,
    dropInfo: EventDropArg
  ) => {
    moveWorkOrderApi(requestParams, cardId, action)
      .then(() => {
        callApiCallBack();
      })
      .catch((error) => {
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
        dropInfo.revert();
      });
  };

  // This function will be called when an event is dropped in a new time slot or resource
  const onEventDrop = (dropInfo: EventDropArg) => {
    const workOrder = dropInfo.event.extendedProps.workOrder;

    const cardId = workOrder.cardId;
    const sequenceId = workOrder.sequenceId;

    const shiftId = workOrder.shiftId;

    const scheduledStart = dropInfo.event.start;
    const scheduledEnd = dropInfo.event.end;

    const oldMemberId = dropInfo.oldResource?.id;
    const newMemberId = dropInfo.newResource?.id;

    if (moment(scheduledStart).isBefore(moment())) {
      setErrorDesc("teamView.pastTimeError");
      setOpenErrorModal(true);
      dropInfo.revert();
      return;
    }

    const newShift = getShiftByScheduledStart(scheduledStart);

    const isWithinShift = isNewScheduleWithinSameShift(scheduledStart, shiftId);

    if (!newShift) {
      // Error popup
      setErrorDesc("teamView.shiftNotFound");
      setOpenErrorModal(true);
      dropInfo.revert();
      return;
    } else if (isWithinShift === null) {
      // Error popup
      setErrorDesc("somethingWrongError");
      setOpenErrorModal(true);
      dropInfo.revert();
      return;
    } else {
      let requestParams = {
        oldMemberId: oldMemberId ? Number(oldMemberId) : 0,
        newMemberId: newMemberId ? Number(newMemberId) : 0,
        woUniqueId: sequenceId,
        shiftId: shiftId, // newShift.shiftId,
        startTime: moment(scheduledStart).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: moment(scheduledEnd).format("YYYY-MM-DDTHH:mm:ss"),
        currentDate: eventFormattedDate(),
        version: workOrder.version,
      };

      // Negative Conditions
      // Check if shift is changed - Note - scheduledEnd can be beyond shift
      if (!isWithinShift) {
        openConfirmationModal(
          "teamView.shiftChangeWarning",
          () => {
            dropInfo.revert();
            return;
          },
          () => {
            requestParams.shiftId = newShift.shiftId;
            moveWorkOrderApiRequest(
              requestParams,
              cardId,
              "MOVE_THROUGH_SHIFTS",
              dropInfo
            );
          }
        );
      } else if (
        // Horizontal movement within shift
        scheduledStart &&
        scheduledEnd &&
        oldMemberId === newMemberId &&
        isCardOverlapping(cardId, scheduledStart, scheduledEnd)
      ) {
        openConfirmationModal(
          "teamView.someMemberOverloadWarning",
          () => {
            dropInfo.revert();
            return;
          },
          () => {
            moveWorkOrderApiRequest(
              requestParams,
              cardId,
              "MOVE_WO_HORIZONTAL",
              dropInfo
            );
          }
        );
      } else if (
        // Vertical movement - Check if member is changed
        scheduledStart &&
        scheduledEnd &&
        oldMemberId !== newMemberId &&
        isCardOverlapping(cardId, scheduledStart, scheduledEnd, newMemberId)
      ) {
        openConfirmationModal(
          "teamView.memberOverloadWarning",
          () => {
            dropInfo.revert();
            return;
          },
          () => {
            moveWorkOrderApiRequest(
              requestParams,
              cardId,
              "MOVE_WO_VERTICAL",
              dropInfo
            );
          }
        );
      } else {
        // No confirmation popups required
        let action: CardMovementActionType = "MOVE_WO_HORIZONTAL";

        if (oldMemberId !== newMemberId) {
          action = "MOVE_WO_VERTICAL";
        }

        moveWorkOrderApiRequest(requestParams, cardId, action, dropInfo);
      }
    }
  };

  // Function to handle the actual search logic
  const handleSearch = (searchValue: string) => {
    let filteredMembers = _.cloneDeep(teamViewMembersDataOriginal);

    if (searchValue.trim()) {
      // Filter logic based on searchValue
      filteredMembers = filteredMembers.filter((memberData: any) =>
        memberData.member.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    setTeamViewMembersData(filteredMembers);
  };

  // Function to handle the actual swap search logic
  const handleSwapSearch = (searchValue: string) => {
    let filteredMembers = _.cloneDeep(teamMembersBackup);

    let newUpdatedTeamMembers = [];

    if (destinationMember) {
      newUpdatedTeamMembers.push({
        ...destinationMember,
        icon: "swap_blue",
      });
    }

    let newIcon = sourceMember ? "" : "drag";

    filteredMembers
      .filter(
        (memberData) =>
          memberData.member.id !==
            (destinationMember ? destinationMember.member.id : null) &&
          memberData.member.id !==
            (sourceMember ? sourceMember.member.id : null)
      )
      .forEach((memberData) => {
        newUpdatedTeamMembers.push({
          ...memberData,
          icon: newIcon,
        });
      });

    if (searchValue.trim()) {
      // Filter logic based on searchValue
      newUpdatedTeamMembers = newUpdatedTeamMembers.filter(
        (memberData: any) =>
          memberData.icon === "swap_blue" ||
          memberData.member.name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      );
    }

    setTeamMembers(newUpdatedTeamMembers);
  };

  // Debounce the handleSearch function
  const debouncedSearch = useCallback(
    debounce((searchValue: string) => handleSearch(searchValue), 300),
    []
  );

  const onSearchChange = (value: string) => {
    setSearchTerm(value); // Update the searchTerm state
    // debouncedSearch(value); // Call the debounced search function
    handleSearch(value);
  };

  const onSwapSearchChange = (value: string) => {
    setSwapSearchTerm(value); // Update the swapSearchTerm state
    handleSwapSearch(value);
  };

  // Function to handle resource lane mount event
  const onResourceLaneDidMount = (arg: ResourceLaneMountArg) => {
    const rowHeight = arg.el.clientHeight;
    setResourceRowHeights(
      new Map(resourceRowHeights.set(arg.resource.id, rowHeight))
    );

    // Initialize ResizeObserver to listen for height changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setResourceRowHeights(
          new Map(
            resourceRowHeights.set(arg.resource.id, entry.contentRect.height)
          )
        );
      }
    });

    resizeObserver.observe(arg.el);

    // Store the observer reference in the ref
    resizeObserversRef.current.set(arg.resource.id, resizeObserver);
  };

  // Function to handle resource lane unmount event
  const onResourceLaneWillUnmount = (arg: ResourceLaneMountArg) => {
    // Retrieve and clean up ResizeObserver
    const resizeObserver = resizeObserversRef.current.get(arg.resource.id);
    if (resizeObserver) {
      resizeObserver.disconnect();
      // Remove the observer from the ref
      resizeObserversRef.current.delete(arg.resource.id);
    }
  };

  // Function to update resources on the calendar
  const updateResources = (newResources: ResourceInput[]) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      // Remove all existing resources
      calendarApi.getResources().forEach((resource) => {
        resource.remove(); // Or calendarApi.removeResource(resource.id);
      });

      // Add new resources
      newResources.forEach((resource) => {
        calendarApi.addResource(resource);
      });
    }
  };

  // Function to update events on the calendar
  const updateEvents = (newEvents: EventInput[]) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      // Remove all existing events
      calendarApi.getEvents().forEach((event) => {
        event.remove();
      });

      calendarApi.addEventSource(newEvents);
    }
  };

  const getMinEventData = (
    getEvents: EventInput[]
  ): { slotMinWidth: number; scrollTime: string } => {
    let minDurationMinutes = Number.MAX_SAFE_INTEGER;
    let minEventStartMoment = moment("23:59:59", "HH:mm:ss"); // Latest time in a day as a moment object

    getEvents.forEach((event) => {
      const eventStartMoment = moment(event.start);
      const eventEndMoment = moment(event.end);
      const durationMinutes = eventEndMoment.diff(eventStartMoment, "minutes");

      if (durationMinutes < minDurationMinutes) {
        minDurationMinutes = durationMinutes;
      }

      if (eventStartMoment.isBefore(minEventStartMoment)) {
        minEventStartMoment = eventStartMoment;
      }
    });

    let minEventStart = minEventStartMoment.format("HH:mm:ss");

    // Handle case where there are no events
    if (getEvents.length === 0) {
      minDurationMinutes = 0;
      minEventStart = "00:00:00";
    }

    let calculatedSlotMinWidth = getSlotMinWidth(minDurationMinutes);

    return { slotMinWidth: calculatedSlotMinWidth, scrollTime: minEventStart };
  };

  const getSlotMinWidth = (minEventDuration: number): number => {
    // Criteria min slot width 30 and min event width 320

    const slotWidthThreshold = 30;

    if (minEventDuration <= 0) return slotWidthThreshold;

    let calculatedSlotMinWidth = 9600 / minEventDuration;

    calculatedSlotMinWidth = Math.max(
      calculatedSlotMinWidth,
      slotWidthThreshold
    );

    return calculatedSlotMinWidth; // if calculatedSlotMinWidth > 30 (minEventDuration > 320) return 30
  };

  const eventFormattedDate = useMemo(() => {
    const activeDate = dateCtx.activeDate;
    return () => {
      return moment(activeDate).format("YYYY-MM-DD");
    };
  }, [dateCtx.activeDate]);

  const timeIsBetween = (
    time: Date | string,
    startTime: string,
    endTime: string
  ) => {
    return moment(time).isBetween(startTime, endTime, null, "[]");
  };

  const timeIsBetweenShift = useMemo(() => {
    const activeDate = dateCtx.activeDate;

    return (
      time: Date | string,
      shiftStartTime: string,
      shiftEndTime: string
    ) => {
      let today = moment(activeDate).format("YYYY-MM-DD");
      let yesterday = moment(activeDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      let tomorrow = moment(activeDate).add(1, "days").format("YYYY-MM-DD");

      // For shift A
      if (
        moment(shiftStartTime, "HH:mm:ss").isAfter(
          moment(shiftEndTime, "HH:mm:ss")
        )
      ) {
        return (
          timeIsBetween(
            time,
            yesterday + "T" + shiftStartTime,
            today + "T" + shiftEndTime
          ) ||
          timeIsBetween(
            time,
            today + "T" + shiftStartTime,
            tomorrow + "T" + shiftEndTime
          )
        );
      }

      // For Other shifts
      let startTime = today + "T" + shiftStartTime;
      let endTime = today + "T" + shiftEndTime;

      return timeIsBetween(time, startTime, endTime);
    };
  }, [dateCtx.activeDate]);

  function getShiftAvailibility(memberId: number) {
    const shiftAvailibility = {
      shift: "",
      availability: "0 hr",
      shiftStartTime: "",
      shiftEndTime: "",
    };
    if (teamViewDetails?.teamView.length > 0) {
      const currentTeamView = teamViewDetails?.teamView;
      const memberIndex = currentTeamView.findIndex(
        (memberData) => memberData.member.id === memberId
      );

      const foundShift = currentTeamView[memberIndex]?.shifts[0];

      if (foundShift) {
        shiftAvailibility.shift = foundShift.shiftName;
        shiftAvailibility.shiftStartTime = foundShift.shiftStartTime;
        shiftAvailibility.shiftEndTime = foundShift.shiftEndTime;
        shiftAvailibility.availability =
          foundShift.teams.find((team) => team.id === memberId)?.availability ||
          "0 hr";
        return shiftAvailibility; // Exit the function early
      }
    }
    return shiftAvailibility;
  }

  function getTeamViewMembersApiData() {
    const teamViewMembersApiData: TeamViewFormattedType[] = [];
    teamViewDetails?.teamView.forEach((teamViewData) => {
      const member: TechnicianType = teamViewData.member;
      let cards: ShiftCardTypes[] = [];
      teamViewData.shifts.forEach((shift) => {
        cards = [...cards, ...shift.cards];
      });
      teamViewMembersApiData.push({
        member,
        cards,
      });
    });
    return teamViewMembersApiData;
  }

  useEffect(() => {
    const teamViewMembersApiData = getTeamViewMembersApiData();
    const teamViewMembersOnly = teamViewMembersApiData.map(
      (item) => item.member
    );
    const teamMembersWithIcon = teamViewMembersOnly.map((member) => {
      return {
        member,
        icon: "swap_grey",
      };
    });
    setTeamViewMembersData(teamViewMembersApiData);
    setTeamViewMembersDataOriginal(teamViewMembersApiData);
    setTeamMembersBackup(teamMembersWithIcon);
  }, [teamViewDetails]);

  useEffect(() => {
    const teamViewMembersOnly = teamViewMembersData.map((item) => item.member);
    const teamMembersWithIcon = teamViewMembersOnly.map((member) => {
      return {
        member,
        icon: "swap_grey",
      };
    });

    const initialResources: ResourceInput[] = teamViewMembersData.map(
      (item, index) => ({
        id: String(item.member.id),
        title: item.member.name,
        eventColor: "transparent",
        sortOrder: index,
      })
    );

    const initialEvents: EventInput[] = [];

    teamViewMembersData.forEach((item) => {
      item.cards.forEach((card, index) => {
        currentStatusRef.current = card.statusCode;
        const eventData: EventInput = {
          id: card.cardId.toString(),
          start: card.scheduledStartTime,
          end: card.scheduledEndTime,
          resourceId: String(item.member.id),
          workOrder: {
            cardId: card.cardId,
            sequenceId: card.sequenceId,
            code: card.code,
            asset: card.asset,
            frequency: card.frequency,
            description: card.description,
            status: card.dispatchStatusType,
            actualStartTime: card.actualStartTime || null,
            actualEndTime: card.actualEndTime || null,
            progress: card.progress ?? 0,
            members: card.members,
            shiftId: card.shiftId,
            shiftName: card.shiftName,
            shiftStartTime: card.shiftStartTime,
            shiftEndTime: card.shiftEndTime,
            version: card.version,
            cardDetails: card,
          },
        };

        initialEvents.push(eventData);
      });
    });

    setTeamMembers(teamMembersWithIcon);

    setDestinationMember(null);
    setSourceMember(null);
    setResources(initialResources);
    setEvents(initialEvents);
  }, [teamViewMembersData]);

  useEffect(() => {
    setIsLoading(true);
    setSearchTerm("");
    setSwapSearchTerm("");
  }, [dateCtx.activeDate]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi && dateCtx.activeDate) {
      calendarApi.gotoDate(eventFormattedDate());
    }
  }, [dateCtx.activeDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [dateCtx.activeDate]);

  useEffect(() => {
    // Clean up the debounced function on component unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Update the calendar when resources or events state changes
  useEffect(() => {
    updateResources(resources);
    updateEvents(events);
  }, [resources, events]);

  // When events change, recalculate the minimum duration and set slotMinWidth and scrollTime
  useEffect(() => {
    const minEventData = getMinEventData(events);
    if (minEventData.slotMinWidth !== slotMinWidth) {
      setSlotMinWidth(minEventData.slotMinWidth);
    }
    if (minEventData.scrollTime !== scrollTime) {
      setScrollTime(minEventData.scrollTime);
    }
    if (events.length > 0) {
      setIsLoading(false);
    }
  }, [events]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.scrollToTime(scrollTime);
    }
  }, [scrollTime]);

  const isOlderThanToday = moment(dateCtx.activeDate).isBefore(
    moment().startOf("day")
  );

  const availableHeight = useAvailableHeight(280);
  const availableHeight1 = useAvailableHeight(160);

  return (
    <>
      <Box>
        <ModalPopup
          descriptionText={modalContent.description}
          open={isModalOpen}
          handleClose={() => {}}
          onPositiveClick={handleModalPositive}
          onNegativeClick={handleModalNegative}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      </Box>
      <Box>
        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
      </Box>
      <Box
        className="tableStyles sticky-container"
        display={"flex"}
        sx={{
          color: "#FFFFFF",
          fontSize: "12px",
          maxHeight: isVisible ? availableHeight : availableHeight1,
        }}
      >
        <Box sx={{ width: "360px", minWidth: "360px" }}>
          {/* Team Members Components */}
          <Box component="div" className="">
            <Box className="teamMember_container_scheduler sticky-header">
              <MediumTypography
                labelId="TeamMembers.title"
                defaultLabel="Team Members"
                fontSize="16px"
                fontWeight={600}
              />
              <Box className="teamMember_count">
                <MediumTypography
                  label={`${teamViewDetails?.presentUserCount || 0}/${
                    teamViewDetails?.totalUserCount || 0
                  }`}
                  fontSize="12px"
                  fontWeight={400}
                />
              </Box>
            </Box>
            {!isLoading && teamMembersBackup.length > 0 && (
              <Box
                // sx={{ overflowY: "auto", maxHeight: "700px" }}
                sx={{
                  pt: "15px",
                  pointerEvents:
                    isOlderThanToday ||
                    currentStatusRef.current === "DISPATCHED"
                      ? "none"
                      : "auto",
                }}
                className="tableStyles"
              >
                {!destinationMember && (
                  <Box component="div" className="padding_12">
                    <Box sx={{ height: "34px" }}></Box>
                    <Box>
                      <SearchBox
                        labelId="defaultSearch"
                        defaultlabel="Search"
                        backgroundColor="#22272B"
                        sxProps={{
                          width: "100%",
                          minWidth: "300px !important",
                        }}
                        onChange={onSearchChange}
                        value={searchTerm}
                      />
                    </Box>
                    <Box sx={{ height: "31px" }}></Box>

                    <Box>
                      <Box>
                        {teamMembers.map((item) => {
                          const memberShiftAvailability = getShiftAvailibility(
                            item.member.id
                          );
                          return (
                            <Box key={item.member.id}>
                              <Box
                                className="team-members-container"
                                style={{
                                  height:
                                    resourceRowHeights.get(
                                      String(item.member.id)
                                    ) || "100%",
                                  minHeight: "119.984px",
                                }}
                              >
                                <TeamMembersComponent
                                  shiftType={memberShiftAvailability.shift}
                                  icon={
                                    <SwapIcon
                                      fill="#44546f"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        hasPermission("Swap_Team_Member")
                                          ? handleSwapClick(item)
                                          : () => {}
                                      }
                                    />
                                  }
                                  data={{
                                    ...item.member,
                                    availability:
                                      memberShiftAvailability.availability,
                                  }}
                                  shiftStartTime={
                                    memberShiftAvailability.shiftStartTime
                                  }
                                  shiftEndTime={
                                    memberShiftAvailability.shiftEndTime
                                  }
                                  isAvailabilityRequired={true}
                                  statusNotRequired={true}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
                {destinationMember && (
                  <Box component="div" className="padding_12">
                    <Box>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable_area">
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {teamMembers.map((item, index) => (
                                <Box key={item.member.id}>
                                  <Draggable
                                    key={item.member.id}
                                    draggableId={String(item.member.id)}
                                    index={index}
                                    isDragDisabled={item.icon !== "drag"}
                                  >
                                    {(provided2, snapshot2) => (
                                      <Box
                                        className="team-members-container"
                                        data-resource-id={item.member.id}
                                        ref={provided2.innerRef}
                                        {...provided2.draggableProps}
                                        {...provided2.dragHandleProps}
                                        style={{
                                          ...provided2.draggableProps.style,
                                          minHeight: "102px",
                                        }}
                                      >
                                        <TeamMembersComponent
                                          shiftType={
                                            getShiftAvailibility(item.member.id)
                                              .shift
                                          }
                                          icon={
                                            item.icon === "swap_blue" ? (
                                              <SwapIcon
                                                fill="#175FFA"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  hasPermission(
                                                    "Swap_Team_Member"
                                                  )
                                                    ? handleSwapClick(item)
                                                    : () => {}
                                                }
                                              />
                                            ) : item.icon === "drag" ? (
                                              <DragIcon />
                                            ) : item.icon === "swap_grey" ? (
                                              <SwapIcon
                                                fill="#44546f"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  hasPermission(
                                                    "Swap_Team_Member"
                                                  )
                                                    ? handleSwapClick(item)
                                                    : () => {}
                                                }
                                              />
                                            ) : (
                                              <></>
                                            )
                                          }
                                          data={{
                                            ...item.member,
                                            availability: getShiftAvailibility(
                                              item.member.id
                                            ).availability,
                                          }}
                                        />
                                      </Box>
                                    )}
                                  </Draggable>

                                  {item.icon === "swap_blue" && (
                                    <Box>
                                      {sourceMember === null && (
                                        <Box
                                          className="teammember_drop_area mb-md mt-md"
                                          sx={{
                                            cursor: "default",
                                            borderRadius: "10px",
                                            color: "#175FFA",
                                            px: "16px",
                                            py: "11px",
                                            border: snapshot.isUsingPlaceholder
                                              ? "2px dashed #ffffff"
                                              : "2px dashed #37424b",
                                            bgcolor: snapshot.isUsingPlaceholder
                                              ? "#404b55"
                                              : "",
                                            ":hover": {
                                              // backgroundColor: "#F0F0F0",
                                            },
                                          }}
                                        >
                                          <MediumTypography
                                            labelId="TeamMember.addButton"
                                            defaultLabel="ADD TEAM MEMBER"
                                            textColor="#404b55"
                                            sxProps={{
                                              fontSize: "16px",
                                              fontFamily: "Inter",
                                              color: snapshot.isUsingPlaceholder
                                                ? "#ffffff"
                                                : "#404b55",
                                              position:
                                                snapshot.isUsingPlaceholder
                                                  ? "absolute"
                                                  : "",
                                            }}
                                          />
                                          <Box
                                            id="droppable_area"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {provided.placeholder}
                                          </Box>
                                        </Box>
                                      )}

                                      {sourceMember && (
                                        <Box
                                          sx={{
                                            cursor: "default",
                                            borderRadius: "10px",
                                            border: "2px dashed #37424b",
                                            p: 1,
                                            mt: 2,
                                          }}
                                        >
                                          <TeamMembersComponent
                                            shiftType={
                                              getShiftAvailibility(
                                                sourceMember.member.id
                                              ).shift
                                            }
                                            icon={<></>}
                                            data={{
                                              ...sourceMember.member,
                                              availability:
                                                getShiftAvailibility(
                                                  sourceMember.member.id
                                                ).availability,
                                            }}
                                          />
                                        </Box>
                                      )}

                                      <Box
                                        className="assign_technician"
                                        sx={{
                                          bgcolor: "#113145",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleSwapSubmit}
                                      >
                                        <MediumTypography
                                          labelId="TeamMember.swap"
                                          defaultLabel="Swap Team Member"
                                          fontSize="12px"
                                          textColor="#FFFFFF"
                                          fontWeight={600}
                                        />
                                      </Box>
                                      <Box className="mt-md flex__justify__space-between">
                                        <Box className="dividerLineHorizantal_25" />
                                        <MediumTypography
                                          labelId="TeamMember.drag"
                                          defaultLabel="DRAG DROP TO ADD"
                                          fontSize="12px"
                                          textColor="#333B43"
                                          className="p-sm"
                                        />
                                        <Box className="dividerLineHorizantal_25" />
                                      </Box>

                                      <Box sx={{ mt: 1, mb: 2 }}>
                                        <SearchBox
                                          labelId="defaultSearch"
                                          defaultlabel="Search"
                                          backgroundColor="#22272B"
                                          sxProps={{
                                            width: "100%",
                                            minWidth: "300px !important",
                                          }}
                                          onChange={onSwapSearchChange}
                                          value={swapSearchTerm}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              ))}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box flexGrow={1}>
          <Box
            className="sticky-header"
            display={"flex"}
            sx={{ bgcolor: "rgba(22, 26, 29, 1)", p: "15px" }}
          >
            <Box flexGrow={1}>
              <DayPagination />
            </Box>
            <Box sx={{ ml: "10px" }}>
              <Stack direction="row" spacing={4}>
                <ToggleButtonGroup
                  value={schedulerViewContext.schedulerView}
                  exclusive
                  onChange={(__, newView) => {
                    if (newView !== null) {
                      schedulerViewContext.onSchedulerViewChange(newView);
                    }
                  }}
                  aria-label="Dashboard View"
                  sx={{
                    bgcolor: "rgba(33, 39, 44, 1)",
                    display: "flex",
                    alignItems: "center",
                    height: "42px",
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                    borderRadius: "50px",
                    padding: "4px",
                    "& .MuiToggleButton-root": {
                      padding: "4px",
                      border: "none",
                      bgcolor: "transparent",
                      color: "#44546f",
                      borderRadius: "50%",
                      width: 32,
                      height: 32,
                      "&.Mui-selected": {
                        bgcolor: "#FFFFFF",
                        color: "#3b78fb",
                        "&:hover": {
                          bgcolor: "#FFFFFF",
                          color: "#3b78fb",
                          cursor: "default",
                        },
                      },
                      "&:hover": {
                        color: "#FFF",
                      },
                    },
                  }}
                >
                  <Tooltip title="Shift View" arrow>
                    <ToggleButton
                      value="shift"
                      aria-label="shift view"
                      sx={{ mr: 1 }}
                    >
                      <CalendarMonthIcon />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title="Team View" arrow>
                    <ToggleButton value="team" aria-label="team view">
                      <GroupsIcon />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Stack>
            </Box>
          </Box>
          {!isLoading && resources.length > 0 && events.length > 0 && (
            <Box>
              {/* Scheduler Component */}
              <Box id="myCalendar" sx={{ pt: "15px" }}>
                <FullCalendar
                  ref={calendarRef}
                  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  plugins={[resourceTimelinePlugin, interactionPlugin]}
                  initialView="resourceTimeline"
                  duration={{ days: 1, hours: 6 }}
                  headerToolbar={false}
                  initialDate={eventFormattedDate()}
                  height="auto" // Adjust to fit the calendar within its parent container without vertical scrolling
                  contentHeight="auto"
                  // initialResources={resources}
                  resources={resources}
                  resourceOrder="sortOrder"
                  resourceAreaWidth={0}
                  resourceLaneDidMount={onResourceLaneDidMount}
                  resourceLaneWillUnmount={onResourceLaneWillUnmount}
                  // initialEvents={events}
                  events={events}
                  eventContent={(event) => (
                    <ScheduleItem
                      eventSchedule={event}
                      callApiCallBack={callApiCallBack}
                      subGroupId={teamViewDetails.selectedSubgroupId}
                      schedulerViewChange="team"
                    />
                  )}
                  editable={
                    isOlderThanToday ||
                    currentStatusRef.current !== "DISPATCHED"
                      ? false
                      : true
                  } // Enable dragging and dropping of events
                  droppable={
                    isOlderThanToday ||
                    currentStatusRef.current !== "DISPATCHED"
                      ? false
                      : true
                  } // Allow dropped items
                  eventDrop={onEventDrop} // Handle event drops
                  eventResizableFromStart={false} // Disables resizing from the event's start
                  eventDurationEditable={false} // Disables resizing from the event's end
                  snapDuration="00:15" // Snap/drag events to 15-minute on time slot
                  slotLabelFormat={[
                    { day: "numeric", month: "short", omitCommas: true },
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                      meridiem: false,
                    },
                  ]}
                  slotMinWidth={slotMinWidth}
                  slotDuration="00:30" // 30 minutes per slot
                  scrollTime={scrollTime}
                  nowIndicator={true}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!isLoading && teamMembersBackup.length <= 0 && (
        <Box className="woNotAvialable">
          <NotAvialableIcon width={`50px`} height={`50px`} />
          <MediumTypography
            className="noWOTitle"
            labelId="schedulerView.noWorkorderTitle"
            defaultLabel="No Work Orders for the Day"
          />
          {/* <MediumTypography
            className="noWOText"
            labelId="schedulerView.noWorkorderText"
            defaultLabel="Please contact your site in-charge."
          /> */}
        </Box>
      )}
      {isLoading && (
        <Box className="woNotAvialable">
          <Loader />
        </Box>
      )}
    </>
  );
};

export default SchedulerTeamView;
