import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppsideBar from "../layouts/appSidebar";
import SchedulerDashboard from "../pages/scheduler/SchedulerDashboard";
import { STORAGE_USER_ID_KEY } from "../api/Constant";
import ManageAbsence from "../pages/manageAbsence/ManageAbsence";
import ViewShifts from "../pages/shifts/ViewShifts";
import ModuleCleaningPlanner from "../pages/planner/ModuleCleaningPlanner";
import ShiftMonthlyPlanner from "../pages/shifts/ShiftMonthlyPlanner";
import CorrectiveMaintenance from "../pages/workOrderDetailScreens/correctiveMaintenance/CorrectiveMaintenance";
import CreateWorkOrder from "../pages/workOrderDetailScreens/correctiveMaintenance/CreateWorkOrder";
import ModuleCleaningComponent from "./../pages/workOrderDetailScreens/ModuleCleaningComponent";
import GrassCuttingComponent from "../pages/workOrderDetailScreens/GrassCuttingComponent";
import GrassCuttingPlanner from "../pages/planner/GrassCuttingPlanner";
import PreventiveMaintenance from "../pages/workOrderDetailScreens/preventiveMaintenance/PreventiveMaintenance";
import MasterDataManagement from "../pages/MasterDataManagement/MasterDataManagement";
import WelcomeScreen from "./../pages/wizards/WelcomeScreen";
import WizardParentComponent from "../pages/wizards/WizardParentComponent";
import OnboardingListScreen from "../pages/wizards/OnboardingListScreen";

import TicketLayout from "../pages/ticketing/TicketLayout";
import Ticket from "../pages/ticketing/Ticket";
import TicketListView from "../pages/ticketing/TicketListView";
import TicketDetails from "../pages/ticketing/TicketDetails";
import ErrorPage from "../pages/ticketing/ErrorPage";
import AppForceUpdate from "../pages/AppForceUpdate";

export const MANAGE_ABSENCE = "/manage-absence";
export const VIEW_SHIFTS = "/shift-monthly-planner/view-shifts";
export const SCHEDULER_DASHBOARD = "/scheduler-dashboard";
export const SCHEDULER_CARDDASHBOARD = "day-view";
export const MODULE_CLEANING_PLANNER = "/module-cleaning-planner";
export const GRASS_CUTTING_PLANNER = "/grass-cutting-planner";
export const SHIFT_MONTHLY_PLANNER = "shift-monthly-planner";
export const WORK_ORDER_EXECUTION = "/work-order-execution";
export const GRASS_CUTTING_EXECUTION = "/grass-cutting-execution";
export const CORRECTIVE_MAINTENANCE = "/corrective-maintenance";
export const PREVENTIVE_MAINTENANCE = "/preventive-maintenance";
export const PREVENTIVE_MAINTENANCE_TASKS = "/preventive-maintenance-tasks";
export const PREVENTIVE_MAINTENANCE_DETAILS = "/preventive-maintenance-details";
export const CREATE_WORK_ORDER = "/create-work-order";
export const MASTER_DATA_MANAGEMENT = "/master-data-management";
export const WIZARDS_WELCOME_SCREEN = "/welcome";
export const WIZARDS_SITE_DETAILS = "/site";
export const TICKETING_LIST_VIEW = "/ticketing/list-view";
export const TICKETING_FORM_BUILDER = "/ticketing/form-builder";
export const TICKET = "/ticket";
export const LIST_VIEW = "list-view";
export const DETAILS = "details";
export const WIZARDS_ONBOARDING = "/onboarding";
export const APP_FORCE_UPDATE = "/app-force-update";

interface RoutingPropType {
  onLogout: () => void;
  openNotification: boolean;
}
const ApplicationRoute: FC<RoutingPropType> = ({
  onLogout,
  openNotification,
}) => {
  const isAuthenticated = !!localStorage.getItem(STORAGE_USER_ID_KEY);
  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to={SCHEDULER_DASHBOARD} />
          ) : (
            <Navigate to={"/"} />
          )
        }
      />
      <Route
        path={"/"}
        element={
          <AppsideBar
            onLogout={onLogout}
            refreshToken={localStorage.getItem("refreshToken")}
            userName={localStorage.getItem(STORAGE_USER_ID_KEY)}
            openNotificationScreen={openNotification}
          />
        }
      >
        <Route path="/" element={<Navigate to={SCHEDULER_DASHBOARD} />} />
        <Route path={SCHEDULER_DASHBOARD} element={<SchedulerDashboard />} />
        <Route path={MANAGE_ABSENCE} element={<ManageAbsence />} />
        <Route path={VIEW_SHIFTS} element={<ViewShifts />} />
        <Route
          path={MODULE_CLEANING_PLANNER}
          element={<ModuleCleaningPlanner />}
        />
        <Route path={GRASS_CUTTING_PLANNER} element={<GrassCuttingPlanner />} />
        <Route path={SHIFT_MONTHLY_PLANNER} element={<ShiftMonthlyPlanner />} />
        <Route
          path={WORK_ORDER_EXECUTION}
          element={<ModuleCleaningComponent />}
        />

        <Route
          path={GRASS_CUTTING_EXECUTION}
          element={<GrassCuttingComponent />}
        />
        <Route
          path={CORRECTIVE_MAINTENANCE}
          element={<CorrectiveMaintenance />}
        />
        <Route
          path={PREVENTIVE_MAINTENANCE}
          element={<PreventiveMaintenance />}
        />
        <Route path={CREATE_WORK_ORDER} element={<CreateWorkOrder />} />
        <Route
          path={MASTER_DATA_MANAGEMENT}
          element={<MasterDataManagement />}
        />
        <Route path={WIZARDS_WELCOME_SCREEN} element={<WelcomeScreen />} />
        <Route path={WIZARDS_ONBOARDING} element={<OnboardingListScreen />} />
        <Route
          path={WIZARDS_SITE_DETAILS}
          element={<WizardParentComponent />}
        />

        <Route path={APP_FORCE_UPDATE} element={<AppForceUpdate />} />

        {/* Ticketing Routes */}
        <Route
          path={TICKET}
          element={<TicketLayout />}
          errorElement={<ErrorPage />}
        >
          <Route index={true} element={<Ticket />} />
          <Route path={LIST_VIEW} element={<TicketListView />} />
          <Route
            path={`:ticketUniqueId`}
            element={<TicketDetails key={window.location.pathname} />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export { ApplicationRoute };
