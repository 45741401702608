// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAyV74i2BBWedTrUQzKL-eays_2jV_tmGI",
  authDomain: "param-dev-8486e.firebaseapp.com",
  projectId: "param-dev-8486e",
  storageBucket: "param-dev-8486e.firebasestorage.app",
  messagingSenderId: "427679745139",
  appId: "1:427679745139:web:b52338757c13e4503fd606",
  measurementId: "G-HQ7YWYNYEE",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const getOrRegisterServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    const registration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js",
      {
        scope: "/firebase-push-notification-scope",
      }
    );

    // Wait until the Service Worker is active
    if (registration.installing) {
      await new Promise((resolve, reject) => {
        registration.installing.addEventListener(
          "statechange",
          function (event) {
            if (event.target.state === "activated") {
              resolve();
            } else if (event.target.state === "redundant") {
              reject(new Error("Service Worker installation failed"));
            }
          }
        );
      });
    } else if (registration.waiting) {
      await new Promise((resolve, reject) => {
        registration.waiting.addEventListener("statechange", function (event) {
          if (event.target.state === "activated") {
            resolve();
          } else if (event.target.state === "redundant") {
            reject(new Error("Service Worker installation failed"));
          }
        });
      });
    } else if (registration.active) {
      return registration;
    }
  } else {
    throw new Error("The browser doesn't support service workers.");
  }
};

export const getFirebaseToken = async () => {
  const serviceWorkerRegistration = await getOrRegisterServiceWorker();
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BOvAYDB5TjKyNraZIzLqPgBFVv0Cyvbiy61dD1-LuEHCVWLlVA7FNFcY4WvPWaReSsi7PdA2U3oOMbsOkv2Jjbc",
      serviceWorkerRegistration,
    });

    return token;
  } else {
    throw new Error("Notification permission not granted");
  }
};
