import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./WizardScreen.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import CustomDropdown from "../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../utils/commonFunctions";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import { fetchAllDataApi } from "../../api/MasterDataManagement/Common";
import {
  AccountData,
  ManageAccountType,
} from "../MasterDataManagement/types/accountTypes";

import ButtonComponent from "../../components/formlib/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { MASTER_DATA_MANAGEMENT } from "../../routes/Routing";

interface AccountModalProps {
  open: boolean;
  handleClose: () => void;
}

const AccountModal: React.FC<AccountModalProps> = ({ open, handleClose }) => {
  const [alignment, setAlignment] = React.useState("existingAccount");
  const accountName = useRef<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [tableData, setTableData] = useState<AccountData[]>([]);
  const navigate = useNavigate();

  const muiColumnProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 4,
    xl: 4,
  };

  useEffect(() => {
    getAccountDetails(true);
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    if (newAlignment === "newAccount") {
      navigate(MASTER_DATA_MANAGEMENT);
    }
  };

  const getAccountDetails = (
    resetData: boolean = false,
    language: string = "en"
  ) => {
    toggleLoader(true);
    // getListOfAccounts(language)
    fetchAllDataApi<ManageAccountType>("account", language, "")
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setTableData(response.account.records);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  return (
    <Box>
      <Dialog
        className="account-dialog main-wrapper-box"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ backgroundColor: "#1D2125" }}></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 16,
            color: "rgba(159, 173, 188, 1)",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            backgroundColor: "#1D2125",
            display: "flex",
            justifyContent: "center", // Keep content centered
            alignItems: "center",
            width: "100%", // Ensure it occupies the full width
            padding: "24px", // Add padding for spacing
            boxSizing: "border-box", // Ensure padding is accounted for within width
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              //   maxWidth: "450px", // Set a max width to keep content responsive
            }}
          >
            <Box sx={{ width: "450px" }}>
              {" "}
              {/* Set the width for the ToggleButtonGroup and CustomDropdown */}
              <ToggleButtonGroup
                color="primary"
                className="secondButtonCss"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{
                  gap: "16px", // Adds space between the buttons
                  width: "100%", // Make it take up full width of the parent
                }}
              >
                <ToggleButton
                  value="existingAccount"
                  sx={{
                    minWidth: "270px",
                    height: "80px",
                    borderRadius: "4px",
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                    padding: "16px",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontWeight: "700",
                    width: "100%", // Ensure it takes full width of the parent
                    justifyContent: "flex-start",
                    alignItems: "end",
                  }}
                >
                  Existing Account
                </ToggleButton>
                <ToggleButton
                  value="newAccount"
                  sx={{
                    minWidth: "270px",
                    height: "80px",
                    borderRadius: "4px",
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                    padding: "16px",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontWeight: "700",
                    width: "100%", // Ensure it takes full width of the parent,
                    justifyContent: "flex-start",
                    alignItems: "end",
                  }}
                >
                  New Account
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {/* Dropdown section below ToggleButtonGroup */}
            <Grid container spacing={2}>
              {" "}
              {/* Matching width with ToggleButtonGroup */}
              <Grid item xs={12}>
                <MediumTypography
                  className="input-label"
                  labelId="accounts.accountType"
                  defaultLabel="Account Type"
                />
                <CustomDropdown
                  disabled={alignment === "newAccount"}
                  options={getOptions(tableData)}
                  selectedValue={accountName.current}
                  onSelectedValueChange={(value) => {
                    accountName.current = value as string;
                  }}
                  sx={{
                    width: "100%", // Ensure it takes full width of the parent
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "12px 20px",
            backgroundColor: "#1D2125",
            // justifyContent: "center",
          }}
        >
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel mr-md"
                  variantType="outlined"
                  defaultLabelId={"Reset"}
                  labelId={"common.reset"}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  defaultLabelId={"Begin"}
                  labelId={"BeginButtonText"}
                  // onClick={() => {

                  // }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountModal;
