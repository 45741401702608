import { Box, Grid, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as CrossIcon } from "../../../assets/images/_CrossIcon_.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/PlusIcon.svg";
import "../css/styles.css";
import { Height } from "@mui/icons-material";

interface FileUploadProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
  viewType?: "BOX" | "RECTANGLE";
  width?: string;
  height?: string;
}

const FileUpload = ({
  files,
  onFilesChange,
  width,
  viewType = "RECTANGLE",
  height,
}: FileUploadProps) => {
  const onDrop = (acceptedFiles: File[]) => {
    const maxFileSize = 25 * 1024 * 1024;
    const validFiles = acceptedFiles.filter((file) => file.size <= maxFileSize);

    if (validFiles.length !== acceptedFiles.length) {
      return false;
    }

    const updatedFiles = [...files, ...validFiles];

    onFilesChange(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "video/*": [],
      "application/pdf": [],
      "application/svg": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "text/plain": [],
      "application/vnd.ms-powerpoint": [],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [],
    },
    maxSize: 25 * 1024 * 1024,
  });

  return (
    <Box>
      <Box
        className="upload_container common-flex-column"
        gap={1}
        sx={{
          width: width ?? "100%",
        }}
      >
        <Box
          className="upload_box"
          {...getRootProps()}
          style={{
            backgroundColor: isDragActive ? "#1d2125" : "#22272b",
            height: height ?? undefined,
          }}
        >
          <input
            {...getInputProps()}
            style={{
              display: "none", // Hide the input element
            }}
          />
          {viewType === "BOX" ? (
            <PlusIcon />
          ) : (
            <Box
              className="common-flex-column"
              alignItems={"center"}
              gap={"4px"}
            >
              <MediumTypography
                labelId={"select.file"}
                defaultLabel={"Select a file or drag and drop here"}
                className="upload-header"
              />
              <MediumTypography
                labelId={"file.size"}
                defaultLabel={
                  "JPG, PNG or PDF files are allowed, max file size 10MB"
                }
                className="upload-subheader"
              />
            </Box>
          )}
        </Box>

        <Grid container spacing={1}>
          {files.map((file, index) => {
            const fileSize = (file.size / 1024).toFixed(2);
            return (
              <Grid item xs={12} key={index}>
                <Box className="upload_item">
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <MediumTypography
                      label={`${file.name} (${fileSize}KB)`}
                      className="ticket-grey-text"
                    />
                  </Box>

                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => {
                      const updatedFiles = files.filter(
                        (f) => f.name !== file.name
                      );
                      onFilesChange(updatedFiles);
                    }}
                  >
                    <CrossIcon style={{ width: 18, height: 18 }} />
                  </IconButton>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default FileUpload;
