import { Box, Grid } from "@mui/material";
import MediumTypography from "../../components/formlib/MediumTypography";
import CustomDatePicker from "../../components/formlib/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import SearchBox from "../../components/formlib/SearchBox";
import TeamMemberWithStatusComponent from "../../components/formlib/TeamMemberWithStatusComponent";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/images/DownArrow.svg";

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import {
  TeamMembersListType,
  ViewShiftsType,
  shiftDetails,
} from "../manageAbsence/types";
import { TeamMemberForShift } from "../../utils/type";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import {
  DropdownContext,
  LoaderContext,
  LoaderContextType,
} from "../../layouts/appSidebar";
import {
  getShiftDetails,
  saveShiftPlan,
  updateShiftTimings,
} from "../../api/ShiftPlanning/ShiftPlanning";

import ShiftTimerComponent from "../manageAbsence/ShiftTimerComponent";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../utils/checkPermission";
import useAvailableWidth from "../../utils/useAvailableWidth";
import useAvailableHeight from "../../utils/useAvailableHeight";
import TeamMembersWithCancel from "../../components/formlib/TeamMembersWithCancel";
import { handleError } from "../../utils/commonFunctions";

interface ShiftNavigationParam {
  date: string;
}
const ViewShifts = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [saveButton, setSaveButton] = useState(false);
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [manageShifts, setManageShifts] = useState<ViewShiftsType | null>(null);
  const [checkedMembers, setCheckedMembers] = useState<Record<string, boolean>>(
    {}
  );
  const [pendingTechnicianId, setPendingTechnicianId] = useState<number | null>(
    null
  );
  const [pendingSourceDayPlan, setPendingSourceDayPlan] =
    useState<shiftDetails | null>(null);
  const [destinationPlan, setDestinationPlan] = useState<shiftDetails | null>(
    null
  );
  const [openClickCancelModal, setOpenClickCancelModal] = useState(false);
  const [threeConsectiveShifts, setThreeConsectiveShifts] = useState(false);
  const [onClickYes, setOnClickYes] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);
  const [openDateChangeModal, setOpenDateChangeModal] = useState(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const isPreviousDay = dayjs(selectedDate).isBefore(dayjs().startOf("day"));
  const isFutureDay = dayjs(selectedDate).isAfter(dayjs().endOf("day"));
  const [warningModal, setWarningModal] = useState(false);
  const [warningPastShiftModal, setWarningPastShiftModal] = useState(false);
  const [shiftModal, setShiftModal] = useState(false);
  const [apiResponseModal, setApiResponseModal] = useState(false);
  const [onClickPublish, setOnClickPublish] = useState(false);
  const [tempSelectedDate, setTempSelectedDate] = useState<Dayjs | null>(null);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();
  const availableHeight = useAvailableHeight(240);
  const availableWidth = useAvailableWidth(440);

  const [draggedTechnician, setDraggedTechnician] =
    useState<TeamMemberForShift | null>(null);

  const [destinationIndex, setDestinationIndex] = useState<number | null>(null);

  const context = useContext(DropdownContext);
  useEffect(() => {
    const navState = location.state as ShiftNavigationParam;
    setSelectedDate(dayjs(navState.date).toDate());
  }, []);
  const allTechniciansHaveNoMembers = manageShifts?.technicians.every(
    (technician) => technician.members.length === 0
  );

  const confirmDateChange = () => {
    if (tempSelectedDate) {
      setSelectedDate(tempSelectedDate.toDate());
      setHasDragged(false);
      setSaveButton(false);
      setCheckedMembers({});
      setOpenDateChangeModal(false);
    }
  };
  const getFormattedShiftRange = (shiftRange: string) => {
    const [startTime, endTime] = shiftRange.split("-").map((time) => {
      const [hours, minutes] = time.trim().split(":");
      // Convert hours and minutes to numbers for comparison
      const numHours = parseInt(hours, 10);
      const numMinutes = parseInt(minutes, 10);
      // If minutes are 59, round up the hours
      if (numMinutes === 59) {
        return `${numHours + 1}:00`.padStart(5, "0");
      }
      return `${hours}:${minutes.substring(0, 2)}`;
    });
    return `${startTime} - ${endTime}`;
  };

  const toggleCheckStatus = (memberId: string) => {
    setCheckedMembers((prevCheckedMembers) => ({
      ...prevCheckedMembers,
      [memberId]: !prevCheckedMembers[memberId],
    }));
  };

  const generateUniqueId = () => {
    const uniqueNumber = parseInt(
      (
        Date.now().toString().slice(-5) +
        Math.floor(Math.random() * 100000).toString()
      ).slice(0, 10),
      10
    );
    return uniqueNumber;
  };

  const processShiftDetails = (shiftData: shiftDetails[]): shiftDetails[] => {
    const memberMap: Record<string, number> = {}; // To track unique members

    return shiftData.map((shift) => {
      const processedMembers = shift.shiftMembers.map((member) => {
        const memberKey = `${member.id}-${member.name}-${member.title}`;

        if (memberMap[memberKey]) {
          // If the member with the same id, name, and title has been seen before, create a new unique ID
          const uniqueId = generateUniqueId();
          return {
            ...member,
            elementId: member.id,
            id: uniqueId.toString(),
          };
        } else {
          // If the member is unique, store it in the map
          memberMap[memberKey] = member.id as number;
          return member;
        }
      });

      return {
        ...shift,
        shiftMembers: processedMembers,
      };
    });
  };

  useEffect(() => {
    if (selectedDate) {
      getShiftData();
    }
  }, [
    selectedDate,
    context.selectedSubgroupId,
    context.selectedAssetGroupsIds,
  ]);
  const getShiftData = () => {
    const DateSelected = dayjs(selectedDate).format("YYYY-MM-DD");
    toggleLoader(true);
    getShiftDetails(context.selectedSubgroupId, DateSelected)
      .then((response) => {
        toggleLoader(false);
        const processedResponse = {
          ...response,
          shiftTimingsAndMembers: processShiftDetails(
            response.shiftTimingsAndMembers
          ),
        };
        setManageShifts(processedResponse);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const handleDraftSave = () => {
    if (manageShifts !== null) {
      // Clone manageShifts to avoid mutating state directly
      let updatedManageShifts: ViewShiftsType = JSON.parse(
        JSON.stringify(manageShifts)
      );

      // Set the selectedDate and shiftPlanStatus based on allTechniciansHaveMembers
      updatedManageShifts.selectedDate =
        dayjs(selectedDate).format("YYYY-MM-DD");
      updatedManageShifts.shiftPlanStatus = "DRAFT";
      updatedManageShifts.shiftTimingsAndMembers.forEach((shift) => {
        shift.shiftMembers = filterUniqueMembers(shift.shiftMembers).map(
          (member) => {
            if (member.userShift !== shift.shiftStaticName) {
              member.isNotificationSent = false;
            }
            return member;
          }
        );
        return shift;
      });

      // Save the updated shift plan
      toggleLoader(true);
      saveShiftPlan(
        context.selectedSubgroupId,
        updatedManageShifts.shiftPlanStatus,
        updatedManageShifts
      )
        .then(() => {
          setApiResponseModal(true);
          getShiftData();
          setSaveButton(false);
          setHasDragged(false);
        })
        .catch((error) => {
          setOpenErrorModal(true);
          handleError(error, setErrorDesc);
          toggleLoader(false);
        });
    }
  };
  const filterUniqueMembers = (
    members: TeamMemberForShift[]
  ): TeamMemberForShift[] => {
    const uniqueMembers: TeamMemberForShift[] = [];
    const seen = new Set();

    members.forEach((member) => {
      const memberId = member.elementId || member.id;

      if (!seen.has(memberId)) {
        seen.add(memberId);
        // If member has elementId, set id to elementId
        if (member.elementId) {
          member.id = member.elementId;
        }
        uniqueMembers.push(member);
      }
    });

    return uniqueMembers;
  };

  const handlePublish = () => {
    if (manageShifts !== null) {
      let updatedManageShifts: ViewShiftsType = JSON.parse(
        JSON.stringify(manageShifts)
      );
      // Replace shiftMembers with unique members from the Map
      updatedManageShifts.shiftTimingsAndMembers.forEach((shift) => {
        shift.shiftMembers = filterUniqueMembers(shift.shiftMembers);

        return shift;
      });

      updatedManageShifts.selectedDate =
        dayjs(selectedDate).format("YYYY-MM-DD");
      updatedManageShifts.shiftPlanStatus = "PUBLISH";

      toggleLoader(true);

      saveShiftPlan(
        context.selectedSubgroupId,
        updatedManageShifts.shiftPlanStatus,
        updatedManageShifts
      )
        .then(() => {
          setOnClickPublish(true);
          setApiResponseModal(true);
          getShiftData();
          setSaveButton(false);
          setHasDragged(false);
        })
        .catch((error) => {
          setOpenErrorModal(true);
          handleError(error, setErrorDesc);
          toggleLoader(false);
        });
    }
  };

  const handleShiftTime = (data: shiftDetails[]) => {
    const date = dayjs(selectedDate).format("YYYY-MM-DD");
    toggleLoader(true);
    updateShiftTimings(context.selectedSubgroupId, date, data)
      .then(() => {
        toggleLoader(false);
        setShiftModal(false);
      })
      .catch((error) => {
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
        toggleLoader(false);
      });
  };

  const filteredTechnicians =
    manageShifts?.technicians &&
    manageShifts?.technicians
      .map((technician) => {
        const matchingMembers = technician.members.filter(
          (member) =>
            member.name.toLowerCase().includes(searchTerm) ||
            member.title.toLowerCase().includes(searchTerm)
        );

        return {
          ...technician,
          members: matchingMembers,
        };
      })
      .filter(
        (technician) =>
          technician.members.length > 0 ||
          technician.title.toLowerCase().includes(searchTerm)
      );

  useEffect(() => {
    if (manageShifts?.technicians) {
      if (searchTerm) {
        manageShifts?.technicians.forEach((technician) => {
          setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [technician.title]: true, // Keep them expanded by default
          }));
        });
      }

      if (!searchTerm) {
        setExpanded({});
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (manageShifts?.technicians) {
      const initialExpandedState = manageShifts?.technicians.reduce(
        (acc, item) => {
          acc[item.title] = false;
          return acc;
        },
        {} as Record<string, boolean>
      );

      setExpanded(initialExpandedState);
    }
  }, []);

  const handleTechnicianMembers = (titleName: string) => {
    setExpanded((prevExpanded) => {
      const isExpanded = prevExpanded[titleName];

      if (isExpanded) {
        return {
          ...prevExpanded,
          [titleName]: false,
        };
      } else {
        return {
          ...prevExpanded,
          [titleName]: true,
        };
      }
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value.toLowerCase());
  };
  useEffect(() => {
    if (
      onClickYes &&
      pendingTechnicianId !== null &&
      pendingSourceDayPlan !== null &&
      destinationPlan !== null
    ) {
      // Assuming you have access to sourceDayPlan and technicianId here
      let updatedManageShifts = JSON.parse(JSON.stringify(manageShifts));
      const sourceDayPlan = updatedManageShifts.shiftTimingsAndMembers.find(
        (shift: shiftDetails) =>
          shift.shiftStaticName === pendingSourceDayPlan.shiftStaticName
      );
      const destination = updatedManageShifts.shiftTimingsAndMembers.find(
        (shift: shiftDetails) =>
          shift.shiftStaticName === destinationPlan.shiftStaticName
      );

      if (sourceDayPlan) {
        const sourceTechnicianIndex =
          pendingSourceDayPlan.shiftMembers.findIndex(
            (member: TeamMemberForShift) =>
              typeof member.id === "string"
                ? parseInt(member.id) === pendingTechnicianId
                : member.id === pendingTechnicianId
          );
        if (sourceTechnicianIndex !== -1) {
          // Remove the technician from the source day plan
          sourceDayPlan.shiftMembers.splice(sourceTechnicianIndex, 1);
        }
      }
      if (destinationIndex !== undefined && draggedTechnician) {
        if (!destination) {
          return;
        }

        if (!Array.isArray(destination.shiftMembers)) {
          return;
        }

        destination.shiftMembers.splice(destinationIndex, 0, draggedTechnician);
      }

      setManageShifts(updatedManageShifts);
      // Reset the onClickYes to false for future confirmations
      setOnClickYes(false);
      setPendingTechnicianId(null);
      setPendingSourceDayPlan(null);
      setDraggedTechnician(null);
      setHasDragged(true); // If you have a state indicating a drag operation has occurred
      // Also, close the modal if it's still open
      setThreeConsectiveShifts(false);
      setDestinationIndex(null);
    }
  }, [onClickYes]);

  const logDetails = (
    shift: TeamMemberForShift[],
    item: TeamMemberForShift
  ) => {
    return shift.some((itemData: TeamMemberForShift) => {
      const results =
        typeof itemData.id === "string"
          ? itemData.elementId === item?.elementId ||
            itemData.elementId === item?.id
          : itemData.id === item?.elementId;

      return results;
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    let newManageShifts: ViewShiftsType = JSON.parse(
      JSON.stringify(manageShifts)
    );

    // Extract the technician id from the draggableId
    const findShiftByName = (shiftName: string) =>
      newManageShifts.shiftTimingsAndMembers.find(
        (shift) => shift.shiftStaticName === shiftName
      );
    const handleSingleTechnicianMove = (technicianId: number) => {
      // Find the destination list in the plan array
      const sourceName = source.droppableId.split("/")[1];

      const destinationName = destination.droppableId.split("/")[1];

      setDestinationIndex(destination.index);
      const destinationList = findShiftByName(destinationName);
      const sourceList = findShiftByName(sourceName);
      let draggedItem: TeamMemberForShift | undefined;

      const isSourceShiftTimingsAndMembers =
        newManageShifts.shiftTimingsAndMembers.some(
          (shift) => shift.shiftStaticName === sourceName
        );

      const isSameDayDrag =
        isSourceShiftTimingsAndMembers &&
        destinationList !== undefined &&
        source.droppableId === destination.droppableId;

      const gShift = findShiftByName("G");
      const aShift = findShiftByName("A");
      const bShift = findShiftByName("B");
      const cShift = findShiftByName("C");

      const isDestinationSpecialType = [
        manageShifts?.timeOffType.type,
        manageShifts?.weekOffType.type,
      ].includes(destinationName);
      const findAndRemoveFromSource = (
        membersList: TeamMemberForShift[],
        memberId: number
      ) => {
        const index = membersList.findIndex((member) => member.id === memberId);
        return index !== -1 ? membersList.splice(index, 1)[0] : undefined;
      };

      const handleTechnicianDragFromList = () => {
        const sourceList = newManageShifts.technicians.find((list) =>
          list.members.some((member) => member.id === technicianId)
        );
        if (sourceList && source.droppableId !== destination.droppableId)
          draggedItem = findAndRemoveFromSource(
            sourceList.members,
            technicianId
          );
      };

      const selectedDateIsToday =
        selectedDate &&
        selectedDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

      const currentTime = new Date();
      const [startTime] =
        (destinationList &&
          destinationList.shiftTimeRange.split("-").map((time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return new Date(
              currentTime.getFullYear(),
              currentTime.getMonth(),
              currentTime.getDate(),
              hours,
              minutes
            );
          })) ||
        [];
      const [sourceStartTime] =
        (sourceList &&
          sourceList.shiftTimeRange.split("-").map((time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return new Date(
              currentTime.getFullYear(),
              currentTime.getMonth(),
              currentTime.getDate(),
              hours,
              minutes
            );
          })) ||
        [];
      const handleDragFromSpecialTypes = () => {
        if (currentTime > startTime && selectedDateIsToday) {
          setWarningPastShiftModal(true);
          return;
        }
        if (
          sourceName === manageShifts?.timeOffType.type &&
          source.droppableId !== destination.droppableId
        ) {
          if (newManageShifts.timeOffType.members)
            draggedItem = findAndRemoveFromSource(
              newManageShifts.timeOffType.members,
              technicianId
            );
        } else if (
          (sourceName === manageShifts?.weekOffType.type &&
            source.droppableId) !== destination.droppableId
        ) {
          if (newManageShifts.weekOffType.members)
            draggedItem = findAndRemoveFromSource(
              newManageShifts.weekOffType.members,
              technicianId
            );
        }
      };

      const validateShiftMove = (item: TeamMemberForShift | undefined) => {
        if (item) {
          const InaShift = aShift && logDetails(aShift.shiftMembers, item);
          const IncShift = cShift && logDetails(cShift.shiftMembers, item);
          const InbShift = bShift && logDetails(bShift.shiftMembers, item);
          const IngShift = gShift && logDetails(gShift.shiftMembers, item);
          if (
            (sourceName === "C" &&
              destinationName === "G" &&
              (InaShift || InbShift)) ||
            (sourceName === "A" &&
              destinationName === "G" &&
              (InaShift || InbShift)) ||
            (sourceName === "B" &&
              destinationName === "G" &&
              (InaShift || InbShift)) ||
            (sourceName === "C" && destinationName === "A" && IngShift) ||
            (sourceName === "A" && destinationName === "B" && IngShift) ||
            (sourceName === "C" && destinationName === "B" && IngShift) ||
            (sourceName === "G" && destinationName === "A" && IngShift) ||
            (sourceName === "G" && destinationName === "B" && IngShift) ||
            (sourceName === "A" && destinationName === "B" && InbShift) ||
            (sourceName === "B" && destinationName === "A" && InaShift) ||
            (sourceName === "B" && destinationName === "C" && IncShift) ||
            (sourceName === "C" && destinationName === "B" && InbShift) ||
            (sourceName === "C" && destinationName === "A" && InaShift) ||
            (sourceName === "A" && destinationName === "C" && IncShift)
          ) {
            return false;
          }
          return true;
        }
      };

      if (source.droppableId.startsWith("technicians-list")) {
        // If dragging from technicians list, remove from the source
        if (currentTime > startTime && selectedDateIsToday) {
          setWarningPastShiftModal(true);
          return;
        } else {
          handleTechnicianDragFromList();
        }
      } else if (destination.droppableId.startsWith("technicians-list")) {
        return;
      } else if (
        sourceName === manageShifts?.timeOffType.type ||
        sourceName === manageShifts?.weekOffType.type
      ) {
        handleDragFromSpecialTypes();
      } else {
        // If dragging from within a day plan, get reference of the technician
        if (currentTime > startTime && selectedDateIsToday && !isSameDayDrag) {
          setWarningPastShiftModal(true);
          return;
        } else if (
          currentTime > sourceStartTime &&
          selectedDateIsToday &&
          !isSameDayDrag
        ) {
          const copies = newManageShifts.shiftTimingsAndMembers
            .find((shift) => shift.shiftStaticName === sourceName)
            ?.shiftMembers.filter((member) =>
              typeof member.id === "string"
                ? parseInt(member.id) === technicianId
                : member.id === technicianId
            );

          const copyObject = newManageShifts.shiftTimingsAndMembers
            .find((shift) => shift.shiftStaticName === sourceName)
            ?.shiftMembers.filter((member) => {
              if (
                member.name === copies?.[0].name &&
                member.title === copies?.[0].title
              ) {
                // Check if IDs or elementId match based on type
                if (
                  typeof member.id === "string" &&
                  typeof copies?.[0].id === "string"
                ) {
                  return member.elementId === copies?.[0].elementId;
                } else if (
                  typeof member.id === "string" &&
                  typeof copies?.[0].id === "number"
                ) {
                  return member.elementId === copies?.[0].id;
                } else if (
                  typeof member.id === "number" &&
                  typeof copies?.[0].id === "string"
                ) {
                  return member.id === copies?.[0].elementId;
                } else {
                  return member.id === copies?.[0].id;
                }
              }
              return false; // Return false to exclude non-matching members
            });
          if (copyObject && copyObject.length > 1) {
            draggedItem = copyObject?.find((member: TeamMemberForShift) =>
              typeof member.id === "string"
                ? parseInt(member.id) === technicianId
                : member.id === technicianId
            );
          } else {
            return;
          }
        } else {
          const sourceDayPlan: shiftDetails | undefined =
            newManageShifts.shiftTimingsAndMembers.find(
              (shift: shiftDetails) => shift.shiftStaticName === sourceName
            );

          if (sourceDayPlan) {
            draggedItem = sourceDayPlan?.shiftMembers.find(
              (member: TeamMemberForShift) =>
                typeof member.id === "string"
                  ? parseInt(member.id) === technicianId
                  : member.id === technicianId
            );
          }
        }
      }

      if (draggedItem) {
        const InaShift = aShift && logDetails(aShift.shiftMembers, draggedItem);
        const IncShift = cShift && logDetails(cShift.shiftMembers, draggedItem);
        const InbShift = bShift && logDetails(bShift.shiftMembers, draggedItem);
        const IngShift = gShift && logDetails(gShift.shiftMembers, draggedItem);
        // If it's a same-day drag, create a copy of the dragged technician with a new ID
        if (isSameDayDrag) {
          // Generate a 10-digit unique number
          const uniqueNumber = parseInt(
            (
              Date.now().toString().slice(-5) +
              Math.floor(Math.random() * 100000).toString()
            ).slice(0, 10),
            10
          );

          draggedItem = {
            ...draggedItem,
            id: `${uniqueNumber}`,
            elementId:
              typeof draggedItem.id === "string"
                ? draggedItem.elementId
                : draggedItem.id,
          };
        }

        if (!isSameDayDrag) {
          const sourceDayPlan = newManageShifts.shiftTimingsAndMembers.find(
            (shift: shiftDetails) => shift.shiftStaticName === sourceName
          );
          const isTechnicianInAnyShift =
            newManageShifts.shiftTimingsAndMembers.some((shift: shiftDetails) =>
              shift.shiftMembers.some((member: TeamMemberForShift) =>
                typeof member.id === "string"
                  ? member.elementId === draggedItem?.elementId ||
                    draggedItem?.id
                  : member.elementId === technicianId
              )
            );
          if (sourceDayPlan) {
            if (
              sourceName === "C" &&
              destinationName === "B" &&
              destinationList !== undefined
            ) {
              setPendingTechnicianId(technicianId);
              setPendingSourceDayPlan(sourceDayPlan);
              setDraggedTechnician(draggedItem);
              setDestinationPlan(destinationList);
            } else if (
              sourceName === "A" &&
              destinationName === "B" &&
              destinationList !== undefined
            ) {
              setPendingTechnicianId(technicianId);
              setPendingSourceDayPlan(sourceDayPlan);
              setDraggedTechnician(draggedItem);
              setDestinationPlan(destinationList);
            } else if (
              sourceName === "B" &&
              destinationName === "C" &&
              destinationList !== undefined
            ) {
              setPendingTechnicianId(technicianId);
              setPendingSourceDayPlan(sourceDayPlan);
              setDraggedTechnician(draggedItem);
              setDestinationPlan(destinationList);
            } else if (
              sourceName === "B" &&
              destinationName === "A" &&
              destinationList !== undefined
            ) {
              setPendingTechnicianId(technicianId);
              setPendingSourceDayPlan(sourceDayPlan);
              setDraggedTechnician(draggedItem);
              setDestinationPlan(destinationList);
            } else if (
              sourceName === "C" &&
              destinationName === "A" &&
              destinationList !== undefined
            ) {
              setPendingTechnicianId(technicianId);
              setPendingSourceDayPlan(sourceDayPlan);
              setDraggedTechnician(draggedItem);
              setDestinationPlan(destinationList);
            } else if (
              sourceName === "A" &&
              destinationName === "C" &&
              destinationList !== undefined
            ) {
              setPendingTechnicianId(technicianId);
              setPendingSourceDayPlan(sourceDayPlan);
              setDraggedTechnician(draggedItem);
              setDestinationPlan(destinationList);
            }

            if (isTechnicianInAnyShift && isDestinationSpecialType) {
              return; // Exit without updating the state
            } else if (!validateShiftMove(draggedItem)) {
              return;
            } else {
              const sourceTechnicianIndex =
                sourceDayPlan.shiftMembers.findIndex(
                  (member: TeamMemberForShift) =>
                    typeof member.id === "string"
                      ? parseInt(member.id) === technicianId
                      : member.id === technicianId
                );

              if (sourceTechnicianIndex !== -1) {
                sourceDayPlan.shiftMembers.splice(sourceTechnicianIndex, 1);
              }
            }
          }
        }

        if (destinationList) {
          if (
            sourceName === "C" &&
            destinationName === "B" &&
            !IngShift &&
            InaShift &&
            IncShift
          ) {
            setThreeConsectiveShifts(true);
          } else if (
            sourceName === "A" &&
            destinationName === "B" &&
            !IngShift &&
            IncShift &&
            InaShift
          ) {
            setThreeConsectiveShifts(true);
          } else if (
            sourceName === "B" &&
            destinationName === "C" &&
            !IngShift &&
            InaShift &&
            InbShift
          ) {
            setThreeConsectiveShifts(true);
          } else if (
            sourceName === "B" &&
            destinationName === "A" &&
            !IngShift &&
            IncShift &&
            InbShift
          ) {
            setThreeConsectiveShifts(true);
          } else if (
            sourceName === "C" &&
            destinationName === "A" &&
            !IngShift &&
            InbShift &&
            IncShift
          ) {
            setThreeConsectiveShifts(true);
          } else if (
            sourceName === "A" &&
            destinationName === "C" &&
            !IngShift &&
            InbShift &&
            InaShift
          ) {
            setThreeConsectiveShifts(true);
          } else {
            destinationList.shiftMembers.splice(destination.index, 0, {
              ...draggedItem,
            });
          }
        }

        if (draggedItem && destinationName === manageShifts?.timeOffType.type) {
          newManageShifts.timeOffType.members &&
            newManageShifts.timeOffType.members.splice(destination.index, 0, {
              ...draggedItem,
            });
        }

        if (draggedItem && destinationName === manageShifts?.weekOffType.type) {
          newManageShifts.weekOffType.members &&
            newManageShifts.weekOffType.members.splice(destination.index, 0, {
              ...draggedItem,
            });
        }
      }
    };
    // Check if this is a multiple drag scenario
    const isMultipleDrag = Object.keys(checkedMembers).some(
      (key) => checkedMembers[key]
    );
    if (isMultipleDrag) {
      // Handle moving multiple checked items
      Object.keys(checkedMembers).forEach((id) => {
        if (checkedMembers[id]) {
          handleSingleTechnicianMove(parseInt(id));
        }
      });
      setCheckedMembers({});
    } else {
      // Handle single drag
      const technicianId = parseInt(draggableId.split("-")[1]);
      handleSingleTechnicianMove(technicianId);
    }
    setManageShifts(newManageShifts);

    setHasDragged(true);
  };

  // from week off removing technician
  const handleRemoveWeekOffClick = (technicianId: number | string) => {
    setManageShifts((prev) => {
      const newManageShift = JSON.parse(JSON.stringify(prev));

      // Directly access the members of weekOffType
      const technicianIndex = newManageShift.weekOffType.members.findIndex(
        (member: TeamMemberForShift) => member.id === technicianId
      );

      // If the technician is not found, do nothing
      if (technicianIndex === -1) {
        return prev;
      }

      // Remove the technician from weekOffType
      const [removedTechnician] = newManageShift.weekOffType.members.splice(
        technicianIndex,
        1
      );

      // Find the group in technicians array that matches the removed technician's title
      const titleGroup = newManageShift.technicians.find(
        (group: TeamMembersListType) => group.title === removedTechnician.title
      );

      // If the group is found, add the removed technician back to the group
      if (titleGroup) {
        titleGroup.members.push(removedTechnician);
      } else {
        // If the group is not found, create a new group and add the technician to it
        newManageShift.technicians.push({
          title: removedTechnician.title,
          members: [removedTechnician],
        });
      }

      // Here we assume setCheckedMembers is a function to update the state of checked members

      return newManageShift;
    });

    setHasDragged(true); // This line assumes setHasDragged is a state setter function
  };

  //From Time off removing the technician
  const handleRemoveTimeOffClick = (technicianId: number | string) => {
    setManageShifts((prev) => {
      const newManageShift = JSON.parse(JSON.stringify(prev));

      // Directly access the members of weekOffType
      const technicianIndex = newManageShift.timeOffType.members.findIndex(
        (member: TeamMemberForShift) => member.id === technicianId
      );

      // If the technician is not found, do nothing
      if (technicianIndex === -1) {
        return prev;
      }

      // Remove the technician from weekOffType
      const [removedTechnician] = newManageShift.timeOffType.members.splice(
        technicianIndex,
        1
      );

      // Find the group in technicians array that matches the removed technician's title
      const titleGroup = newManageShift.technicians.find(
        (group: TeamMembersListType) => group.title === removedTechnician.title
      );

      // If the group is found, add the removed technician back to the group
      if (titleGroup) {
        titleGroup.members.push(removedTechnician);
      } else {
        // If the group is not found, create a new group and add the technician to it
        newManageShift.technicians.push({
          title: removedTechnician.title,
          members: [removedTechnician],
        });
      }

      // Here we assume setCheckedMembers is a function to update the state of checked members

      return newManageShift;
    });

    setHasDragged(true); // This line assumes setHasDragged is a state setter function
  };

  //from shifts removing the technicians and cloning also
  const handleRemoveClick = (technicianId: number | string, name: string) => {
    setManageShifts((prev) => {
      const newManageShift = JSON.parse(JSON.stringify(prev));

      // Find the day from which we are removing the technician
      const shiftPlan = newManageShift.shiftTimingsAndMembers.find(
        (shift: shiftDetails) => shift.shiftName === name
      );

      const technicianIndex = shiftPlan.shiftMembers.findIndex(
        (member: TeamMemberForShift) => member.id === technicianId
      );

      // If the technician is not found, do nothing
      if (technicianIndex === -1) {
        return prev;
      }

      // Remove the technician from the day plan
      const [removedTechnician] = shiftPlan.shiftMembers.splice(
        technicianIndex,
        1
      );

      // Check if the technician is scheduled for multiple days
      const isScheduledMultipleDays =
        newManageShift.shiftTimingsAndMembers.some((shift: shiftDetails) =>
          shift.shiftMembers.some((member: TeamMemberForShift) => {
            return typeof member.id === "string"
              ? member.elementId === removedTechnician?.elementId ||
                  removedTechnician?.id
              : member.id === removedTechnician?.elementId;
          })
        );

      // If the technician is only scheduled for one day, add them back to the appropriate teamMembersWithStatus
      if (!isScheduledMultipleDays) {
        // Assuming technicians is an array of objects, each with a title and members array
        const titleGroup = newManageShift.technicians.find(
          (group: TeamMembersListType) =>
            group.title === removedTechnician.title
        );

        if (titleGroup) {
          if (
            typeof removedTechnician.id === "string" &&
            removedTechnician.elementId !== undefined
          ) {
            removedTechnician.id = removedTechnician.elementId;
            removedTechnician.elementId = undefined;
          }

          titleGroup.members.push(removedTechnician);
        } else {
          // If the group is not found, create a new group and add the technician to it
          newManageShift.technicians.push({
            title: removedTechnician.title,
            members: [removedTechnician],
          });
        }
      }

      return newManageShift;
    });
    setHasDragged(true);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box component={"main"} className="p-md">
        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
        {manageShifts && (
          <Box component={"section"} className="pt-md pb-md pl-sm pr-sm">
            {openClickCancelModal && (
              <ModalPopup
                descriptionText="Weekoff.description"
                open={openClickCancelModal}
                handleClose={() => setOpenClickCancelModal(false)}
                onPositiveClick={() => {
                  setSaveButton(false);
                  getShiftData();
                  setOpenClickCancelModal(false);
                  setCheckedMembers({});
                  setHasDragged(false);
                }}
                onNegativeClick={() => {
                  setOpenClickCancelModal(false);
                }}
                positiveButtonLabel="Yes"
                positiveButtonLabelId="YesText"
                negativeButtonLabel="No"
                negativeButtonLabelId="NoText"
              />
            )}
            {threeConsectiveShifts && (
              <ModalPopup
                descriptionText="ConsecutiveShifts.description"
                open={threeConsectiveShifts}
                handleClose={() => setThreeConsectiveShifts(false)}
                onPositiveClick={() => {
                  setThreeConsectiveShifts(false);
                  setOnClickYes(true);
                }}
                onNegativeClick={() => {
                  setThreeConsectiveShifts(false);
                  setOnClickYes(false);

                  if (
                    pendingSourceDayPlan &&
                    draggedTechnician &&
                    destinationPlan
                  ) {
                    let newManageShifts: ViewShiftsType = JSON.parse(
                      JSON.stringify(manageShifts)
                    );

                    // Remove the technician from the destination
                    const destinationList =
                      newManageShifts.shiftTimingsAndMembers.find(
                        (shift: shiftDetails) =>
                          shift.shiftStaticName ===
                          destinationPlan.shiftStaticName
                      );

                    if (destinationList) {
                      const technicianIndex =
                        destinationList.shiftMembers.findIndex(
                          (member: TeamMemberForShift) =>
                            typeof member.id === "string"
                              ? parseInt(member.id) === draggedTechnician.id
                              : member.id === draggedTechnician.id
                        );

                      if (technicianIndex !== -1) {
                        destinationList.shiftMembers.splice(technicianIndex, 1);
                      }
                    }

                    // Add the technician back to the source
                    const sourceDayPlan =
                      newManageShifts.shiftTimingsAndMembers.find(
                        (shift: shiftDetails) =>
                          shift.shiftStaticName ===
                          pendingSourceDayPlan.shiftStaticName
                      );

                    if (sourceDayPlan) {
                      sourceDayPlan.shiftMembers.push(draggedTechnician);
                    }
                    // Update the state with the modified manageShifts
                    setManageShifts(newManageShifts);
                  }

                  // Reset the dragged technician and source day plan
                  setDraggedTechnician(null);
                  setPendingSourceDayPlan(null);
                }}
                positiveButtonLabel="Yes"
                positiveButtonLabelId="YesText"
                negativeButtonLabel="No"
                negativeButtonLabelId="NoText"
              />
            )}
            {apiResponseModal && (
              <ModalPopup
                descriptionText={
                  onClickPublish ? "Publish.successSave" : "Save.successSave"
                }
                open={apiResponseModal}
                handleClose={() => setApiResponseModal(false)}
                onPositiveClick={() => {
                  setApiResponseModal(false);
                  setOnClickPublish(false);
                }}
                positiveButtonLabel="Ok"
                positiveButtonLabelId="WarningText.ok"
              />
            )}
            {openDateChangeModal && (
              <ModalPopup
                descriptionText="Weekoff.description"
                open={openDateChangeModal}
                handleClose={() => setOpenDateChangeModal(false)}
                onPositiveClick={confirmDateChange}
                onNegativeClick={() => {
                  setOpenDateChangeModal(false);
                }}
                positiveButtonLabel="Yes"
                positiveButtonLabelId="YesText"
                negativeButtonLabel="No"
                negativeButtonLabelId="NoText"
              />
            )}
            {shiftModal && !isPreviousDay && !isFutureDay && (
              <ShiftTimerComponent
                open={shiftModal}
                onSave={(data: shiftDetails[]) => {
                  if (data) {
                    handleShiftTime(data);
                  }
                }}
                onNegativeClick={() => {
                  setShiftModal(false);
                }}
                handleClose={() => setShiftModal(false)}
                data={manageShifts.shiftTimingsAndMembers}
              />
            )}
            {warningModal && (
              <ModalPopup
                descriptionText="WarningText.Publish"
                open={warningModal}
                handleClose={() => setWarningModal(false)}
                onPositiveClick={() => {
                  setWarningModal(false);
                }}
                positiveButtonLabel="Ok"
                positiveButtonLabelId="WarningText.ok"
              />
            )}
            {warningPastShiftModal && (
              <ModalPopup
                descriptionText="WarningText.Previousshifts"
                open={warningPastShiftModal}
                handleClose={() => setWarningPastShiftModal(false)}
                onPositiveClick={() => {
                  setWarningPastShiftModal(false);
                  setCheckedMembers({});
                }}
                positiveButtonLabel="Ok"
                positiveButtonLabelId="WarningText.ok"
              />
            )}

            <Box className="flex__justify__space-between mb-md">
              <Box sx={{ width: "max-content" }}>
                <CustomDatePicker
                  date={dayjs(selectedDate)}
                  disableFutureDate={false}
                  hideIcon={true}
                  leftArrowRequired={true}
                  rightArrowRequired={true}
                  onDateSelect={(newDate: Dayjs) => {
                    if (hasDragged && saveButton) {
                      setOpenDateChangeModal(true);
                      setTempSelectedDate(newDate);
                    } else {
                      setSelectedDate(newDate.toDate());
                      setSaveButton(false);
                    }
                  }}
                />
              </Box>

              <Box className="flex__">
                {saveButton && (
                  <ButtonComponent
                    className="btn-primary btn-cancel "
                    labelId="btn.cancel"
                    defaultLabelId="Cancel"
                    onClick={() => {
                      if (saveButton) {
                        if (hasDragged) {
                          setOpenClickCancelModal(true);
                        } else {
                          setSaveButton(false);
                        }
                      }
                    }}
                  />
                )}
                {saveButton ? (
                  <>
                    <ButtonComponent
                      className="btn-primary btn-submit ml-sm"
                      labelId="btn.save"
                      defaultLabelId="Save"
                      onClick={() => {
                        if (hasPermission("Edit_Shifts")) {
                          handleDraftSave();
                        }
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ButtonComponent
                      className="btn-primary btn-cancel ml-sm"
                      labelId="btn.cancel"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        navigate("/shift-monthly-planner");
                      }}
                    />
                    {allTechniciansHaveNoMembers &&
                      !isPreviousDay &&
                      manageShifts.shiftPlanStatus === "DRAFT" && (
                        <ButtonComponent
                          className="btn-primary btn-submit "
                          labelId="btn.publish"
                          defaultLabelId="Publish"
                          onClick={() => {
                            if (hasPermission("Edit_Shifts")) {
                              handlePublish();
                            }
                          }}
                        />
                      )}

                    {!isPreviousDay && (
                      <ButtonComponent
                        className={`btn-primary ${
                          hasPermission("Edit_Shifts")
                            ? "btn-submit"
                            : "btn-disabled"
                        }  ml-sm`}
                        labelId="btn.edit"
                        defaultLabelId="Edit"
                        onClick={() =>
                          hasPermission("Edit_Shifts")
                            ? setSaveButton(true)
                            : setSaveButton(false)
                        }
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box className="flex__ ">
              {!isPreviousDay && manageShifts?.technicians.length > 0 && (
                <Box className="mr-sm">
                  <Box className="teamMember_weekoff">
                    <MediumTypography
                      labelId="TeamMembers.title"
                      defaultLabel="Team Members"
                      fontSize="16px"
                      fontWeight={600}
                    />
                    <Box className="teamMember_count">
                      <MediumTypography
                        label={
                          manageShifts?.presentUserCount +
                          "/" +
                          manageShifts?.totalCount
                        }
                        fontSize="12px"
                        fontWeight={400}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#191E22",
                      position: "relative",
                      overflowY: "auto",
                      maxHeight: availableHeight,
                      height: availableHeight,
                    }}
                    className="tableStyles"
                  >
                    <Box
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "#191E22",
                        minWidth: "300px",
                      }}
                      className="pt-md pl-md pr-md"
                    >
                      <SearchBox
                        labelId="defaultSearch"
                        defaultlabel="Search"
                        backgroundColor="#22272B"
                        onChange={handleSearchChange}
                        sxProps={{
                          minWidth: "250px",
                        }}
                      />
                    </Box>
                    <Box className="pb-md pl-md pr-md">
                      <ul
                        className="no-marker "
                        style={{
                          scrollbarWidth: "none",
                          scrollbarColor: "red",
                        }}
                      >
                        <Droppable droppableId="technicians-list">
                          {(provided) => (
                            <ul
                              className="no-marker"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {filteredTechnicians !== null &&
                                filteredTechnicians?.map((techies) => {
                                  return (
                                    <li>
                                      <Box
                                        className="flex__"
                                        sx={{
                                          padding: "8px",
                                          alignItems: "center",
                                          gap: "8px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleTechnicianMembers(techies.title)
                                        }
                                      >
                                        {expanded[techies.title] ? (
                                          <DownArrow />
                                        ) : (
                                          <RightArrow />
                                        )}
                                        <Box
                                          className="flex__"
                                          alignItems="center"
                                        >
                                          <MediumTypography
                                            label={techies.title}
                                            fontSize="14px"
                                            textColor="#ffffff"
                                          />
                                          <MediumTypography
                                            label={`(${techies.members.length})`}
                                            fontSize="14px"
                                            className="ml-sm"
                                          />
                                        </Box>
                                      </Box>
                                      {techies.members.map((mem, index) => {
                                        return (
                                          expanded[techies.title] && (
                                            <Draggable
                                              key={mem.id}
                                              draggableId={`technician-${mem.id}`}
                                              index={index}
                                              isDragDisabled={!saveButton}
                                            >
                                              {(provide) => (
                                                <ul
                                                  className="no-marker pt-sm"
                                                  {...provide.draggableProps}
                                                  {...provide.dragHandleProps}
                                                  ref={provide.innerRef}
                                                >
                                                  <TeamMemberWithStatusComponent
                                                    key={mem.id}
                                                    data={mem}
                                                    value={
                                                      mem.id !== undefined &&
                                                      saveButton &&
                                                      checkedMembers[mem.id]
                                                    }
                                                    onClickChange={() => {
                                                      if (
                                                        mem.id !== undefined &&
                                                        saveButton
                                                      ) {
                                                        toggleCheckStatus(
                                                          mem.id.toString()
                                                        );
                                                      }
                                                    }}
                                                    statusNotRequired={false}
                                                  />
                                                </ul>
                                              )}
                                            </Draggable>
                                          )
                                        );
                                      })}
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </Droppable>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  backgroundColor: "#191E22",
                  position: "relative",
                  overflowX: "auto",
                  maxWidth:
                    isPreviousDay || manageShifts.technicians.length === 0
                      ? "1600px"
                      : availableWidth,
                }}
                className="tableStyles"
              >
                <Grid
                  container
                  sx={{
                    minWidth:
                      manageShifts?.shiftTimingsAndMembers.length > 2 &&
                      manageShifts?.remote !== null
                        ? "1600px"
                        : manageShifts?.shiftTimingsAndMembers.length > 2 &&
                          manageShifts?.remote === null
                        ? "1370px"
                        : (manageShifts?.shiftTimingsAndMembers.length < 2 ||
                            manageShifts?.shiftTimingsAndMembers.length ===
                              2) &&
                          manageShifts?.remote === null
                        ? "900px"
                        : "1150px",
                    overflowX: "auto",
                  }}
                >
                  {manageShifts?.shiftTimingsAndMembers.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      sx={{
                        height: "fit-content",
                        backgroundColor: "#161A1D",
                        padding: "16px",
                        borderBottom: "2px solid rgba(166, 197, 226, 0.16)",
                      }}
                      onClick={() => {}}
                    >
                      <Box
                        className="flex__  "
                        sx={{
                          width:
                            manageShifts?.shiftTimingsAndMembers.length > 2
                              ? "194px"
                              : "192px",
                          justifyContent: "space-around",
                        }}
                      >
                        <MediumTypography
                          label={item.shiftName}
                          fontSize="12px"
                          fontWeight={700}
                          className="shift_btn_1"
                          sxProps={{ border: "2px solid #FFFF" }}
                        />
                        <MediumTypography
                          label={getFormattedShiftRange(item.shiftTimeRange)}
                          fontSize="12px"
                          fontWeight={700}
                          textColor="#626F86"
                        />
                      </Box>
                    </Grid>
                  ))}
                  <Grid
                    item
                    sx={{
                      height: "fit-content",
                      backgroundColor: "#161A1D",
                      padding: "16px",
                      borderBottom: "2px solid rgba(166, 197, 226, 0.16)",
                    }}
                    className="ml-sm"
                  >
                    <Box
                      className="flex__container pb-xs "
                      sx={{ width: "188px" }}
                    >
                      <MediumTypography
                        label={
                          manageShifts?.timeOffType.type
                            ? manageShifts?.timeOffType.type
                            : ""
                        }
                        fontSize="12px"
                        fontWeight={700}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      height: "fit-content",
                      backgroundColor: "#161A1D",
                      padding: "16px",
                      borderBottom: "2px solid rgba(166, 197, 226, 0.16)",
                    }}
                    className="ml-sm"
                  >
                    <Box
                      className="flex__container pb-xs "
                      sx={{ width: "188px" }}
                    >
                      <MediumTypography
                        label={
                          manageShifts?.weekOffType.type
                            ? manageShifts?.weekOffType.type
                            : ""
                        }
                        fontSize="12px"
                        fontWeight={700}
                      />
                    </Box>
                  </Grid>
                  {manageShifts?.remote !== null && (
                    <Grid
                      item
                      sx={{
                        height: "fit-content",
                        backgroundColor: "#161A1D",
                        padding: "16px",
                        borderBottom: "2px solid rgba(166, 197, 226, 0.16)",
                      }}
                      className="ml-sm"
                    >
                      <Box
                        className="flex__container pb-xs "
                        sx={{ width: "190px" }}
                      >
                        <MediumTypography
                          label={
                            manageShifts?.remote.type
                              ? manageShifts?.remote.type
                              : ""
                          }
                          fontSize="12px"
                          fontWeight={700}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  sx={{
                    minWidth:
                      manageShifts?.shiftTimingsAndMembers.length > 2 &&
                      manageShifts?.remote !== null
                        ? "1600px"
                        : manageShifts?.shiftTimingsAndMembers.length > 2 &&
                          manageShifts?.remote === null
                        ? "1370px"
                        : (manageShifts?.shiftTimingsAndMembers.length < 2 ||
                            manageShifts?.shiftTimingsAndMembers.length ===
                              2) &&
                          manageShifts?.remote === null
                        ? "900px"
                        : "1150px",
                    overflowX: "auto",
                  }}
                >
                  {manageShifts?.shiftTimingsAndMembers.map((shifts) => {
                    const selectedShiftDate =
                      dayjs(selectedDate).format("YYYY-MM-DD");

                    const pastShift = dayjs().isAfter(
                      dayjs(
                        `${selectedShiftDate}T${
                          shifts.shiftTimeRange.split("-")[0]
                        }`
                      )
                    );
                    return (
                      <Droppable
                        droppableId={`shift/${shifts.shiftStaticName}`}
                      >
                        {(provided) => (
                          <Grid
                            item
                            key={shifts.shiftName}
                            sx={{
                              backgroundColor: "#161A1D",
                              padding: "8px",
                              overflowY: "auto",
                              width: "220px",
                              maxWidth: "250px",
                              maxHeight: availableHeight,
                              height: availableHeight,
                              marginRight: "8px",
                            }}
                            className="tableStyles"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {shifts.shiftMembers.map(
                              (teamMember, itemIndex) => (
                                <Draggable
                                  key={teamMember.id}
                                  draggableId={`assigned-${teamMember.id}`}
                                  index={itemIndex}
                                  isDragDisabled={!saveButton}
                                >
                                  {(provider) => (
                                    <Box
                                      key={teamMember.id}
                                      sx={{ width: "200px", paddingTop: "8px" }}
                                      {...provider.draggableProps}
                                      {...provider.dragHandleProps}
                                      ref={provider.innerRef}
                                    >
                                      <TeamMembersWithCancel
                                        data={teamMember}
                                        statusIndication={true}
                                        buttonStatus={!saveButton || pastShift}
                                        onRemoveClick={() => {
                                          if (teamMember?.id) {
                                            handleRemoveClick(
                                              teamMember?.id,
                                              shifts.shiftName
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    );
                  })}
                  <Droppable
                    droppableId={`shift/${manageShifts?.timeOffType.type}`}
                  >
                    {(provided) => (
                      <Grid
                        item
                        sx={{
                          backgroundColor: "#161A1D",
                          padding: "8px",
                          overflowY: "auto",
                          width: "220px",
                          maxWidth: "250px",
                          maxHeight: availableHeight,
                          height: availableHeight,
                          marginRight: "8px",
                        }}
                        className="tableStyles"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {manageShifts?.timeOffType.members &&
                          manageShifts?.timeOffType.members.map(
                            (teamMember, index) => (
                              <Draggable
                                key={teamMember.id}
                                draggableId={`assigned-${teamMember.id}`}
                                index={index}
                                isDragDisabled={!saveButton}
                              >
                                {(provider) => (
                                  <Box
                                    key={teamMember.id}
                                    sx={{ width: "200px", paddingTop: "8px" }}
                                    {...provider.draggableProps}
                                    {...provider.dragHandleProps}
                                    ref={provider.innerRef}
                                  >
                                    <TeamMembersWithCancel
                                      data={teamMember}
                                      statusIndication={true}
                                      buttonStatus={!saveButton}
                                      onRemoveClick={() => {
                                        if (teamMember?.id) {
                                          handleRemoveTimeOffClick(
                                            teamMember?.id
                                          );
                                        }
                                      }}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            )
                          )}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </Droppable>
                  <Droppable
                    droppableId={`shift/${manageShifts?.weekOffType.type}`}
                  >
                    {(provided) => (
                      <Grid
                        item
                        sx={{
                          backgroundColor: "#161A1D",
                          padding: "8px",
                          overflowY: "auto",
                          width: "220px",
                          maxWidth: "250px",
                          maxHeight: availableHeight,
                          height: availableHeight,
                          marginRight: "8px",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="tableStyles"
                      >
                        {manageShifts?.weekOffType.members &&
                          manageShifts?.weekOffType.members.map(
                            (teamMember, index) => (
                              <Draggable
                                key={teamMember.id}
                                draggableId={`assigned-${teamMember.id}`}
                                index={index}
                                isDragDisabled={!saveButton}
                              >
                                {(provider) => (
                                  <Box
                                    key={teamMember.id}
                                    sx={{ width: "200px", paddingTop: "8px" }}
                                    {...provider.draggableProps}
                                    {...provider.dragHandleProps}
                                    ref={provider.innerRef}
                                  >
                                    <TeamMembersWithCancel
                                      data={teamMember}
                                      statusIndication={true}
                                      buttonStatus={!saveButton}
                                      onRemoveClick={() => {
                                        if (teamMember?.id) {
                                          handleRemoveWeekOffClick(
                                            teamMember?.id
                                          );
                                        }
                                      }}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            )
                          )}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </Droppable>
                  {manageShifts?.remote !== null && (
                    <Grid
                      item
                      sx={{
                        backgroundColor: "#161A1D",
                        padding: "8px",
                        overflowY: "auto",
                        width: "220px",
                        maxWidth: "250px",
                        maxHeight: availableHeight,
                        height: availableHeight,
                      }}
                      className="tableStyles"
                    >
                      {manageShifts?.remote.members &&
                        manageShifts?.remote.members.map((teamMember) => (
                          <Box
                            key={teamMember.id}
                            sx={{ width: "200px", paddingTop: "8px" }}
                          >
                            <TeamMembersWithCancel
                              data={teamMember}
                              statusIndication={true}
                            />
                          </Box>
                        ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </DragDropContext>
  );
};

export default ViewShifts;
