import { CMSetType } from "../../pages/workOrderDetailScreens/correctiveMaintenance/SetAssetsAssignee";
import {
  CreateWOResponse,
  createWorkOrderType,
  PrepopulateCMDetailsResponse,
  shiftDetailsType,
} from "../../pages/workOrderDetailScreens/correctiveMaintenance/types";
import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";

export interface SaveWORequest {
  assetGroupId: number;
  assetCategoryId: number;
  assetId: number[];
  woTypeId: number;
  setAssignees: CMSetType[];
  selectedSkills: number[];
  selectedShiftId: number;
  estimatedEffort: number;
  skillCheckRequired: boolean;
  safetyCheckReqAfterCompletion: boolean;
  selectedPermitIds: number[];
  selectedToolIds: number[];
  approver1: number;
  approver2: number;
  approver3: number;
  observation: string;
}

export const getCreateWorkOrderDetailsApi = async (
  subGroupId: number,
  date: string
): Promise<createWorkOrderType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/cm/fetch/data?subGroupId=${subGroupId}&selectedDate=${date}`
    )
  );
};

export const getSkillSetAssignees = async (
  subGroupId: number,
  date: string,
  skills: number[]
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<number[], shiftDetailsType[]>(
      `workorder/cm/skillSet/users?subGroupId=${subGroupId}&selectedDate=${date}`,
      skills
    )
  );
};

export const saveWorkOrder = async (
  subGroupId: number,
  date: string,
  data: SaveWORequest
): Promise<CreateWOResponse> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `workorder/cm/save/wo?subGroupId=${subGroupId}&selectedDate=${date}`,
      data
    )
  );
};

export const prePouplateCMWorkOrder = async (
  assetGroupId: number,
  assetCategoryId: number
): Promise<PrepopulateCMDetailsResponse> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/cm/fetch/woMaster?assetGroupId=${assetGroupId}&assetCategoryId=${assetCategoryId}`
    )
  );
};
