import { Box, Drawer } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import NotificationCard from "./NotificationCard";
import { ActionRequest, NotificationHistory } from "./notificationTypes";

import useAvailableHeight from "../../utils/useAvailableHeight";
import {
  approveOrRejectRequest,
  getNotifications,
} from "../../api/UserApi/User";
import { handleError } from "../../utils/commonFunctions";
import ErrorModal from "../../components/formlib/modal/ErrorModal";

interface NotificationProps {
  open: boolean;
  handleClose: () => void;
}

const NotificationScreen: FC<NotificationProps> = ({ open, handleClose }) => {
  const [activeItem, setActiveItem] = useState("all");
  const [notificationData, setNotificationData] = useState<
    NotificationHistory[]
  >([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const containerRef = useRef<HTMLDivElement>(null);
  const isFetching = useRef(false);
  const pageRef = useRef(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const availableHeight = useAvailableHeight(110);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const fetchNotifications = useCallback(
    async (pageNumber: number) => {
      try {
        if (isFetching.current) return;
        isFetching.current = true;

        let data: NotificationHistory[] = [];
        if (activeItem === "all") {
          const response = await getNotifications("");
          data = response.notificationHistory;
        } else if (activeItem === "info") {
          const response = await getNotifications("info");
          data = response.notificationHistory;
        } else if (activeItem === "actions") {
          const response = await getNotifications("action");
          data = response.notificationHistory;
        } else {
          isFetching.current = false;
          return;
        }

        const start = pageNumber * itemsPerPage;
        const end = start + itemsPerPage;
        const slicedData = data.slice(start, end);

        if (slicedData && slicedData.length > 0) {
          setNotificationData((prevData) => [...prevData, ...slicedData]);
          setTotalItemCount(data.length);
        }

        isFetching.current = false;
      } catch (err) {
        isFetching.current = false;
      }
    },
    [activeItem, itemsPerPage]
  );

  //
  const approveOrRejectNotification = async (
    data: ActionRequest
  ): Promise<boolean> => {
    const queryParams = new URLSearchParams();
    if (data.requestId !== null && data.requestId !== undefined)
      queryParams.append("requestId", String(data.requestId));
    if (data.requestType !== null && data.requestType !== undefined)
      queryParams.append("type", data.requestType);
    if (data.isApprove !== null && data.isApprove !== undefined)
      queryParams.append("isApprove", String(data.isApprove));
    if (data.isRejected !== null && data.isRejected !== undefined)
      queryParams.append("isRejected", String(data.isRejected));
    if (data.fromUserId !== null && data.fromUserId !== undefined)
      queryParams.append("toId", String(data.fromUserId));
    if (data.attendanceId !== null && data.attendanceId !== undefined)
      queryParams.append("attendanceId", String(data.attendanceId));
    if (data.woDesc !== null && data.woDesc !== undefined)
      queryParams.append("woDesc", data.woDesc);
    if (data.level !== null && data.level !== undefined)
      queryParams.append("level", data.level);
    if (data.woUniqueId !== null && data.woUniqueId !== undefined)
      queryParams.append("woUniqueId", String(data.woUniqueId));

    // if (workOrderId !== null && workOrderId !== undefined)
    //   queryParams.append("id", String(workOrderId));

    try {
      const res = await approveOrRejectRequest(data, queryParams);
      if (res.code === 200) {
        return true;
      } else {
        setOpenErrorModal(true);
        setErrorDesc(res.message);
        return false;
      }
    } catch (err) {
      setOpenErrorModal(true);
      handleError(err, setErrorDesc);
      return false;
    }
  };
  const handleOnPress = async (data: ActionRequest) => {
    // Handle the press event here.
    // You now have access to the onPress data in the parent component.
    const status = await approveOrRejectNotification(data);
    if (status) {
      setPage(0);
      setNotificationData([]); // Reset notifications before fetching updated data
      setTotalItemCount(0);
      pageRef.current = 0;
      isFetching.current = false;
      fetchNotifications(0);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        !isFetching.current &&
        totalItemCount > (pageRef.current + 1) * itemsPerPage
      ) {
        pageRef.current = page + 1;
        fetchNotifications(pageRef.current);
        setPage((prevPage) => {
          const newPage = prevPage + 1;
          pageRef.current = newPage;
          return newPage;
        });
      }
    }
  };

  useEffect(() => {
    // Reset all states when activeItem changes
    setPage(0);
    setNotificationData([]);
    setTotalItemCount(0);
    pageRef.current = 0;
    isFetching.current = false;
    fetchNotifications(0);
  }, [activeItem, fetchNotifications]);

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Drawer
        className="custom-drawer"
        SlideProps={{ direction: "right" }}
        transitionDuration={2000}
        anchor={"right"}
        open={open}
        onClose={handleClose}
      >
        <Box className="pt-lg pl-lg  pr-lg">
          <MediumTypography
            labelId="Notifications.text"
            defaultLabel="Notifications"
            fontSize="20px"
          />

          <Box className="flex__ mt-md">
            <Box className="mr-md">
              <Box
                className={"p-xs cursor__pointer"}
                onClick={() => setActiveItem("all")}
              >
                <MediumTypography
                  labelId="AllNotifications.text"
                  defaultLabel="All"
                  fontWeight={600}
                  textColor={activeItem === "all" ? "#FFFFFF" : "#ABABAB"}
                />
              </Box>
              {activeItem === "all" && <Box className="activeItem" />}
            </Box>
            <Box className="mr-md">
              <Box
                className={"p-xs cursor__pointer"}
                onClick={() => setActiveItem("actions")}
              >
                <MediumTypography
                  labelId="ActionNotifications.text"
                  defaultLabel="Actions"
                  fontWeight={600}
                  textColor={activeItem === "actions" ? "#FFFFFF" : "#ABABAB"}
                />
              </Box>
              {activeItem === "actions" && <Box className="activeItem" />}
            </Box>
            <Box className="mr-md">
              <Box
                className={"p-xs cursor__pointer"}
                onClick={() => setActiveItem("info")}
              >
                <MediumTypography
                  labelId="InfoNotifications.text"
                  defaultLabel="Info"
                  fontWeight={600}
                  textColor={activeItem === "info" ? "#FFFFFF" : "#ABABAB"}
                />
              </Box>
              {activeItem === "info" && <Box className="activeItem" />}
            </Box>
          </Box>

          <Box
            sx={{
              maxHeight: availableHeight,
              overflowY: "auto",
            }}
            ref={containerRef}
            onScroll={handleScroll}
          >
            {notificationData.map((notification, index) => (
              <NotificationCard
                key={index}
                buttonRequired={notification.type.toLowerCase() === "action"}
                positiveLabel="btn.Approve"
                negativeLabel="btn.Reject"
                notificationData={notification}
                handleAction={handleOnPress}
              />
            ))}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default NotificationScreen;
