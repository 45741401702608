import { Box, Button, ButtonGroup, Divider, Grid } from "@mui/material";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import "./css/scheduler.css";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import PeriodButtonComponent from "../../components/formlib/PeriodButtonComponent";
import BorderLine from "../../components/formlib/BorderLine";
import ShiftComponent from "../../components/formlib/ShiftComponent";
import TeamMembersDropDown from "../../components/formlib/TeammembersDropDown";
import WorkOrderCategory from "../../components/formlib/WorkOrderCategory";
import WorkOrderStatus from "../../components/formlib/WorkOrderStatus";
import { ReactComponent as RefreshIcon } from "../../assets/images/refreshIcon.svg";
import { ReactComponent as ChevronCircleIcon } from "../../assets/images/chevronDownCircleIcon.svg";
import {
  AssetGroupsType,
  ShiftDashboardType,
  ShiftType,
  StatusType,
  TeamViewDashboardType,
  TechnicaianTitleType,
  TechnicianType,
  WOCategoryType,
} from "../../utils/type";
import {
  DropdownContext,
  LoaderContext,
  LoaderContextType,
} from "../../layouts/appSidebar";
import AssetTreeDropdown from "../../components/formlib/AssetTreeDropdown";
import {
  getSchedulerDetailsApi,
  getWorkOrderCardDetailsTeamApi,
} from "../../api/workOrderApi/schedulerApi/Scheduler";
import SchedulerCardDashboard from "./SchedulerCardDashboard";
import moment from "moment";
import SchedulerTeamView from "./SchedulerTeamView";
import SchedulerShiftView from "./SchedulerShiftView";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { hasPermission } from "../../utils/checkPermission";
import { useNavigate, useLocation } from "react-router-dom";
import { CREATE_WORK_ORDER } from "../../routes/Routing";
import { handleError } from "../../utils/commonFunctions";
import useAvailableHeight from "../../utils/useAvailableHeight";

export interface DateContextType {
  onDateChange: (newActiveDate: Date) => void;
  onDateRangeChange: (newDateRange: Date[]) => void;
  activeDate: Date;
  activeDateRange: Date[];
}

export const dateContext = createContext<DateContextType>({
  onDateChange: () => {},
  onDateRangeChange: () => {},
  activeDate: new Date(),
  activeDateRange: [new Date(), new Date()],
});

export interface SchedulerViewContextType {
  onSchedulerViewChange: (newView: string) => void;
  schedulerView: string;
}

export const SchedulerViewContext = createContext<SchedulerViewContextType>({
  onSchedulerViewChange: () => {},
  schedulerView: "",
});

const InitialDashboardData: ShiftDashboardType = {
  totalUserCount: 0,
  presentUserCount: 0,
  period: [],
  shiftTypes: [],
  totalWOCount: 0,
  totalWOStatusCount: 0,
  status: [],
  woCategory: [],
  shifts: [],
  backlogs: [],
  sites: [],
  assetGroups: [],
  technicians: [],
  selectedSiteName: "",
  selectedSiteId: 0,
  selectedSubgroupId: 0,
  backLogCardCount: 0,
  selectedDate: "",
  currentWoCount: 0,
  selectedAssetIds: [],
  selectedAssetCategoryIds: [],
  selectedMemberIds: [],
  selectedShiftIds: [],
  selectedAssetGroupIds: [],
  selectedWoCategoryIds: [],
  selectedStatusIds: [],
};

const InitialMemberData: TechnicianType = {
  id: 0,
  image: "",
  name: "",
  title: "",
  availability: "",
};

const InitialTeamViewData: TeamViewDashboardType = {
  totalUserCount: 0,
  presentUserCount: 0,
  period: [],
  shiftTypes: [],
  totalWOCount: 0,
  totalWOStatusCount: 0,
  status: [],
  woCategory: [],
  shifts: [],
  sites: [],
  assetGroups: [],
  technicians: [],
  selectedSiteName: "",
  selectedSiteId: 0,
  selectedSubgroupId: 0,
  member: InitialMemberData,
  teamView: [],
  selectedShiftIds: [],
  currentWoCount: 0,
  selectedAssetIds: [],
  selectedAssetCategoryIds: [],
  selectedMemberIds: [],
  selectedWoCategoryIds: [],
  selectedStatusIds: [],
};

const SchedulerDashboard = () => {
  //setting state for scheduler view, team view and shift view
  const [mockData, setMockData] =
    useState<ShiftDashboardType>(InitialDashboardData);
  const [shiftViewData, setShiftViewData] =
    useState<ShiftDashboardType>(InitialDashboardData);
  const [teamViewData, setTeamViewData] =
    useState<TeamViewDashboardType>(InitialTeamViewData);
  const location = useLocation();

  //date related states
  // Get today's date
  let firstDate = new Date();
  let lastDate = new Date(location.state ? location.state.date : firstDate);
  lastDate.setDate(lastDate.getDate() + 6);

  // Check if the provided date is the last day of the range
  if (
    lastDate.getDate() ===
    new Date(location.state ? location.state.date : firstDate).getDate()
  ) {
    lastDate.setDate(lastDate.getDate() + 1);
  }

  const [activeDate, setActiveDate] = useState<Date>(
    location.state ? location.state.date : firstDate
  );
  const [activeDateRange, setActiveDateRange] = useState<Date[]>(
    location.state ? location.state.dateRange : [firstDate, lastDate]
  );

  //state fot status and status count
  const [allWorkOrderType, setAllWorkOrderType] = useState<StatusType[]>([]);
  const [totalWorkOrderStatusCount, setTotalWorkOrderStatusCount] =
    useState<number>(0);

  //setting state for view like scheduler view, team view and shift view
  const [schedulerView, setSchedulerView] = useState<string>(
    location.state ? location?.state?.schedulerViewChange : ""
  );

  const [selectedShifts, setSelectedShifts] = useState<number[]>([]); //setting state for selected shifts

  const [allSelectedAssets, setAllSelectedAssets] = useState<number[]>([]); //setting state for selected assets
  const [selectedAssetGroups, setSelectedAssetGroup] = useState<number[]>([]); // setting state for selected asset groups
  const [allSelectedAssetsParent, setAllSelectedAssetsParent] = useState<
    number[]
  >([]); // setting state for assets when coming from child
  const [selectedAssetGroupsParent, setSelectedAssetGroupParent] = useState<
    number[]
  >([]); // setting asset group which is coming from child

  const [selectedAssetCategoryParent, setSelectedAssetCategoryParent] =
    useState<number[]>([]); // setting state for asset category which is coming from child

  const [selectedTechniciansId, setSelectedTechniciansId] = useState<number[]>(
    []
  ); //setting technicians id in callback func from child

  const [selectedWorkOrderIds, setSelectedWorkOrderIds] = useState<number[]>(
    []
  ); // setting selected work order ids in call back func from child

  const [selectedWorkOrderStatusIds, setSelectedWorkOrderStatusIds] = useState<
    number[]
  >([]); //setting selected status ids in call back func from child

  const [selectedMembersIds, setSelectedMembersIds] = useState<number[]>([]); //sending back members selected to child

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType; //loader context

  const [assetData, setAssetData] = useState<AssetGroupsType[]>([]); // getting asset data from api and setting and sending to child
  const [teamMembersData, setTeamMembersData] = useState<
    TechnicaianTitleType[]
  >([]); // getting team members or technician data from api and setting and sending to child

  const [isVisible, setIsVisible] = useState(true); // expand or collapse filters state
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false); //error modal state
  const [errorDesc, setErrorDesc] = useState<string>(""); //error desc state

  const [selectedSiteId, setSelectedSiteId] = useState<number>(); //selected siteid from context
  const [selectedSubgroupId, setSelectedSubgroupId] = useState<number>(); //selected subgroup id from context
  const [selectedShiftsFromParent, setSelectedShiftsFromParent] = useState<
    number[]
  >([]); //selected shifts from parent and send to child state
  const [selectedWOCategoryFromParent, setSelectedWOCategoryFromParent] =
    useState<number[]>([]); //selected WO Category from parent and send to child state

  const [selectedWOStatusFromParent, setSelectedWOStatusFromParent] = useState<
    number[]
  >([]); //selected WO Status from parent and send to child state

  const [filteredShiftTypes, setFilteredShiftTypes] = useState<ShiftType[]>([]); //getting only shift details from api and send to child state
  const [filteredWorkOrderCategory, setFilteredWorkOrderCategory] = useState<
    WOCategoryType[]
  >([]); //getting work order category details from api and sendto child state

  const [filteredPeriodDays, setFilteredPeriodDays] = useState<string[]>([]); //period days state
  const [filteredTotalWOCount, setFilteredTotalWOCount] = useState<number>(0); //total work order count state
  const isActiveDateTodayOrFuture = moment(activeDate).isSameOrAfter(
    moment(),
    "day"
  );
  const isActiveDateTodayOrPast = moment(activeDate).isSameOrBefore(
    moment(),
    "day"
  );

  const context = useContext(DropdownContext); //context for setting site related details
  const navigate = useNavigate();
  const availableHeight = useAvailableHeight(240);
  const availableHeight1 = useAvailableHeight(800);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    getSchedulerDetails();
  }, [
    selectedWorkOrderStatusIds,
    activeDate,
    allSelectedAssets,
    selectedAssetGroups,
    selectedWorkOrderIds,
    selectedShifts,
    schedulerView,
    selectedTechniciansId,
  ]);

  //api for getting scheduler, team and shift view details
  const getSchedulerDetails = useCallback(() => {
    let storedAssetGroupIds = localStorage.getItem("selectedAssetGroupsIds");

    let obj = {
      selectedDate: moment(activeDate).format("YYYY-MM-DD"),
      selectedMemberIds:
        selectedTechniciansId.length > 0 ? selectedTechniciansId : null,
      selectedWoCategoryIds:
        selectedWorkOrderIds?.length > 0 ? selectedWorkOrderIds : null,
      selectedStatusIds:
        selectedWorkOrderStatusIds.length > 0
          ? selectedWorkOrderStatusIds
          : null,
      selectedAssetIds: allSelectedAssets.length > 0 ? allSelectedAssets : null,
      selectedAssetGroupIds: storedAssetGroupIds
        ? context.selectedAssetGroupsIds
        : selectedAssetGroups,
      selectedAssetCategoryIds:
        selectedAssetCategoryParent.length > 0
          ? selectedAssetCategoryParent
          : null,
      selectedSiteId: localStorage.getItem("selectedSiteId")
        ? Number(localStorage.getItem("selectedSiteId"))
        : selectedSiteId,
      selectedSubgroupId: localStorage.getItem("selectedSubgroupId")
        ? Number(localStorage.getItem("selectedSubgroupId"))
        : selectedSubgroupId,
      selectedShiftIds: selectedShifts.length > 0 ? selectedShifts : null,
    };
    if (activeDate) {
      if (schedulerView !== "team" && schedulerView !== "shift") {
        toggleLoader(true);
        getSchedulerDetailsApi("SCHEDULE", obj)
          .then((response) => {
            setAssetData(response.assetGroups);
            setFilteredPeriodDays(response.period);
            setAllWorkOrderType(response.status);
            setTotalWorkOrderStatusCount(response.totalWOStatusCount);
            setFilteredShiftTypes(response.shiftTypes);
            setFilteredTotalWOCount(response.totalWOCount);
            setFilteredWorkOrderCategory(response.woCategory);
            if (response.selectedAssetCategoryIds) {
              setSelectedAssetCategoryParent(response.selectedAssetCategoryIds);
            }
            if (response.selectedAssetGroupIds) {
              setSelectedAssetGroupParent(response.selectedAssetGroupIds);
            }
            if (response.selectedAssetIds) {
              setAllSelectedAssetsParent(response.selectedAssetIds);
            }
            if (response.selectedMemberIds !== null) {
              setSelectedMembersIds(response.selectedMemberIds);
            }
            if (response.technicians !== null) {
              setTeamMembersData(response.technicians);
            }
            setSelectedWOCategoryFromParent(response.selectedWoCategoryIds);
            setSelectedWOStatusFromParent(response.selectedStatusIds);
            setSelectedShiftsFromParent(response.selectedShiftIds);
            context.dropdownValue(
              response.sites,
              response.selectedSiteName,
              response.selectedSiteId,
              response.selectedSubgroupId
            );
            context.selectedSiteId = response.selectedSiteId;
            context.selectedSubgroupId = response.selectedSubgroupId;
            context.selectedAssetGroupsIds = response.selectedAssetGroupIds;

            setMockData(response);
            toggleLoader(false);
          })
          .catch((error) => {
            setOpenErrorModal(true);
            handleError(error, setErrorDesc);

            toggleLoader(false);
          });
      } else {
        toggleLoader(true);
        if (schedulerView === "team") {
          getWorkOrderCardDetailsTeamApi(obj)
            .then((response) => {
              setFilteredPeriodDays(response.period);
              setAssetData(response.assetGroups);
              setAllWorkOrderType(response.status);
              setFilteredShiftTypes(response.shiftTypes);
              setFilteredTotalWOCount(response.totalWOCount);
              setFilteredWorkOrderCategory(response.woCategory);
              setTotalWorkOrderStatusCount(response.totalWOStatusCount);
              if (response.selectedAssetCategoryIds) {
                setSelectedAssetGroupParent(response.selectedAssetCategoryIds);
              }
              if (response.selectedAssetIds) {
                setAllSelectedAssetsParent(response.selectedAssetIds);
              }
              if (response.selectedMemberIds !== null) {
                setSelectedMembersIds(response.selectedMemberIds);
              }
              if (response.technicians !== null) {
                setTeamMembersData(response.technicians);
              }
              setSelectedShiftsFromParent(response.selectedShiftIds);
              setSelectedWOCategoryFromParent(response.selectedWoCategoryIds);
              setSelectedWOStatusFromParent(response.selectedStatusIds);

              context.dropdownValue(
                response.sites,
                response.selectedSiteName,
                response.selectedSiteId,
                response.selectedSubgroupId
              );
              context.selectedSiteId = response.selectedSiteId;
              context.selectedSubgroupId = response.selectedSubgroupId;
              setTeamViewData(response);
              toggleLoader(false);
            })
            .catch((error) => {
              setOpenErrorModal(true);
              handleError(error, setErrorDesc);
              toggleLoader(false);
            });
        } else if (schedulerView === "shift") {
          getSchedulerDetailsApi("WORKORDER", obj)
            .then((response) => {
              setAssetData(response.assetGroups);
              setFilteredPeriodDays(response.period);
              setAllWorkOrderType(response.status);
              setTotalWorkOrderStatusCount(response.totalWOStatusCount);
              setFilteredShiftTypes(response.shiftTypes);
              setFilteredTotalWOCount(response.totalWOCount);
              setFilteredWorkOrderCategory(response.woCategory);

              if (response.selectedAssetCategoryIds) {
                setSelectedAssetGroupParent(response.selectedAssetCategoryIds);
              }
              if (response.selectedAssetIds) {
                setAllSelectedAssetsParent(response.selectedAssetIds);
              }
              if (response.selectedMemberIds !== null) {
                setSelectedMembersIds(response.selectedMemberIds);
              }
              if (response.technicians !== null) {
                setTeamMembersData(response.technicians);
              }
              setSelectedShiftsFromParent(response.selectedShiftIds);
              setSelectedWOCategoryFromParent(response.selectedWoCategoryIds);
              setSelectedWOStatusFromParent(response.selectedStatusIds);

              context.dropdownValue(
                response.sites,
                response.selectedSiteName,
                response.selectedSiteId,
                response.selectedSubgroupId
              );
              context.selectedSiteId = response.selectedSiteId;
              context.selectedSubgroupId = response.selectedSubgroupId;
              setShiftViewData(response);
              toggleLoader(false);
            })
            .catch((error) => {
              setOpenErrorModal(true);
              handleError(error, setErrorDesc);

              toggleLoader(false);
            });
        }
      }
    }
  }, [
    activeDate,
    selectedWorkOrderStatusIds,
    allSelectedAssets,
    selectedAssetGroups,
    selectedTechniciansId,
    selectedShifts,
    schedulerView,
    selectedWorkOrderIds,
  ]);

  //reset function call
  const refreshPage = (ref: boolean) => {
    setMockData(InitialDashboardData);
    setSelectedWorkOrderIds([]);
    setSelectedWorkOrderStatusIds([]);
    setSelectedAssetGroup([]);
    setSelectedShifts([]);
    setSelectedShiftsFromParent([]);
    setSelectedWOCategoryFromParent([]);
    setSelectedWOStatusFromParent([]);
    setSelectedMembersIds([]);
    setSelectedTechniciansId([]);
    setSelectedAssetGroup([]);
    setSelectedAssetGroupParent([]);
    setAllSelectedAssetsParent([]);
    setAllSelectedAssets([]);
    if (ref) {
      setSelectedSiteId(0);
      setSelectedSubgroupId(0);
      context.dropdownValue([], "", 0, 0, []);
    }
    setSelectedAssetCategoryParent([]);
  };

  //callback func for work oder category tabs
  const handleWOtabs = useCallback(
    (selectedTabs: number[]) => {
      const filteredTabs = selectedTabs.filter((tab) => tab !== 0);
      setSelectedWorkOrderIds(filteredTabs);
    },
    [selectedWorkOrderIds]
  );

  //callback func for work order status tabs
  const handleWOStatusTabs = useCallback((selectedStatusTabs: number[]) => {
    const filteredStatusTabs = selectedStatusTabs.filter((tab) => tab !== 0);
    setSelectedWorkOrderStatusIds(filteredStatusTabs);
  }, []);

  //setting site details based on context
  useEffect(() => {
    const storedSiteId = localStorage.getItem("selectedSiteId");
    const storedSubgroupId = localStorage.getItem("selectedSubgroupId");
    const storedAssetGroupId = localStorage.getItem("selectedAssetGroupsIds");

    if (storedSiteId) {
      setSelectedSiteId(Number(storedSiteId));
    }

    if (storedSubgroupId) {
      setSelectedSubgroupId(Number(storedSubgroupId));
    }
    if (storedAssetGroupId) {
      setSelectedAssetGroup(JSON.parse(storedAssetGroupId));
    }
  }, [
    context.selectedSiteId,
    context.selectedSubgroupId,
    context.selectedAssetGroupsIds,
  ]);

  //team member call back
  const teamMemberSelectedCallBack = useCallback((value: number[]) => {
    setSelectedTechniciansId(value);
  }, []);

  //asset call back for assets dropdown
  const assetCallBack = useCallback(
    (
      value: number[],
      assetGroups: number[],
      allSelectedAssetCategories: number[],
      filteredAssetData: AssetGroupsType[]
    ) => {
      setAllSelectedAssets(value);
      setAssetData(filteredAssetData);
      setSelectedAssetCategoryParent(allSelectedAssetCategories);
    },
    []
  );

  const dataLoadCallBack = useCallback(() => {
    toggleLoader(false);
  }, []);

  //date change func
  const onDateChange = useCallback(
    (newActiveDate: Date) => {
      let newSchedulerView = schedulerView;
      if (moment(newActiveDate).isBefore(moment(), "day")) {
        if (schedulerView !== "team" && schedulerView !== "shift") {
          newSchedulerView = "shift";
        }
      } else if (moment(newActiveDate).isAfter(moment(), "day")) {
        newSchedulerView = "";
      }
      setActiveDate(newActiveDate);
      setSchedulerView(newSchedulerView);
    },
    [schedulerView]
  );

  //shift change call back
  const handleShiftChange = useCallback((shiftValue: number[]) => {
    setSelectedShifts(shiftValue);
  }, []);

  //date range change call back
  const onDateRangeChange = ([newStartDate, newEndDate]: Date[]) => {
    setActiveDateRange([newStartDate, newEndDate]);
  };

  //scheduler view change func
  const onSchedulerViewChange = (newView: string) => {
    setSchedulerView(newView);
  };

  return (
    <dateContext.Provider
      value={{ onDateChange, onDateRangeChange, activeDate, activeDateRange }}
    >
      <Box
        sx={{
          // overflow: "hidden",
          // minHeight: "200px",
          backgroundColor: "#1D2125",
          height: isVisible ? availableHeight : availableHeight1,
          // overflow: "hidden",
        }}
      >
        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
        <div>
          {isVisible && (
            <Box component="main">
              <Grid
                container
                className="mt-sm"
                spacing={2}
                sx={{ height: "48px !important" }}
              >
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3.5}
                        className="flex__"
                      >
                        <Box>
                          <MediumTypography
                            labelId="PeriodLabel"
                            defaultLabel="PERIOD"
                            textColor="#FFFFFF"
                            fontSize="12px"
                            sxProps={{ paddingBottom: "10px" }}
                          />
                          {filteredPeriodDays && (
                            <PeriodButtonComponent
                              period={filteredPeriodDays}
                              handleSelectedPeriod={(value) => {}}
                            />
                          )}
                        </Box>
                      </Grid>
                      <BorderLine />

                      <Grid
                        item
                        xs={12}
                        sm={2}
                        md={2}
                        lg={2}
                        className="flex__ "
                      >
                        <Box>
                          <MediumTypography
                            labelId="ShiftsLabel"
                            defaultLabel="SHIFTS"
                            textColor="#FFFFFF"
                            fontSize="12px"
                            sxProps={{ paddingBottom: "8px" }}
                          />
                          {filteredShiftTypes !== null &&
                            filteredShiftTypes !== undefined &&
                            filteredShiftTypes.length > 0 && (
                              <ShiftComponent
                                handleShiftSelected={(value) =>
                                  handleShiftChange(value)
                                }
                                selectedShifts={selectedShiftsFromParent}
                                shifts={filteredShiftTypes}
                              />
                            )}
                        </Box>
                      </Grid>
                      <BorderLine />

                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={3}
                        lg={2.5}
                        className="flex__ ml-md"
                      >
                        <Box>
                          <MediumTypography
                            labelId="AssetsLabel"
                            defaultLabel="ASSETS"
                            textColor="#FFFFFF"
                            fontSize="12px"
                            sxProps={{ paddingBottom: "10px" }}
                          />
                          {assetData.length > 0 && (
                            <AssetTreeDropdown
                              AssetData={assetData}
                              selectedAssetGroupsParent={
                                selectedAssetGroupsParent
                              }
                              selectedAssetCategoryParent={
                                selectedAssetCategoryParent
                              }
                              selectedAssetsParent={allSelectedAssetsParent}
                              assetCallBack={assetCallBack}
                            />
                          )}
                        </Box>
                      </Grid>
                      <BorderLine />

                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={3}
                        lg={2.5}
                        className="flex__ ml-lg"
                      >
                        <Box>
                          <MediumTypography
                            labelId="TeamMembersLabel"
                            defaultLabel="TEAM MEMBERS"
                            textColor="#FFFFFF"
                            fontSize="12px"
                            sxProps={{ paddingBottom: "10px" }}
                          />
                          <TeamMembersDropDown
                            newItems={teamMembersData}
                            selectedMembersIds={selectedMembersIds}
                            teamMemberSelectedCallBack={
                              teamMemberSelectedCallBack
                            }
                          />
                        </Box>
                      </Grid>
                      <BorderLine />
                      <Grid item xs={12} sm={6} md={3} lg={1}>
                        <Box
                          sx={{
                            height: "72px",
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                            paddingTop: "10px",
                          }}
                        >
                          <Button className="button-ui button-ui-small">
                            <RefreshIcon onClick={() => refreshPage(true)} />
                          </Button>
                          <Button
                            className="button-ui button-ui-small"
                            onClick={toggleVisibility}
                          >
                            {isVisible && <ChevronCircleIcon />}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container className="mt-xlg">
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{ borderTop: "1px solid #333b43" }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} lg={12} md={12} xl={12} className="mt-sm">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ height: "48px !important" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={4}
                        lg={4}
                        className="flex__"
                      >
                        <Box>
                          <MediumTypography
                            labelId="WorkCategoryLabel"
                            defaultLabel="WORK ORDERS CATEGORY"
                            textColor="#FFFFFF"
                            fontSize="12px"
                            sxProps={{ paddingBottom: "10px" }}
                          />

                          {filteredWorkOrderCategory && (
                            <WorkOrderCategory
                              onSelectTabs={handleWOtabs}
                              totalWOCount={filteredTotalWOCount}
                              selectedWOCategoryFromParent={
                                selectedWOCategoryFromParent
                              }
                              workOrderType={filteredWorkOrderCategory}
                            />
                          )}
                        </Box>
                      </Grid>
                      <BorderLine />

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={3.5}
                        className="flex__ "
                      >
                        <Box>
                          <MediumTypography
                            labelId="StatusLabel"
                            defaultLabel="STATUS"
                            textColor="#FFFFFF"
                            fontSize="12px"
                            sxProps={{ paddingBottom: "10px" }}
                          />
                          {allWorkOrderType && (
                            <WorkOrderStatus
                              onSelectTabs={handleWOStatusTabs}
                              status={allWorkOrderType}
                              selectedWOStatusFromParent={
                                selectedWOStatusFromParent
                              }
                              totalWOStatusCount={totalWorkOrderStatusCount}
                            />
                          )}
                        </Box>
                      </Grid>
                      <BorderLine />

                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={2.2}
                        className="flex__ "
                      >
                        <Box
                          sx={{
                            height: "72px",
                            position: "relative",
                            paddingTop: "26px",
                          }}
                        >
                          <ButtonGroup
                            variant="contained"
                            aria-label="Basic button group"
                            sx={{ textTransform: "none" }}
                          >
                            <ButtonGroup
                              variant="contained"
                              aria-label="Basic button group"
                              sx={{ textTransform: "none" }}
                            >
                              <ButtonComponent
                                className={`button-ui yearMonthDay_btn ${
                                  schedulerView === ""
                                    ? "workOrderCategory_Toggle_btn_active"
                                    : ""
                                }`}
                                labelId="Schedule"
                                defaultLabelId="Schedule"
                                sxProps={{
                                  textTransform: "none",
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                                onClick={() => {
                                  onSchedulerViewChange("");
                                  setActiveDate(moment().toDate());

                                  const currentDate = new Date();
                                  let nextSixDaysDate = new Date();
                                  nextSixDaysDate.setDate(
                                    currentDate.getDate() + 6
                                  );
                                  setActiveDateRange([
                                    currentDate,
                                    nextSixDaysDate,
                                  ]);

                                  refreshPage(false);
                                }}
                              />
                              <ButtonComponent
                                className={`button-ui yearMonthDay_btn ${
                                  schedulerView !== ""
                                    ? "workOrderCategory_Toggle_btn_active"
                                    : ""
                                }`}
                                labelId="WorkOrders"
                                defaultLabelId="Work Orders"
                                sxProps={{
                                  textTransform: "none",
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                                onClick={() => {
                                  onSchedulerViewChange("shift");
                                  setActiveDate(moment().toDate());
                                  refreshPage(false);
                                }}
                                disabled={!hasPermission("Manage_WO")}
                              />
                            </ButtonGroup>
                          </ButtonGroup>
                        </Box>
                      </Grid>
                      <BorderLine />

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={6}
                        lg={1.7}
                        className="flex__ "
                      >
                        <Box
                          sx={{
                            height: "72px",
                            position: "relative",
                            paddingTop: "26px",
                          }}
                        >
                          <ButtonComponent
                            className="button-ui submit_btn_primary"
                            labelId="CreateWorkOrder"
                            defaultLabelId="Create Work Order"
                            onClick={() => {
                              navigate(CREATE_WORK_ORDER, {
                                state: {
                                  date: activeDate,
                                  dateRange: activeDateRange,
                                  selectedSubgroupId:
                                    context.selectedSubgroupId,
                                },
                              });
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container className="mt-xlg">
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{ borderTop: "1px solid rgba(166, 197, 226, 0.16)" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>

        <Box
          component="main"
          // sx={{ height: isVisible ? availableHeight : availableHeight - 40 }}
        >
          {!isVisible && (
            <Grid container sx={{ color: "#FFFFFF", fontSize: "12px" }}>
              <Grid
                item
                xs={12}
                className="display-inline-flex gridWithLines"
                justifyContent="center"
              >
                <Box sx={{ position: "relative", textAlign: "center" }}>
                  <Button
                    onClick={toggleVisibility}
                    className={`button-ui  mt-lg mb-lg ${
                      isVisible ? "hide" : "show"
                    }`}
                    sx={{ border: "none", background: "transparent" }}
                  >
                    <MediumTypography
                      labelId="ClicktoViewFiltersText"
                      defaultLabel="Click here to Expand Filters"
                      textColor=" #6A6F75"
                      fontSize="14px"
                      fontWeight={400}
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
          {schedulerView === "" && mockData && (
            <SchedulerCardDashboard
              cardDashboardDetails={mockData}
              selectedAssetGroupIds={selectedAssetGroupsParent}
              dataLoadCallBack={dataLoadCallBack}
              callApiCallBack={() => {
                getSchedulerDetails();
              }}
              isVisible={isVisible}
            />
          )}

          {/* {activeDate.getDate() <= new Date().getDate() && ( */}
          <SchedulerViewContext.Provider
            value={{
              onSchedulerViewChange,
              schedulerView,
            }}
          >
            {schedulerView === "team" && teamViewData && (
              <SchedulerTeamView
                teamViewDetails={teamViewData}
                callApiCallBack={() => {
                  getSchedulerDetails();
                }}
                isVisible={isVisible}
              />
            )}
            {schedulerView === "shift" && shiftViewData && (
              <SchedulerShiftView
                cardDashboardDetails={shiftViewData}
                selectedDate={activeDate}
                callApiCallBack={() => {
                  getSchedulerDetails();
                }}
                isVisible={isVisible}
              />
            )}
          </SchedulerViewContext.Provider>
          {/* )} */}
        </Box>
      </Box>
    </dateContext.Provider>
  );
};

export default SchedulerDashboard;
