import TicketApiClient from "../TicketApi";
import { ApiResponseHandler } from "../ApiResponseHandler";
export const fetchTicketDetailsById = async <T>(id: number): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().get(`/ticket/${id}/fetch`)
  );
};
export const saveConversation = async <T>(
  url: string,
  payload: any
): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post(`/${url}`, payload)
  );
};

export const deleteAttachments = async <T>(payload: number[]): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().delete(
      `/ticket/attachments?ids=${payload}`
    )
  );
};

export const saveWatch = async <T>(url: string): Promise<T> => {
  return ApiResponseHandler(
    await TicketApiClient.getInstance().post(`/${url}`, {})
  );
};

export const fetchTicketActivity = async <T>(url: string): Promise<T> => {
  return ApiResponseHandler(await TicketApiClient.getInstance().get(`/${url}`));
};
