import {
  Box,
  Grid,
  Menu,
  MenuProps,
  styled,
  Theme,
  Tooltip,
} from "@mui/material";
import MediumTypography from "../../components/formlib/MediumTypography";
import { FC, useContext, useMemo, useState } from "react";
import { ReactComponent as Available } from "../../assets/images/Available.svg";
import { ReactComponent as Noshow } from "../../assets/images/Noshow.svg";
import { ReactComponent as Weekoff } from "../../assets/images/Weekoff.svg";
import { ReactComponent as Timeoff } from "../../assets/images/TimeOffIcon.svg";
import { ReactComponent as Note } from "../../assets/images/note.svg";
import dayjs, { Dayjs } from "dayjs";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ContentCopy } from "@mui/icons-material";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import { cloneShift } from "../../api/ShiftPlanning/ShiftPlanning";
import { handleError } from "../../utils/commonFunctions";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import "./ShiftPlanner.css";

export interface shiftDetails {
  shiftName: string;
  shiftStaticName: string;
  shiftUserCount: number;
}
export interface ShiftMonthlyDetails {
  date: string;
  shiftDetails: shiftDetails[];
  availableMemberCount: number;
  noShowMemberCount: number;
  weekOffMemberCount: number;
  timeOffMemberCount: number;
  holidayName: string;
  shiftPlanStatus: string;
}

interface ShiftDayData {
  data: ShiftMonthlyDetails;
  subGroupId: number;
  getCallBack: () => void;
}
export const CustomMenu = styled(
  ({
    padding = "0px 0px",
    maxWidth = 500, // default maxWidth
    left = 0,
    width = "0",
    minWidth = 500, // default maxWidth
    ...props
  }: MenuProps & {
    padding?: string;
    maxWidth?: number;
    left?: number;
    width?: string;
    minWidth?: number;
  }) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{ left: left }}
      {...props}
    />
  )
)(
  ({
    theme,
    padding = "0px 0px",
    maxWidth = 500, // fallback for maxWidth
    width = "0",
    minWidth = 0, // fallback for maxWidth
  }: {
    theme: Theme;
    width?: string;
    padding?: string;
    maxWidth?: number;
    minWidth?: number;
  }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth, // dynamically set maxWidth
      maxHeight: 550,
      padding: padding,
      borderWidth: "0 10px 10px 10px",
      boxShadow: "none",
      backgroundColor: "#1D2125",
      border: "1px solid rgba(166, 197, 226, 0.16)",
    },
    "& .MuiPaper-root.MuiPaper-elevation > ul": {
      paddingBottom: "0px",
    },
    "& .MuiPaper-root::-webkit-scrollbar": {
      height: "5px",
      width: "4px",
      marginLeft: "10px",
    },
    "& .MuiPaper-root::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(12, 102, 228, 0.6)",
      borderRadius: "10px",
      border: "1px solid rgba(12, 102, 228, 0.6)",
      backgroundClip: "content-box",
      marginLeft: "10px",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a8bbbf",
    },
  })
);
const CustomDateCalendar = styled(DateCalendar)(({ theme }) => ({
  "& .MuiPickersDay-root": {
    color: "rgba(159, 173, 188, 1)", // Default color of dates
  },
  "& .MuiPickersCalendarHeader-dayLabel": {
    color: "rgba(159, 173, 188, 1)", // Color for weekday labels
  },
  "& .MuiPickersArrowSwitcher-button": {
    color: "rgba(159, 173, 188, 1)", // Color for navigation arrows
  },
  "& .MuiPickersCalendarHeader-switchViewButton": {
    color: "rgba(159, 173, 188, 1)",
  },
  "& .MuiDayCalendar-weekDayLabel": {
    color: "rgba(159, 173, 188, 1)",
  },
  "& .MuiPickersCalendarHeader-switchHeader button": {
    color: "rgba(159, 173, 188, 1)", // Color for navigation arrows
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#1976d2", // Background color for selected dates
    color: "rgba(159, 173, 188, 1)", // Color for selected dates
  },
  "& .MuiPickersDay-root:hover": {
    backgroundColor: "#1976d2", // Background color when hovering over dates
    color: "rgba(159, 173, 188, 1)", // Color when hovering over dates
  },
  "& .MuiPickersDay-root:disabled": {
    color: "rgba(191, 219, 248, 0.28)",
    opacity: "1 ",
    "-webkit-text-fill-color": "rgba(191, 219, 248, 0.28) ",
  },
}));

const DayShiftDetails: FC<ShiftDayData> = ({
  data,
  subGroupId,
  getCallBack,
}) => {
  const isPastDate = dayjs(data.date).isBefore(dayjs(), "day");
  const shiftDataExist = data.shiftDetails.some(
    (item: shiftDetails) => item.shiftUserCount > 0
  );
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedDate(null);
  };
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate !== null) {
      setSelectedDate(newDate);
    }
  };

  const cloneShiftPlan = () => {
    const fromDate = dayjs(data.date).format("YYYY-MM-DD");
    const dateValue = dayjs(selectedDate).format("YYYY-MM-DD");
    const cloneDates: string[] = [dateValue];
    toggleLoader(true);
    cloneShift(subGroupId, fromDate, cloneDates)
      .then(() => {
        toggleLoader(false);
        setSelectedDate(null);
        handleClose();
        getCallBack();
      })
      .catch((error) => {
        toggleLoader(false);
        setSelectedDate(null);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  return (
    <Box className="width__100">
      {openErrorModal && (
        <Box
          onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        </Box>
      )}
      <Box
        className="flex__ mr-md width__100"
        sx={{ justifyContent: "space-between" }}
      >
        <Box className="flex__">
          <Box className="mr-lg">
            {data.shiftDetails.map((item: shiftDetails) => (
              <Box key={item.shiftStaticName} className="flex__ mb-sm">
                <Tooltip title={item.shiftName}>
                  <Box>
                    <MediumTypography
                      label={item.shiftStaticName}
                      className="dayview_shift_btn_1 mr-sm"
                      textColor="#626F86"
                      sxProps={{
                        height: "fit-content",
                        border: "2px solid #626F86",
                        lineHeight: "normal",
                      }}
                    />
                  </Box>
                </Tooltip>
                <MediumTypography
                  label={item.shiftUserCount.toString()}
                  textColor="#626F86"
                />
              </Box>
            ))}
          </Box>
          <Box>
            {data.availableMemberCount > 0 && (
              <Box className="flex__ mb-sm align__items__center">
                <Tooltip title={"Available"}>
                  <Available style={{ width: "18px", height: "18px" }} />
                </Tooltip>
                <MediumTypography
                  label={data.availableMemberCount?.toString() ?? "0"}
                  className="ml-sm"
                  textColor="#626F86"
                />
              </Box>
            )}
            {data.noShowMemberCount > 0 && (
              <Box className="flex__ mb-sm align__items__center">
                <Tooltip title={"No Show"}>
                  <Noshow style={{ width: "18px", height: "18px" }} />
                </Tooltip>
                <MediumTypography
                  label={data.noShowMemberCount?.toString() ?? "0"}
                  className="ml-sm"
                  textColor="#626F86"
                />
              </Box>
            )}
            {data.weekOffMemberCount > 0 && (
              <Box className="flex__ mb-sm align__items__center">
                <Tooltip title={"Week Off"}>
                  <Weekoff style={{ width: "18px", height: "18px" }} />
                </Tooltip>
                <MediumTypography
                  label={data.weekOffMemberCount?.toString() ?? "0"}
                  className="ml-sm"
                  textColor="#626F86"
                />
              </Box>
            )}
            {data.timeOffMemberCount > 0 && (
              <Box className="flex__ mb-sm align__items__center">
                <Tooltip title={"Time Off"}>
                  <Timeoff style={{ width: "18px", height: "18px" }} />
                </Tooltip>
                <MediumTypography
                  label={data.timeOffMemberCount?.toString() ?? "0"}
                  className="ml-sm"
                  textColor="#626F86"
                />
              </Box>
            )}
          </Box>
        </Box>

        {isPastDate && shiftDataExist && (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box className="hover-box mr-lg flex__" onClick={handleOpen}>
                <ContentCopy
                  style={{ width: "18px", height: "18px" }}
                  className="mr-xs"
                />
                <MediumTypography labelId="cloneText" defaultLabel="Clone" />
              </Box>
            </LocalizationProvider>
          </>
        )}
      </Box>
      <Box className="flex__ width__100 statusIcon">
        {data.shiftPlanStatus === "DRAFT" && (
          <Tooltip title={data.shiftPlanStatus} followCursor arrow>
            <Note style={{ width: "18px", height: "18px" }} />
          </Tooltip>
        )}
      </Box>
      <Box className="flex__ width__100 holidayView">
        {data.holidayName && (
          <Box className="holidayOuterView">
            <MediumTypography
              label={data.holidayName}
              sxProps={{ fontSize: "12px" }}
            />
          </Box>
        )}
      </Box>
      <MemorizedCustomMenu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        minWidth={400}
        maxWidth={400}
        onClose={handleClose}
        sx={{
          width: "500px",
          padding: "0px",
        }}
      >
        <Box
          sx={{ minWidth: "300px", flexDirection: "column" }}
          className="flex__"
        >
          <Box className="flex__" onClick={(event) => event.stopPropagation()}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDateCalendar
                value={selectedDate}
                minDate={dayjs().add(1, "day")}
                onChange={handleDateChange}
                sx={{ color: "rgba(159, 173, 188, 1)" }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box onClick={(event) => event.stopPropagation()}>
          <Grid
            className="flex__ justifyContent-FlexEnd p-md "
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <ButtonComponent
                sxProps={{ color: "#B6C2CF !important" }}
                className="btn-primary btn-cancel mr-md"
                variantType="contained"
                defaultLabelId="Cancel"
                labelId="CancelBtnText"
                onClick={handleClose}
              />
            </Grid>
            <Grid item>
              <ButtonComponent
                className={
                  selectedDate
                    ? "btn-primary btn-submit "
                    : "btn-primary btn-disabled mr-md"
                }
                variantType="contained"
                defaultLabelId="Ok"
                labelId="OkText"
                sxProps={{
                  textTransform: "none",
                  fontFamily: "Inter",
                }}
                disabled={!selectedDate}
                onClick={cloneShiftPlan}
              />
            </Grid>
          </Grid>
        </Box>
      </MemorizedCustomMenu>
    </Box>
  );
};

export default DayShiftDetails;
