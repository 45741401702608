import { Box, Grid, Tooltip } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MediumTypography from "../../../components/formlib/MediumTypography";
import {
  PreferredTeamMember,
  RoleType,
  TeamDetails,
} from "../types/workOrderTypes";
import { ReactComponent as DragIcon } from "../../../assets/images/DragIcon.svg";
import { FC, useEffect, useState } from "react";
import UserprofileIcon from "../../../assets/images/profile-circle.svg";
import "./WoMaster.css";

interface TeamMemberProps {
  teamMembersData: PreferredTeamMember[];
  roleData: RoleType[];
  activeItem: string;
  handleDrag: (data: PreferredTeamMember[]) => void;
}

const muiCards = {
  xs: 2.7,
  sm: 2.7,
  md: 2.7,
  lg: 2.7,
  xl: 2.7,
};

const PreferredTeamMembers: FC<TeamMemberProps> = ({
  teamMembersData,
  roleData,
  activeItem,
  handleDrag,
}) => {
  const [activeTitle, setActiveTitle] = useState<string>("");

  useEffect(() => {
    setActiveTitle(activeItem);
  }, [activeItem]);

  useEffect(() => {
    // Update activeTitle based on the selected roles
    const selectedRoles = roleData.filter((role) => role.isSelected);
    if (!selectedRoles.some((role) => role.name === activeTitle)) {
      if (selectedRoles.length > 0) {
        setActiveTitle(selectedRoles[0].name); // Set to the first selected role
      } else {
        setActiveTitle(""); // Clear the active title if no roles are selected
      }
    }
  }, [roleData]);

  const filteredTeamMembers = teamMembersData.filter((teamMember) =>
    roleData.some((role) => role.id === teamMember.id && role.isSelected)
  );

  return (
    <>
      <Grid container>
        <Grid item className="ml-md mr-md mb-md mt-sm width__100">
          <Box className=" flex__justify__space-between ">
            <Box className="flex__">
              {filteredTeamMembers.map((item) => {
                return (
                  <Box
                    className="flex__container  mr-md align__items__center cursor__pointer title"
                    sx={{
                      backgroundColor:
                        activeTitle === item.name
                          ? "rgba(37, 186, 250, 0.16)"
                          : "rgba(84, 85, 88, 0.16)",

                      border:
                        activeTitle === item.name
                          ? "1px solid rgba(37, 186, 250, 1)"
                          : "1px solid rgba(84, 85, 88, 1)",
                    }}
                    onClick={() => {
                      setActiveTitle(item.name);
                    }}
                  >
                    <MediumTypography
                      label={item.name}
                      textColor={
                        activeTitle === item.name ? "#25BAFA" : "#949597"
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return;

            const { source, destination } = result;

            if (filteredTeamMembers) {
              // Find the active team's typeList
              const activeTeam = filteredTeamMembers.find(
                (item) => item.name === activeTitle
              );

              if (!activeTeam) return;

              const items = [...(activeTeam.list || [])];
              const [reorderedItem] = items.splice(source.index, 1);
              items.splice(destination.index, 0, reorderedItem);

              // Update the rank of the reordered items
              items.forEach((item, index) => {
                item.rank = index + 1;
              });

              const newPreferredTeamMembers = teamMembersData.map((item) => {
                if (item.name === activeTitle) {
                  return { ...item, list: items };
                }
                return item;
              });

              handleDrag(newPreferredTeamMembers);
              setActiveTitle(activeTitle);
            }
          }}
        >
          {/* Split items into sections */}
          {filteredTeamMembers
            .filter((item) => item.name === activeTitle)
            .map((teamMember) => {
              const itemsPerSection = 4; // Customize this based on layout constraints
              const sections: TeamDetails[][] = [];
              for (
                let i = 0;
                i < teamMember.list.length;
                i += itemsPerSection
              ) {
                sections.push(teamMember.list.slice(i, i + itemsPerSection));
              }

              return sections.map((sectionItems, sectionIndex) => (
                <Droppable
                  key={`section-${sectionIndex}`}
                  droppableId={`droppable-section-${sectionIndex}`}
                  direction="horizontal"
                >
                  {(provider) => (
                    <Grid
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                      container
                      spacing={2}
                      className="pl-md mb-sm flex__"
                      sx={{
                        flexWrap: "wrap",
                        gap: "8px",
                      }}
                    >
                      {sectionItems.map((team, index) => (
                        <Draggable
                          key={team.id || index}
                          draggableId={`draggable-${team.id || index}`}
                          index={index + sectionIndex * itemsPerSection} // Adjust index for the correct global order
                        >
                          {(provided) => (
                            <Grid
                              item
                              {...muiCards}
                              className="pl-md "
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {/* Box layout as defined */}
                              <Box>
                                <Grid
                                  container
                                  sx={{
                                    backgroundColor: "#323237",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <Grid
                                    item
                                    className="flex__ align__items__center pt-md pb-md pl-md"
                                    sx={{ position: "relative" }}
                                  >
                                    <Box
                                      sx={{
                                        width: "45px",
                                        height: "45px",
                                        backgroundImage:
                                          team.image !== undefined &&
                                          team.image !== null &&
                                          team.image !== ""
                                            ? `url(${team.image})`
                                            : `url(${UserprofileIcon})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: "50%",
                                      }}
                                    ></Box>
                                    <Box
                                      style={{
                                        borderRadius: "50%",
                                        backgroundColor: "#5C7E9F",
                                        border: "2px solid #22272B",
                                        position: "absolute",
                                        left: -4,
                                        top: -5,
                                      }}
                                      className="pl-xs pr-xs"
                                    >
                                      <MediumTypography
                                        label={team.rank}
                                        fontSize={"10px"}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    className="ml-xs pt-md pb-sm"
                                  >
                                    <Box
                                      className="flex__"
                                      sx={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box className={"mt-xs"}>
                                        <Tooltip
                                          title={
                                            team.name && team.name.length > 15
                                              ? team.name
                                              : ""
                                          }
                                          arrow={true}
                                          followCursor
                                        >
                                          <Box>
                                            <MediumTypography
                                              label={
                                                team.name === "" ||
                                                team.name === null
                                                  ? "-"
                                                  : team.name
                                              }
                                              className="ml-xs ellipsis"
                                              fontSize="14px"
                                              sxProps={{
                                                width:
                                                  team.name.length > 15
                                                    ? "120px"
                                                    : "auto",
                                              }}
                                            />
                                          </Box>
                                        </Tooltip>
                                        <Tooltip
                                          title={
                                            activeTitle &&
                                            activeTitle.length > 15
                                              ? activeTitle
                                              : ""
                                          }
                                          arrow={true}
                                          followCursor
                                        >
                                          <Box>
                                            <MediumTypography
                                              label={
                                                activeTitle === "" ||
                                                activeTitle === null
                                                  ? "-"
                                                  : activeTitle
                                              }
                                              className=" ml-xs ellipsis"
                                              fontSize="12px"
                                              sxProps={{
                                                width:
                                                  activeTitle.length > 15
                                                    ? "150px"
                                                    : "auto",
                                                opacity: 0.64,
                                              }}
                                            />
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                      <Box className="ml-sm mt-sm">
                                        <Box className="flex__ align__items__center flex__justify__center mt-sm">
                                          <DragIcon />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </Grid>
                  )}
                </Droppable>
              ));
            })}
        </DragDropContext>
      </Grid>
    </>
  );
};

export default PreferredTeamMembers;
