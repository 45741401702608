// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-container.MuiBox-root {
  display: flex;
  flex-direction: column; /* Stack the image and text vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
  padding: 10px; /* Optional: Add padding for spacing */
  box-sizing: border-box; /* Ensure padding is inside the width/height */
}

/* Style the image */
.center-image {
  max-width: 100%; /* Ensure the image is responsive */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: 10px; /* Space between the image and text */
}

/* Style the text */
.center-text {
  font-size: 1.2rem; /* Adjust text size as needed */
  color: #333; /* Text color */
  margin: 0;
}

.secondButtonCss.MuiToggleButtonGroup-root .MuiToggleButtonGroup-lastButton,
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton {
  border-left: 1px solid rgba(166, 197, 226, 0.16);
}

/* Override for the middle button, if needed */
.secondButtonCss.MuiToggleButtonGroup-root .MuiToggleButtonGroup-lastButton,
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton {
  border-left: 1px solid rgba(166, 197, 226, 0.16);
}
`, "",{"version":3,"sources":["webpack://./src/pages/wizards/WizardScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB,EAAE,wCAAwC;EAChE,mBAAmB,EAAE,wBAAwB;EAC7C,uBAAuB,EAAE,sBAAsB;EAC/C,kBAAkB;EAClB,aAAa,EAAE,sCAAsC;EACrD,sBAAsB,EAAE,8CAA8C;AACxE;;AAEA,oBAAoB;AACpB;EACE,eAAe,EAAE,mCAAmC;EACpD,YAAY,EAAE,8BAA8B;EAC5C,mBAAmB,EAAE,qCAAqC;AAC5D;;AAEA,mBAAmB;AACnB;EACE,iBAAiB,EAAE,+BAA+B;EAClD,WAAW,EAAE,eAAe;EAC5B,SAAS;AACX;;AAEA;;EAEE,gDAAgD;AAClD;;AAEA,8CAA8C;AAC9C;;EAEE,gDAAgD;AAClD","sourcesContent":[".center-container.MuiBox-root {\n  display: flex;\n  flex-direction: column; /* Stack the image and text vertically */\n  align-items: center; /* Center horizontally */\n  justify-content: center; /* Center vertically */\n  text-align: center;\n  padding: 10px; /* Optional: Add padding for spacing */\n  box-sizing: border-box; /* Ensure padding is inside the width/height */\n}\n\n/* Style the image */\n.center-image {\n  max-width: 100%; /* Ensure the image is responsive */\n  height: auto; /* Maintain the aspect ratio */\n  margin-bottom: 10px; /* Space between the image and text */\n}\n\n/* Style the text */\n.center-text {\n  font-size: 1.2rem; /* Adjust text size as needed */\n  color: #333; /* Text color */\n  margin: 0;\n}\n\n.secondButtonCss.MuiToggleButtonGroup-root .MuiToggleButtonGroup-lastButton,\n.MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton {\n  border-left: 1px solid rgba(166, 197, 226, 0.16);\n}\n\n/* Override for the middle button, if needed */\n.secondButtonCss.MuiToggleButtonGroup-root .MuiToggleButtonGroup-lastButton,\n.MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton {\n  border-left: 1px solid rgba(166, 197, 226, 0.16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
