import { Box } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";

interface VideoPlayerProps {
  url: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
  return (
    <Box sx={{ border: "1px solid rgba(166, 197, 226, 0.06)" }}>
      <ReactPlayer url={url} pip={false} controls width="100%" />
    </Box>
  );
};

export default VideoPlayer;
