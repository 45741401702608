export const EMAIL_REGEX = /^[^\s@]{1,64}@[A-Za-z0-9.-]{1,255}\.[A-Za-z]{2,}$/;

// export const USER_BASE_URL = "http://userapi.qa.paramrenewable.com/";
// export const WORK_ORDER_BASE_URL = "http://workorderapi.qa.paramrenewable.com/";

export const USER_BASE_URL = process.env.REACT_APP_USER_API;
export const WORK_ORDER_BASE_URL = process.env.REACT_APP_WORK_ORDER_API;
export const WORK_FLOW_BASE_URL = process.env.REACT_APP_WORK_FLOW_API;

export const STORAGE_USER_ID_KEY = "userId";
export const SESSION = "session";
export const EMAIL = "email";
export const CHANGE_PASSWORD = "changePassword";
export const USER_ID_DB = "id";
export const VERSION = "1.26.4";

export const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = mins < 10 ? `0${mins}` : `${mins}`;

  return `${formattedHours}:${formattedMinutes}`;
};

export const convertHoursAndMinutesToMinutes = (value: string) => {
  const [hours, minutes] = value.split(":").map(Number);
  return isNaN(hours) || isNaN(minutes) ? 0 : hours * 60 + minutes;
};
