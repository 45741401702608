import { Avatar, Box } from "@mui/material";
import MediumTypography from "../../components/formlib/MediumTypography";
import { FC } from "react";

import { ReactComponent as Menu } from "../../assets/images/HorizontalMenu.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import "./Notification.css";
import { ActionRequest, NotificationHistory } from "./notificationTypes";
import moment from "moment";
import { useIntl } from "react-intl";
import { ReactComponent as ApprovedSvg } from "../../assets/images/requestapproved.svg";
import { ReactComponent as RejectedSvg } from "../../assets/images/requestrejected.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/notfication_logo.svg";

interface CardProps {
  buttonRequired?: boolean;

  positiveLabel?: string;
  negativeLabel?: string;
  handleAction?: (data: ActionRequest) => void;

  notificationData: NotificationHistory;
}

export const getTimeDifference = (date: string, intl: any) => {
  const now = moment(); // This is already in local time
  const endDate = moment(date); // Convert UTC to local time
  const duration = moment.duration(now.diff(endDate));

  const years = duration.years();
  const months = duration.months();
  const weeks = duration.weeks();
  const days = duration.days() - weeks * 7; // Subtract weeks part to get remaining days
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (years > 0) return `${years}y`;
  if (months > 0) return `${months} mon`;
  if (weeks > 0) return `${weeks}w`;
  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes} min`;
  return intl.formatMessage({ id: "justNowText", defaultMessage: "Just Now" });
};

const NotificationCard: FC<CardProps> = ({
  positiveLabel,
  negativeLabel,
  handleAction,
  buttonRequired,
  notificationData,
}) => {
  const intl = useIntl();
  const timeDifference = getTimeDifference(
    notificationData.createdDatetime,
    intl
  );
  const approveRequest: ActionRequest = {
    requestId: notificationData.requestId,
    requestType: notificationData.requestType,
    isApprove: true,
    isRejected: false,
    fromUserId: notificationData.fromUserId,
    toUserId: notificationData.toUserId,
    notificationId: notificationData.notificationId,
    attendanceId: notificationData.attendanceId,
    level: notificationData.level,
    woUniqueId: notificationData.woUniqueId,
    woDesc: notificationData.woDesc,
    type: notificationData.type,
  };

  const rejectRequest: ActionRequest = {
    requestId: notificationData.requestId,
    requestType: notificationData.requestType,
    isApprove: false,
    isRejected: true,
    fromUserId: notificationData.fromUserId,
    toUserId: notificationData.toUserId,
    notificationId: notificationData.notificationId,
    attendanceId: notificationData.attendanceId,
    level: notificationData.level,
    woUniqueId: notificationData.woUniqueId,
    woDesc: notificationData.woDesc,
    type: notificationData.type,
  };

  const handlePositiveAction = () => {
    if (handleAction) {
      handleAction(approveRequest);
    }
  };

  const handleNegativeAction = () => {
    if (handleAction) {
      handleAction(rejectRequest);
    }
  };

  return (
    <Box className={"pt-lg "}>
      <Box className="flex__">
        {notificationData.systemGenerated ? (
          <NotificationIcon />
        ) : (
          <>
            {notificationData.imageUrl !== null &&
            notificationData.imageUrl !== "" &&
            notificationData.imageUrl !== undefined ? (
              <div
                style={{
                  backgroundImage: `url(${notificationData.imageUrl})`,
                }}
                className="notification-image"
              ></div>
            ) : (
              <Box className="notification-container">
                <MediumTypography
                  fontSize="20px"
                  fontWeight={600}
                  // className="pl-sm pt-sm"
                  label={
                    notificationData.placeholderText &&
                    notificationData.placeholderText.toUpperCase()
                  }
                />
              </Box>
            )}
          </>
        )}

        <Box
          className="flex__ pb-lg "
          sx={{ borderBottom: "1px solid #E2E8F029", width: "90%" }}
        >
          <Box className=" ml-md" sx={{ width: "80%" }}>
            <MediumTypography
              label={
                notificationData.title !== null &&
                notificationData.title !== "" &&
                notificationData.title !== undefined
                  ? notificationData.title
                  : "-"
              }
              fontWeight={600}
            />
            {notificationData.remarks !== null &&
              notificationData.remarks !== "" &&
              notificationData.remarks !== undefined && (
                <Box
                  sx={{ borderLeft: "4px solid #FFFFFF" }}
                  className="mt-xs mb-sm"
                >
                  <MediumTypography
                    label={notificationData.remarks}
                    fontWeight={400}
                    textColor="#909191"
                    className="ml-sm"
                  />
                </Box>
              )}

            {buttonRequired && notificationData.status === null ? (
              <>
                <ButtonComponent
                  className="btn-primary btn-submit mr-md"
                  variantType="contained"
                  defaultLabelId={"Save"}
                  labelId={positiveLabel}
                  onClick={handlePositiveAction}
                />
                <ButtonComponent
                  className="btn-primary btn-reject "
                  variantType="outlined"
                  defaultLabelId={"Reset"}
                  labelId={negativeLabel}
                  onClick={handleNegativeAction}
                />
              </>
            ) : (
              (notificationData.status?.toLowerCase() === "approved" ||
                notificationData.status?.toLowerCase() === "rejected") &&
              buttonRequired && (
                <Box
                  sx={{
                    backgroundColor:
                      notificationData.status?.toLowerCase() === "approved"
                        ? "#00800033"
                        : notificationData.status?.toLowerCase() === "rejected"
                        ? "#FF000033"
                        : "transparent",
                  }}
                  className="flex__ align__items__center p-xs"
                >
                  {notificationData.type?.toLowerCase() === "action" &&
                  notificationData.status?.toLowerCase() === "approved" ? (
                    <ApprovedSvg />
                  ) : (
                    <RejectedSvg />
                  )}

                  {notificationData.type?.toLowerCase() === "action" ? (
                    notificationData.status?.toLowerCase() === "approved" ? (
                      <MediumTypography
                        labelId={"approvedText"}
                        defaultLabel="Approved"
                        fontWeight={400}
                        textColor="#008000"
                        className="ml-sm"
                      />
                    ) : (
                      notificationData.status?.toLowerCase() === "rejected" && (
                        <MediumTypography
                          labelId={"rejectedText"}
                          defaultLabel="Approved"
                          fontWeight={400}
                          textColor="#FF0000"
                          className="ml-sm"
                        />
                      )
                    )
                  ) : (
                    <MediumTypography
                      label={notificationData.actionMsg}
                      fontWeight={400}
                      textColor="#909191"
                      className="ml-sm"
                    />
                  )}
                </Box>
              )
            )}
          </Box>
          <Box className=" ml-md">
            <MediumTypography
              label={timeDifference}
              fontSize="12px"
              fontWeight={400}
              className="mb-sm"
            />
            <Menu width={"20px"} height={"20px"} />
          </Box>
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
};

export default NotificationCard;
