import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  IconButton,
  Badge,
  Grid,
  Tooltip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import MediumTypography from "../../../components/formlib/MediumTypography";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import { ReactComponent as RoleSave } from "../../../assets/images/tickAdd-circle.svg";
import { ReactComponent as RoleDelete } from "../../../assets/images/closeAdd-circle.svg";
import AssignedPermission from "./AssignedPermission";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import {
  fetchAllDataRolePermissionApi,
  saveDataRolePermissionApi,
  updateDataRolePermissionApi,
  saveMapdataRolePermissionApi,
} from "../../../api/MasterDataManagement/Common";
import { HeadCell } from "../types/accountTypes";
import {
  RoleAndPermissionRecordsType,
  PermissionRecordType,
  RoleRecordType,
  SaveRoleAndPermissionType,
  updateRoleType,
  RolePermissionMappingType,
  responseType,
} from "../types/rolesAndPermission";
import TextInput from "../../../components/formlib/TextInput";
import { handleError } from "../../../utils/commonFunctions";

type RecordsType = RoleAndPermissionRecordsType;
const apiUri = "rolePermissions";
const searchKeys = ["name"];
const staticColumn = "name";
const dataFields = [
  {
    key: "role",
    value: "Roles",
  },
];

export interface ManageComponentProps {
  onFilter: (filteredData: { [key: string]: string }) => void;
}

const ManageRolesAndPermissionComponent: FC<ManageComponentProps> = ({
  onFilter,
}) => {
  const availableWidth = useAvailableWidth(420);
  const availableHeight = useAvailableHeight(220);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [tableData, setTableData] = useState<RoleRecordType[]>([]);
  const [permissionData, setPermissionData] = useState<PermissionRecordType[]>(
    []
  );
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalAdd, setWarningModalAdd] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | null>();
  const [fisrtRadioClick, setRadioClick] = useState<boolean>(false);
  const [mappedData, setMappedData] = useState<RolePermissionMappingType[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>();
  const [errorDesc, setErrorDesc] = useState<string>("");
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [showButton, setShowSaveButton] = useState(false);
  const mappedRef = useRef<RolePermissionMappingType[]>([]);
  const [rolenotSaved, setRoleNotSaved] = useState<boolean>(false);
  const [userName, setUsername] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const originalRoleVal = useRef<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const saveClick = useRef<boolean>(false);
  const dataFieldsWithIcons = reorderedFields?.map((field) => {
    return { ...field, icon: <Sort /> };
  });

  useEffect(() => {
    setReorderedFields(dataFields);
  }, []);

  const handleEditClick = (name: string, id: number) => {
    setIsEditing(true);
    setEdit(true);
    originalRoleVal.current = name;
  };

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop =
        tableContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollBottom && scrollToBottom();
  }, [scrollBottom]);
  const handleAddRole = () => {
    setScrollBottom(true);

    if (
      tableData[tableData.length - 1].name == "" ||
      tableData[tableData.length - 1].id == undefined
    ) {
      setWarningModal(true);
      return;
    }
    if (tableData.length > 0) {
      const emptyIndex = tableData.findIndex(
        (obj: RoleRecordType) => obj.name === ""
      );

      if (tableData[tableData.length - 1].name == "" && !fisrtRadioClick) {
        setSelectedValue(emptyIndex + 1);
      } else {
        setSelectedValue(tableData.length + 1);
        setSelectedRoleId(undefined);
      }
    }
    const newRole: RoleRecordType = {
      name: "",
    };

    setTableData([...tableData, newRole]);
    setSelectedValue(tableData.length + 1);
    setRoleNotSaved(true);
    setShowSaveButton(false);
  };

  const handleResetRole = (index: number | undefined | null) => {
    if (index === null || index === undefined) {
      return;
    }

    const newRoleData = [...tableData];
    if (originalRoleVal.current != undefined) {
      newRoleData[index].name = originalRoleVal.current;
    }

    setTableData(newRoleData);
  };

  const handleRadioChange = (index: number, rowId: number | undefined) => {
    if (tableData[tableData.length - 1].name == "") {
      const tableData1 = tableData.filter((obj) => obj.id !== undefined);
      setTableData(tableData1);
    }
    setEdit(false);
    setIsEditing(false);
    setShowSaveButton(false);
    setRadioClick(true);
    setSelectedValue(index + 1);
    setSelectedRoleId(rowId);

    if (rowId == undefined) {
      setSelectedRoleId(undefined);
      setRoleNotSaved(true);
    }
    setRoleNotSaved(false);
  };
  useEffect(() => {
    getRecords();
  }, []);

  const convertDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year} `;
  };

  const getRecords = () => {
    toggleLoader(true);
    setIsEditing(false);
    fetchAllDataRolePermissionApi<RecordsType>(apiUri)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setRoleNotSaved(false);
          if (response.role.length == 0) {
            const newRole: RoleRecordType = {
              name: "",
            };

            setTableData([newRole]);
            setSelectedValue(1);
          } else {
            const updatedItems = response.role.map((item) => {
              const role = response.rolePermissionMap.find(
                (role) => role.roleId === item.id
              );

              return {
                ...item,
                permissionCount: role ? role.permissionIds.length : 0,
              };
            });

            setTableData(updatedItems);
          }
          setDate(convertDate(response.lastUpdated.timestamp));

          if (saveClick.current === false) {
            setSelectedValue(1);
            setSelectedRoleId(response?.role[0]?.id);
          }

          setUsername(response.lastUpdated.name);
          setMappedData(response.rolePermissionMap);
          setPermissionData(response.permission);
          setShowSaveButton(false);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const saveData = (
    rolename: string,
    type: string,
    originalRoleValue: string,
    rowId?: number
  ) => {
    if (rowId !== undefined && rowId !== null) {
      let updatedVal;
      let data;
      if (type === "ROLE") {
        data = tableData.filter(
          (item) => item.id === rowId && item.name === originalRoleValue
        );
      } else {
        data = permissionData.filter(
          (item) => item.id === rowId && item.name === originalRoleValue
        );
      }

      if (data.length > 0) {
        setEdit(false);
        setIsEditing(false);
        toggleLoader(false);
        return;
      } else {
        toggleLoader(true);
        updateDataRolePermissionApi<updateRoleType>(
          apiUri,
          type,
          rowId,
          rolename
        )
          .then((response: updateRoleType) => {
            if (type === "ROLE") {
              updatedVal = tableData.map((obj) =>
                obj.name === response.name
                  ? { ...obj, id: response.id, permissionCount: 0 }
                  : obj
              );
              setEdit(false);
              setIsEditing(false);
              setTableData(updatedVal);
            } else {
              updatedVal = permissionData.map((obj) =>
                obj.id === response.id ? { ...obj, name: response.name } : obj
              );

              setPermissionData(updatedVal);
              setEdit(false);
              setIsEditing(false);
            }
            toggleLoader(false);
          })
          .catch((err) => {
            handleError(err, setErrorDesc);
            setOpenErrorModal(true);
            toggleLoader(false);
          });
      }
    } else {
      toggleLoader(true);
      setScrollBottom(false);
      setRoleNotSaved(false);
      saveDataRolePermissionApi<SaveRoleAndPermissionType>(apiUri, type, {
        values: [rolename],
      })
        .then((response: responseType) => {
          if (type === "ROLE") {
            let updatedValSave = tableData.map((obj) =>
              obj.name === response.name
                ? { ...obj, id: response.id, permissionCount: 0 }
                : obj
            );

            setTableData(updatedValSave);
          } else {
            setPermissionData([...permissionData, response]);
          }
          setEdit(false);
          setIsEditing(false);
          toggleLoader(false);
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);

          toggleLoader(false);
        });
    }
  };

  const getPermissionByRoleId = (roleIdToFind: number | null | undefined) => {
    const role = mappedData?.find(
      (item: RolePermissionMappingType) => item.roleId === roleIdToFind
    );
    return role?.permissionIds || null;
  };

  const hasSameValues = (permissionIds: number[], ids: number[]) => {
    if (permissionIds.length !== ids.length) return false;

    for (let i = 0; i < permissionIds.length; i++) {
      if (!ids.includes(permissionIds[i])) {
        return false;
      }
    }

    return true;
  };

  const getAssignPermission = (assignedPermission: PermissionRecordType[]) => {
    const permissionIds: number[] | null | undefined =
      getPermissionByRoleId(selectedRoleId);

    const ids: number[] = assignedPermission.map((obj) => obj.id ?? 0);

    const finalResult =
      permissionIds !== null ? hasSameValues(permissionIds, ids) : false;

    if (permissionIds == null && assignedPermission.length > 0) {
      setShowSaveButton(true);
    } else if (finalResult == false) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
    if (ids.length == 0 && permissionIds == null) {
      setShowSaveButton(false);
    }
    const permissionId = assignedPermission.map(
      (permission: PermissionRecordType) => permission.id
    );
    const filteredPermissionId = permissionId.filter(
      (id) => id !== null && id !== undefined
    ) as number[];

    if (selectedValue !== null && selectedValue !== undefined) {
      mappedRef.current.push({
        roleId: tableData[selectedValue - 1]?.id ?? null,
        permissionIds: filteredPermissionId,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDelete = useCallback(() => {
    setWarningModal(false);
  }, []);

  const handleConfirm = () => {
    setWarningModalAdd(false);
  };

  const saveMappedData = () => {
    toggleLoader(true);
    saveMapdataRolePermissionApi<RolePermissionMappingType[]>(
      "rolePermissions",
      [mappedRef.current[mappedRef.current.length - 1]]
    )
      .then(() => {
        saveClick.current = true;
        getRecords();
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      <Box className="flex__justify__space-between mb-md">
        {warningModal && (
          <ModalPopup
            descriptionText={"roleAndPermission.AddWarning"}
            open={warningModal}
            handleClose={() => setWarningModal(!warningModal)}
            onPositiveClick={handleConfirmDelete}
            onNegativeClick={() => {
              setWarningModal(false);
              handleClose();
            }}
            positiveButtonLabel="Yes"
            positiveButtonLabelId="YesText"
            negativeButtonLabel="No"
            negativeButtonLabelId="NoText"
          />
        )}
        {warningModalAdd && (
          <ModalPopup
            descriptionText={"roleAndPermissionAdd.AddWarning"}
            open={warningModalAdd}
            handleClose={() => setWarningModal(!warningModalAdd)}
            onPositiveClick={handleConfirm}
            positiveButtonLabel="OK"
            positiveButtonLabelId="OK"
          />
        )}

        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
        <Box>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId={"roleandpermission.title"}
              defaultLabel="Roles and Permission"
              fontSize="20px"
            />
            <Box className="display-flex-center" style={{ marginRight: "1%" }}>
              <MediumTypography
                labelId={"lastupdatedon.role"}
                defaultLabel="Last updated on"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={date}
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                labelId={"lastupdatedby.role"}
                defaultLabel="by"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={`${userName}`}
                textColor="rgba(255, 255, 255, 0.64)"
              />
            </Box>
          </Box>
          <Box
            className="flex__"
            width={availableWidth}
            sx={{ flexDirection: "row" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4.5}>
                <Box
                  sx={{
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                  className={"position__relative"}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      overflowX: "auto",
                      backgroundColor: "#2B3033",
                      height: availableHeight,
                      overflowY: "auto",
                    }}
                    ref={tableContainerRef}
                  >
                    <Table>
                      <CustomTableHeader
                        headerNames={dataFieldsWithIcons}
                        searchableKeys={searchKeys}
                        stickyItems={staticColumn}
                        showButtonAdd={true}
                        onAddClick={handleAddRole}
                      />
                      <TableBody>
                        {tableData.length !== 0 &&
                          tableData.map(
                            (row: RoleRecordType, rowIndex: number) => {
                              const incrementRowIndex = rowIndex + 1;
                              return (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                    border: "0.2px 	#383838 solid",
                                  }}
                                >
                                  <TableCell
                                    align={"left"}
                                    className={"table_cell_role"}
                                    sx={{
                                      backgroundColor:
                                        selectedValue === incrementRowIndex
                                          ? "#34383C"
                                          : rowIndex % 2 === 0
                                          ? "#2B3033"
                                          : "#22272B",
                                      padding: "4px 12px",
                                    }}
                                  >
                                    <Radio
                                      edge="start"
                                      id="radio"
                                      checked={
                                        selectedValue === incrementRowIndex
                                      }
                                      onChange={() => {
                                        if (
                                          row?.id !== null &&
                                          row?.id !== undefined
                                        ) {
                                          handleRadioChange(rowIndex, row?.id);
                                        } else {
                                          handleRadioChange(
                                            rowIndex,
                                            undefined
                                          );
                                        }
                                      }}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": `radio-list-label-${rowIndex}`,
                                      }}
                                      sx={{ color: "grey" }}
                                      disabled={
                                        selectedValue !== incrementRowIndex &&
                                        edit
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    align={"left"}
                                    className={"table_cell_role"}
                                    sx={{
                                      backgroundColor:
                                        selectedValue === incrementRowIndex
                                          ? "#34383C"
                                          : rowIndex % 2 === 0
                                          ? "#2B3033"
                                          : "#22272B",
                                      padding: "4px 12px",
                                    }}
                                  >
                                    <MediumTypography
                                      label={incrementRowIndex.toString()}
                                    />
                                  </TableCell>

                                  <TableCell
                                    align={"left"}
                                    className={"table_cell_role"}
                                    sx={{
                                      backgroundColor:
                                        selectedValue === incrementRowIndex
                                          ? "#34383C"
                                          : rowIndex % 2 === 0
                                          ? "#2B3033"
                                          : "#22272B",
                                      padding: "4px 12px",
                                    }}
                                  >
                                    {(isEditing &&
                                      selectedValue === incrementRowIndex) ||
                                    row?.id == undefined ? (
                                      <Box
                                        className={"flex__"}
                                        alignItems={"center"}
                                      >
                                        <TextInput
                                          className="text-input-fieldRole"
                                          type="text"
                                          variant="outlined"
                                          inputProps={{
                                            readOnly: false,
                                          }}
                                          onFocus={() => {
                                            setEdit(true);
                                          }}
                                          Value={row.name}
                                          handlechange={(value: string) => {
                                            const newRoleData = [...tableData];
                                            newRoleData[rowIndex].name = value;
                                            setTableData(newRoleData);
                                          }}
                                        />
                                        {
                                          <IconButton
                                            onClick={() => {
                                              if (row?.id) {
                                                saveData(
                                                  row.name,
                                                  "ROLE",
                                                  originalRoleVal?.current,
                                                  row?.id
                                                );
                                              } else {
                                                saveData(
                                                  row.name,
                                                  "ROLE",
                                                  originalRoleVal?.current
                                                );
                                              }
                                            }}
                                            disabled={!row?.name}
                                          >
                                            <RoleSave />
                                          </IconButton>
                                        }

                                        {row?.id && (
                                          <IconButton
                                            onClick={() => {
                                              handleResetRole(rowIndex);
                                              setIsEditing(false);
                                              setEdit(false);
                                            }}
                                          >
                                            <RoleDelete />
                                          </IconButton>
                                        )}
                                        {row?.id && (
                                          <Badge
                                            sx={{
                                              width: "22px",
                                              height: "22px",
                                              borderRadius: "50%",
                                              backgroundColor: "#007aff",
                                              color: "white",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              marginLeft: 1,
                                            }}
                                          >
                                            {row.permissionCount}
                                          </Badge>
                                        )}
                                      </Box>
                                    ) : (
                                      <Box
                                        className="flex__"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        onClick={() =>
                                          handleEditClick(
                                            row.name,
                                            incrementRowIndex
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                        sx={{
                                          pointerEvents:
                                            selectedValue === incrementRowIndex
                                              ? "auto"
                                              : "none",
                                        }}
                                      >
                                        <Tooltip
                                          title={row.name}
                                          placement="bottom"
                                        >
                                          <span>
                                            <MediumTypography
                                              label={row.name}
                                              sxProps={{
                                                maxWidth: "200px",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                              }}
                                            />
                                          </span>
                                        </Tooltip>
                                        <Badge
                                          sx={{
                                            width: 20,
                                            height: 21,
                                            fontWeight: 700,
                                            font: "Inter",
                                            fontSize: 12,
                                            lineHeight: "22px",
                                            borderRadius: "9px",
                                            backgroundColor: "#007aff",
                                            color: "white",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {row.permissionCount}
                                        </Badge>
                                      </Box>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid xs={7.5} md={7.5} lg={7.5} className={"pt-md pl-one"}>
                <AssignedPermission
                  mapData={mappedData}
                  permissionData={permissionData}
                  selectedRoleId={selectedRoleId}
                  handleAddRolePermission={saveData}
                  getAssignPermission={getAssignPermission}
                  roleNotSaved={rolenotSaved}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box mt={"30px"} mr={"-20px"} className="flex__ justifyContent-FlexEnd ">
        {showButton && !rolenotSaved && (
          <ButtonComponent
            className="btn-primary btn-submit"
            variantType="contained"
            defaultLabelId={"Save"}
            labelId={"btn.save"}
            onClick={() => {
              saveMappedData();
            }}
          />
        )}
      </Box>
    </>
  );
};

export default ManageRolesAndPermissionComponent;
