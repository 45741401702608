import { useEffect, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { Box } from "@mui/material";
import { TemplateFieldType, TicketDetailsType } from "../types/Types";
import TextInput from "../../../components/formlib/TextInput";
import { hasTicketEditPermission } from "../helpers/helper";
import CustomRichText from "../../../components/formlib/CustomRichText";

const Details = ({
  fieldValue,
  textFields,
  ticketDetails,
  handleDetailsChange,
  saveCallback,
}: {
  fieldValue: string;
  textFields: TemplateFieldType[];
  ticketDetails: TicketDetailsType;
  handleDetailsChange: (updatedDetails: Partial<TicketDetailsType>) => void;
  saveCallback: (value: string) => void;
}) => {
  const [updatedValue, setUpdatedValue] = useState(fieldValue);

  useEffect(() => {
    setUpdatedValue(fieldValue);
  }, [fieldValue]);

  const handleChange = (field: TemplateFieldType, value: any) => {
    let payloadObject = { [field.code]: value };

    if (field.initialFieldType === "CUSTOM") {
      payloadObject = {
        customField: payloadObject,
      };
    }

    handleDetailsChange(payloadObject);
  };

  return (
    <>
      <Box>
        <Box className="added-margin" sx={{ display: "flex", gap: "8px" }}>
          <MediumTypography
            labelId={"title.description"}
            defaultLabel="Description"
            className="ticket-subheader"
          />
          <span className="ticket-asterisk">*</span>
        </Box>
        <Box mt={1} className="ticket-rich-text-box">
          <CustomRichText
            inline
            labelId="common.placeHolderText"
            defaultLabelId="--- type here ---"
            data={updatedValue}
            onChangeCallBack={(value) => {
              handleDetailsChange({ description: value });
            }}
            readOnly={!hasTicketEditPermission()}
          />
        </Box>
      </Box>

      {textFields?.map((field) => {
        return (
          <Box key={field.code}>
            <Box className="added-margin" sx={{ display: "flex", gap: "8px" }}>
              <MediumTypography
                label={field.alias}
                className="ticket-subheader"
              />
              {field.isRequired && <span className="ticket-asterisk">*</span>}
            </Box>

            {field.type === "text" && (
              <TextInput
                inline
                className="text-input-field inline-text-input no-side-padding"
                type={field.type}
                Value={ticketDetails[field.code] ?? ""}
                handlechange={(value: string) => {
                  handleChange(field, value);
                }}
                disabled={field.isDisabled}
              />
            )}

            {field.type === "rich_text" && (
              <Box mt={1} className="ticket-rich-text-box">
                <CustomRichText
                  inline
                  labelId="common.placeHolderText"
                  defaultLabelId="--- type here ---"
                  data={ticketDetails[field.code] ?? ""}
                  onChangeCallBack={(value) => {
                    handleChange(field, value);
                  }}
                  readOnly={field.isDisabled}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};
export default Details;
