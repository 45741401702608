// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-reject.MuiButton-root {
  background: none;
  color: #ababab;
  border: 1px solid #4b4b4b;
  opacity: 0.85;
}
.btn-reject.MuiButton-root:hover {
  background: none;
  color: #ababab;
  border: none;
  opacity: 1;
}

.activeItem {
  border-bottom: 3px solid #457ffb;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.notification-image {
  width: 48px;
  height: 48px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 2px solid #457ffb;
}

.notification-container {
  height: 48px;
  width: 48px;
  border-radius: 200px;
  overflow: hidden;
  background-color: #1d2125;
  justify-content: center;
  align-items: center;
  border: 2px solid #457ffb;
  display: flex;
}

.custom-drawer {
  width: 500px;
  padding-inline: 24px;
  flex-shrink: 0;
}

.custom-drawer .MuiDrawer-paper {
  width: 500px;
  box-sizing: border-box;
  background-color: #1d2125;
  border: 1px solid rgba(166, 197, 226, 0.16);
}
`, "",{"version":3,"sources":["webpack://./src/pages/notifications/Notification.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,yBAAyB;EACzB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,2BAA2B;EAC3B,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,oBAAoB;EACpB,gBAAgB;EAChB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,2CAA2C;AAC7C","sourcesContent":[".btn-reject.MuiButton-root {\n  background: none;\n  color: #ababab;\n  border: 1px solid #4b4b4b;\n  opacity: 0.85;\n}\n.btn-reject.MuiButton-root:hover {\n  background: none;\n  color: #ababab;\n  border: none;\n  opacity: 1;\n}\n\n.activeItem {\n  border-bottom: 3px solid #457ffb;\n  border-top-left-radius: 2px;\n  border-top-right-radius: 2px;\n}\n.notification-image {\n  width: 48px;\n  height: 48px;\n  background-size: cover;\n  background-position: center;\n  border-radius: 50%;\n  border: 2px solid #457ffb;\n}\n\n.notification-container {\n  height: 48px;\n  width: 48px;\n  border-radius: 200px;\n  overflow: hidden;\n  background-color: #1d2125;\n  justify-content: center;\n  align-items: center;\n  border: 2px solid #457ffb;\n  display: flex;\n}\n\n.custom-drawer {\n  width: 500px;\n  padding-inline: 24px;\n  flex-shrink: 0;\n}\n\n.custom-drawer .MuiDrawer-paper {\n  width: 500px;\n  box-sizing: border-box;\n  background-color: #1d2125;\n  border: 1px solid rgba(166, 197, 226, 0.16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
