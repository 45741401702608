import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";

import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import dayjs from "dayjs";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { hasPermission } from "../../../utils/checkPermission";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import TableHeaderSelection from "../TableHeaderSelection";
import { ReactComponent as Menu } from "../../../assets/images/HorizontalMenu.svg";
import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import AddEditModal from "./AddEditLineItemModal";
import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../TableUtility";
import { useLocation } from "react-router-dom";
import {
  ChecklistRecordsType,
  ChecklistRecordType,
} from "../types/checklistTypes";
import {
  deleteDataApi,
  fetchAllDataApi,
} from "../../../api/MasterDataManagement/Common";
import { HeadCell } from "../types/accountTypes";
import CheckYesIcon from "@mui/icons-material/CheckCircle";
import CheckNoIcon from "@mui/icons-material/Cancel";
import {
  ChecklistLineItemRecordsType,
  ChecklistLineItemRecordType,
} from "../types/checklistLineItemTypes";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import { handleError } from "../../../utils/commonFunctions";

// Configurations

type RecordType = ChecklistLineItemRecordType;
type RecordsType = ChecklistLineItemRecordsType;

const apiUri = "checklistLine";

const searchKeys = [
  "name",
  "checklistType",
  "description",
  "assetCategory",
  "make",
  "model",
];

const staticColumn = "name";

export interface ManageComponentProps {
  onDataFetch: (data: RecordsType) => void;
  selectedLanguageCode: string;
  onOpenAddModal: boolean;
  handleModalClose: () => void;
  handleModalOpen: () => void;
  onFilter: (filteredData: { [key: string]: string }) => void;
}

const ManageLineItemComponent: FC<ManageComponentProps> = ({
  onDataFetch,
  selectedLanguageCode,
  onOpenAddModal,
  handleModalClose,
  handleModalOpen,
  onFilter,
}) => {
  const availableWidth = useAvailableWidth(420);
  const availableHeight = useAvailableHeight(220);
  const emptyTableHeight = useAvailableHeight(300);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const containerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [listData, setListData] = useState<RecordsType | null>(null);
  const [tableData, setTableData] = useState<RecordType[]>([]);
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [originalTableData, setOriginalTableData] = useState<RecordType[]>([]);

  const [modalAccountId, setModalAccountId] = useState<number>();
  const open = Boolean(filterOpen);
  const addEditOpen = Boolean(anchorEl);

  const dataFieldsWithIcons = reorderedFields?.map((field) => {
    return { ...field, icon: <Sort /> };
  });

  const location = useLocation();

  useEffect(() => {
    getRecords(true);
  }, [selectedLanguageCode, location?.state]);

  const mockFetchAllDataApi = async () => {
    const res = await fetch("mock_api/checklist_line_items/fetchAll.json");
    return await res.json();
  };

  const getRecords = (
    resetData: boolean = false,
    language: string = selectedLanguageCode,
    applyFilters: boolean = false
  ) => {
    toggleLoader(true);

    const filterQuery = Object.entries(activeFilters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    fetchAllDataApi<RecordsType>(apiUri, language, filterQuery)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setListData(response);

          if (resetData) {
            setTableData(response.checklistLineItemRecords);
            setOriginalTableData(response.checklistLineItemRecords);
          } else {
            const updateTableData = (
              newRecords: ChecklistLineItemRecordType[]
            ) => {
              setTableData((prevMessages) => [...prevMessages, ...newRecords]);
              setOriginalTableData((prevMessages) => [
                ...prevMessages,
                ...newRecords,
              ]);
            };

            // Use the helper function with response data
            updateTableData(response.checklistLineItemRecords);
          }

          setReorderedFields(response.dataFields);

          onDataFetch(response);
          if (applyFilters && Object.keys(activeFilters).length > 0) {
            const filteredData = applySearchFilters(
              response.checklistLineItemRecords,
              activeFilters
            );
            setTableData(filteredData as RecordType[]);
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    setActiveFilters({});
  };

  const handleDelete = (id: number) => {
    toggleLoader(true);
    deleteDataApi(apiUri, id)
      .then(() => {
        getRecords(true, selectedLanguageCode, true);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    handleClose();
  };

  const handleSort = (columnKey: string, direction: string) => {
    const sortedData = sortTableData(
      tableData,
      columnKey,
      direction,
      originalTableData
    );
    setTableData(sortedData as RecordType[]);
  };

  const handleSearchApply = (columnKey: string, value: string) => {
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredData = applySearchFilters(originalTableData, newFilters);
    setTableData(filteredData as RecordType[]);
    onFilter(newFilters);
    setActiveFilters(newFilters);
  };

  const handleSearchCancel = (columnKey: string) => {
    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalTableData,
      activeFilters,
      columnKey
    );
    setTableData(filteredData as RecordType[]);
    onFilter(updatedFilters);
    setActiveFilters(updatedFilters);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id: number) => {
    setModalAccountId(id);
    handleModalOpen();
    handleClose();
  };

  const handleCloseMenu = () => {
    setFilterOpen(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterOpen(event.currentTarget);
  };

  const handleApply = (reorderedCheckedItems: HeadCell[]) => {
    setReorderedFields(reorderedCheckedItems);
    // Perform any additional actions with the reorderedCheckedItems
  };

  const handleConfirmDelete = useCallback(() => {
    if (modalAccountId) {
      handleDelete(modalAccountId);
      setModalAccountId(undefined);
    }
    setWarningModal(false);
  }, [modalAccountId]);
  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Box>
      {onOpenAddModal && (
        <AddEditModal
          id={modalAccountId}
          open={onOpenAddModal}
          handleClose={() => {
            setModalAccountId(undefined);
            handleModalClose();
          }}
          getDataCallback={() => getRecords(true)}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {warningModal && (
        <ModalPopup
          descriptionText={"CheckListLineItem.deleteWarningmessage"}
          open={warningModal}
          handleClose={() => {
            setModalAccountId(undefined);
            setWarningModal(!warningModal);
          }}
          onPositiveClick={handleConfirmDelete}
          onNegativeClick={() => {
            setModalAccountId(undefined);
            setWarningModal(false);
            handleClose();
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {listData && (
        <Box>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId="lineItem.title"
              defaultLabel="Check List"
              fontSize="20px"
            />
            <Box className="flex__" alignItems="center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData.totalRecords
                    : `${tableData.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />
              <Box
                className="ml-md display-flex-center menu-item"
                onClick={handleMenuClick}
              >
                <Menu />
              </Box>
              <TableHeaderSelection
                data={listData.dataFields}
                anchorEl={filterOpen}
                handleClose={handleCloseMenu}
                open={open}
                onApply={handleApply}
                fixedItem={staticColumn}
              />
            </Box>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: availableHeight,
            }}
            className={"position__relative overflow_Y"}
          >
            <TableContainer
              component={Paper}
              sx={{
                width: availableWidth,
                height: tableData.length !== 0 ? availableHeight : "auto",
              }}
              className="tableContainer"
              ref={containerRef}
            >
              <Table>
                <CustomTableHeader
                  headerNames={dataFieldsWithIcons}
                  searchableKeys={searchKeys}
                  stickyItems={staticColumn}
                  onSortApply={handleSort}
                  onCancelSearch={handleSearchCancel}
                  onSearchApply={handleSearchApply}
                  languageCode={selectedLanguageCode}
                />
                <TableBody>
                  {tableData.length !== 0 &&
                    tableData.map((row, rowIndex) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                      >
                        {/* Sticky First Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                            padding: "4px 12px",
                          }}
                          className="stickyColumn"
                        >
                          <MediumTypography label={(rowIndex + 1).toString()} />
                        </TableCell>

                        {dataFieldsWithIcons.map((cell: HeadCell) => {
                          const cellValue = row[cell.key as keyof RecordType];

                          if (cell.key === "lastUpdated") {
                            return (
                              <TableCell
                                key={cell.key}
                                align={"left"}
                                sx={{
                                  backgroundColor:
                                    rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                  padding: "4px 12px",
                                }}
                                // className="p-md"
                              >
                                <Box className="display-flex-center">
                                  <Avatar
                                    src={
                                      row.lastUpdated !== null &&
                                      row.lastUpdated !== undefined
                                        ? row.lastUpdated.imageUrl
                                        : "-"
                                    }
                                    alt="abc"
                                    className="avatar-style"
                                  />
                                  <Box className="ml-sm">
                                    <MediumTypography
                                      label={
                                        row.lastUpdated !== null &&
                                        row.lastUpdated !== undefined
                                          ? row.lastUpdated.name
                                          : "-"
                                      }
                                      sxProps={{
                                        lineHeight: "normal",
                                      }}
                                      className="mt-xs"
                                    />

                                    <MediumTypography
                                      label={
                                        row.lastUpdated !== null &&
                                        row.lastUpdated !== undefined
                                          ? dayjs(
                                              row.lastUpdated.timestamp
                                            ).format("DD MMM YYYY HH:mm")
                                          : ""
                                      }
                                      fontSize="12px"
                                      textColor={"rgba(255, 255, 255, 0.64)"}
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                            );
                          }

                          if (cell.key === "isStandardChecklist") {
                            return (
                              <TableCell
                                key={cell.key}
                                align={"left"}
                                sx={{
                                  backgroundColor:
                                    rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                  padding: "4px 12px",
                                }}
                                // className="p-md"
                              >
                                {cellValue ? (
                                  <CheckYesIcon className="checkIconGreen" />
                                ) : (
                                  <CheckNoIcon className="checkIconCancel" />
                                )}
                              </TableCell>
                            );
                          }

                          return (
                            <>
                              {cell.key === staticColumn ? (
                                <TableCell
                                  key={cell.key}
                                  align={"left"}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                    padding: "4px 12px",
                                  }}
                                  className="stickyColumn_left"
                                  onClick={() =>
                                    hasPermission("Onboarding_Admin")
                                      ? handleEdit(row.id)
                                      : () => {}
                                  }
                                >
                                  <MediumTypography
                                    label={
                                      cellValue !== undefined &&
                                      cellValue !== null &&
                                      cellValue !== ""
                                        ? cellValue.toString()
                                        : "-"
                                    }
                                    textColor="#25bafa"
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={cell.key}
                                  align={"left"}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                    padding: "4px 12px",
                                  }}
                                  // className="p-md"
                                >
                                  <MediumTypography
                                    label={
                                      cellValue !== undefined &&
                                      cellValue !== null &&
                                      cellValue !== ""
                                        ? cellValue.toString()
                                        : "-"
                                    }
                                  />
                                </TableCell>
                              )}
                            </>
                          );
                        })}

                        {/* Sticky Last Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                            padding: "4px 12px",
                          }}
                          className="stickyColumn_right"
                        >
                          <Tooltip title="More">
                            <IconButton
                              onClick={(event) => handleClick(event, rowIndex)}
                            >
                              <More />
                            </IconButton>
                          </Tooltip>
                          <Popover
                            anchorEl={anchorEl}
                            open={addEditOpen && selectedRow === rowIndex}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                backgroundColor: "#2B3033",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                hasPermission("Onboarding_Admin")
                                  ? handleEdit(row.id)
                                  : () => {}
                              }
                              style={{ backgroundColor: "2B3033" }}
                              disabled={selectedLanguageCode !== "en"}
                            >
                              <MediumTypography
                                labelId="btn.edit"
                                defaultLabel="Edit"
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                hasPermission("Onboarding_Admin")
                                  ? (setWarningModal(true),
                                    setModalAccountId(row.id))
                                  : () => {}
                              }
                              style={{ backgroundColor: "2B3033" }}
                            >
                              <MediumTypography
                                labelId="planner.deleteText"
                                defaultLabel="Delete"
                              />
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {tableData.length === 0 && (
              <Box
                sx={{
                  height: emptyTableHeight,
                }}
                className="display-flex-center flex__justify__center "
              >
                <MediumTypography
                  labelId="ManageAccounts.emptymsg"
                  defaultLabel="No records available"
                  fontSize="20px"
                  fontWeight={700}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ManageLineItemComponent;
