import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CustomMenu } from "../../../components/formlib/CustomMenu";
import { useEffect, useRef, useState, useMemo, useContext } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import CustomDropdown, {
  OptionType,
} from "../../../components/formlib/CustomDropdown";
import { ReactComponent as DownArrow } from "./../../../assets/images/DownArrow.svg";
import TextInput from "../../../components/formlib/TextInput";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import FileUpload from "../components/UploadFileDragDrop";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import TreeDropdown from "../components/TreeDropDown";
import {
  InitialData,
  DynamicFieldType,
  site,
  siteDetails,
} from "../types/CreateTicket";
import {
  fetchMetaData,
  fetchTicketTypeBySubgroupId,
  fetchFieldByTicketTypeId,
  saveTicket,
  fetchAssestByAssestGroupAssestCatId,
} from "../../../api/createTicket/CreateTicketAPI";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import FormikErrorOnlyComponent from "../../../components/formlib/FormikErrorOnlyComponent";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import { getOptions, getProcessedFiles } from "../../../utils/commonFunctions";
import CustomRichText from "../../../components/formlib/CustomRichText";
const getOverridenTemplateFields = (
  templateFields: DynamicFieldType[]
): DynamicFieldType[] => {
  return templateFields.map((item) => {
    let showInFormField = item.showInForm;
    let isRequiredField = item.isRequired;
    let isDisabledField = false;

    if (isRequiredField) {
      showInFormField = true;
    }

    if (item.isMandatory) {
      showInFormField = true;
      isRequiredField = true;
    }

    if (item.editableOn === "NONE" || item.editableOn === "DETAIL") {
      showInFormField = false;
      isRequiredField = false;
      isDisabledField = true;
    }

    return {
      ...item,
      showInForm: showInFormField,
      isRequired: isRequiredField,
      isDisabled: isDisabledField,
    };
  });
};

interface siteProps {
  name: "";
  id: 0;
  subgroups: [
    {
      id: 0;
      name: "";
      assetGroups: [{ id: 0; name: "" }];
    }
  ];
}

let initialDataValue: any = {
  accountOptions: [],
  siteOptions: [],
  subGroupOptions: [],
  typeOptions: [],
  assestCategoryOption: [],
  assestGroupOption: [],
  categoryOptions: [],
  departmentOptions: [],
  assigneeOptions: [],
  priorityOptions: [],
  severityOptions: [],
  attachments: [],
};

interface ModalProps {
  handleClose: () => void;
  createCallback: () => void;
  open: boolean;
}
interface fileProps {
  document: string;
  fileName: string;
  extension: string;
}

let apiUrl = "/ticket/getDataByType?";
let ticketType = "ticketType";
let url = "ticket/save";
const newFiles: fileProps[] = [];

const muiColumnProps = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const fieldWidth = "400px";

const CreateTicketModal = ({
  handleClose,
  createCallback,
  open,
}: ModalProps) => {
  const showSiteDetailsRef = useRef<boolean>(true);
  const [apiData, setApiData] = useState(initialDataValue);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorDesc, setErrorDesc] = useState("");
  const [dynamicFeilds, setDynamicFeilds] = useState<DynamicFieldType[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const openit = Boolean(anchorEl);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [createMessage, setCreateMessage] = useState<string>("");
  const [siteName, setSiteName] = useState("");
  const [siteId, setSiteId] = useState<number>(0);
  const [subGroupId, setSubGroupId] = useState<number>(0);
  const [account, setAccountId] = useState<number>(0);
  const [type, setType] = useState<number>(0);
  const [assestId, setAssest] = useState<number>(0);
  const formikRef = useRef<FormikProps<InitialData>>(null);

  useEffect(() => {
    fetchmetadata();
  }, []);

  const fetchmetadata = () => {
    toggleLoader(true);
    fetchMetaData()
      .then((response: any) => {
        toggleLoader(false);
        if (response) {
          const updatedApiData = {
            ...apiData,
            accountOptions: response.accountSiteMapList,
            // typeOptions: response?.type,
            departmentOptions: response.departmentList,
            priorityOptions: response.priorityList,
            severityOptions: response.severityList,
          };

          let updatedSubGroupValue = 0;

          if (response.accountSiteMapList?.length === 1) {
            setAccountId(response.accountSiteMapList[0].id);
            const siteOptionsData = getSiteOptions(
              response.accountSiteMapList[0].id,
              response.accountSiteMapList
            );
            if (siteOptionsData.length === 1) {
              updatedApiData.siteOptions = siteOptionsData;
              setSiteId(siteOptionsData[0].id);
              const subGroupOptionsData = getSubGroupOptions(
                siteOptionsData[0].id,
                response.accountSiteMapList
              );

              if (subGroupOptionsData.length === 1) {
                updatedApiData.subGroupOptions = subGroupOptionsData;
                setSubGroupId(subGroupOptionsData[0].id);
                updatedApiData.typeOptions = getTicketTypeOptions(
                  subGroupOptionsData[0].id,
                  response.accountSiteMapList
                );
              }
            }
          }

          setApiData(updatedApiData);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };
  const handleNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const createValidationSchema = (dynamicFeildsVal: DynamicFieldType[]) => {
    const validationSchema: Record<string, any> = {};

    dynamicFeildsVal.forEach((field) => {
      if (field.isRequired) {
        switch (field.type) {
          case "number":
            validationSchema[field.code] = Yup.number().required(
              "validation.fieldRequired"
            );
            break;

          case "text":
            validationSchema[field.code] = Yup.string().required(
              "validation.fieldRequired"
            );
            break;

          case "email":
            validationSchema[field.code] = Yup.string()
              .email("validation.invaldiEmail")
              .required("validation.fieldRequired");
            break;

          case "phone_number":
            validationSchema[field.code] = Yup.string()
              .test("is-valid-phone", "validation.phoneNumber", (value) => {
                if (value) {
                  // Check if the number starts with "91"
                  if (value.startsWith("91")) {
                    // Validate that the number is 12 digits long
                    return /^\d{12}$/.test(value);
                  } else {
                    // Validate that the number is at least 6 digits long
                    return /^\d{6,}$/.test(value);
                  }
                }
                return true;
              })
              .required("validation.fieldRequired");
            break;

          case "single_select":
            validationSchema[field.code] = Yup.string().test(
              "custom-required",
              "validation.dropdown",
              (value) => {
                if (Number(value) > 0) {
                  return true;
                }
                return false;
              }
            );
            break;

          case "multiple_select":
            validationSchema[field.code] = Yup.array().required(
              "validation.fieldRequired"
            );
            break;

          default:
            validationSchema[field.code] = Yup.mixed().required(
              "validation.fieldRequired"
            );
            break;
        }
      }
    });

    return Yup.object().shape(validationSchema);
  };

  const getFieldsById = (id: number) => {
    if (id === 0) {
      setDynamicFeilds([]);
    } else {
      fetchFieldByTicketTypeId(ticketType, id, "en")
        .then((response: any) => {
          toggleLoader(false);
          if (response) {
            const overridenTemplateFields = getOverridenTemplateFields(
              response.ticketTemplateList
            );

            overridenTemplateFields.forEach((field: DynamicFieldType) => {
              if (
                field.isVisible === "STANDARD" &&
                field.showInForm === true &&
                field.type === "single_select" &&
                field.customConfig?.defaultOption
              ) {
                setApiData((prevApiData: typeof apiData) => ({
                  ...prevApiData,
                  [field.code]: field.customConfig.defaultOption,
                }));
              }
            });

            setDynamicFeilds(overridenTemplateFields);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          if (isCustomError(error)) {
            const apiError = error as ApiErrorResponse;
            setErrorDesc(apiError.issue[0].diagnostics);
          } else {
            setErrorDesc(error.id);
          }
        });
    }
  };

  const saveCreateTicket = (data: InitialData) => {
    const {
      accountOptions,
      typeOptions,
      departmentOptions,
      categoryOptions,
      subCategotyOptions,
      assigneeOptions,
      subCategoryOptions,
      siteOptions,
      subGroupOptions,
      assestCategoryOption,
      assestGroupOption,
      assetOptions,
      ...updatedData
    } = data;

    const validCodes = new Set(
      dynamicFeilds
        .filter((obj) => obj.isVisible === "STANDARD")
        .map((obj) => obj.code)
    );

    const filteredObject = Object.keys(updatedData).reduce(
      (acc: InitialData, key) => {
        if (key === "customField") {
          const filteredCustomFields = Object.keys(updatedData[key] || {})
            .filter((subKey) => validCodes.has(subKey))
            .reduce((subAcc: InitialData, subKey) => {
              subAcc[subKey] = (updatedData as { [key: string]: any })[key]?.[
                subKey
              ];
              return subAcc;
            }, {});

          if (Object.keys(filteredCustomFields).length > 0) {
            acc[key] = filteredCustomFields;
          }
        } else if (validCodes.has(key) || key === "attachments") {
          acc[key] = updatedData[key];
        }
        return acc;
      },
      {}
    );

    const FinalData: InitialData = {
      ...filteredObject,
      channel: 50,
      type: type,
      subGroup: subGroupId,
      assetGroup: assestId,
    };

    saveTicket(url, FinalData)
      .then((response: any) => {
        toggleLoader(false);
        if (response) {
          const TicketMessage = `Ticket created successfully! Reference: ${response.ticketUniqueId}.`;
          setCreateMessage(TicketMessage);
          setConfirmation(true);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };
  function cleanObject(obj: InitialData) {
    const keysToRemove = Object.keys(obj.customField || {});
    const cleanedObj = { ...obj };
    keysToRemove.forEach((key) => {
      if (key in cleanedObj) {
        delete cleanedObj[key];
      }
    });
    return cleanedObj;
  }
  const getSiteOptions = (accountId: number, accountOptionVal: site[]) => {
    const result = accountOptionVal.find(
      (accountItem) => accountItem.id === Number(accountId)
    );

    return result ? result.sites : [];
  };

  const getSubGroupOptions = (
    siteIdValue: number,
    accountOptionVal: site[]
  ) => {
    const siteData = accountOptionVal
      .flatMap((account) => account.sites)
      .find((site) => site?.id === Number(siteIdValue));

    return siteData?.subgroups ?? [];
  };

  const getTicketTypeOptions = (
    subGroupIdValue: number,
    accountOptionVal: site[]
  ) => {
    const subgroup = accountOptionVal
      .flatMap((account) => account.sites?.flatMap((site) => site.subgroups))
      .find((subgroup) => subgroup?.id === Number(subGroupIdValue));

    return subgroup?.typeList ?? [];
  };

  const getAssetGroupOptions = (
    subGroupId: number,
    accountOptionVal: site[]
  ) => {
    const subGroup = accountOptionVal
      .flatMap((account) => account.sites?.flatMap((site) => site.subgroups))
      .find((subGroupItem) => subGroupItem?.id === Number(subGroupId));

    return subGroup?.assetGroups ?? [];
  };

  const getAssetCategoryOptions = (
    assetGroupId: number,
    accountOptionVal: site[]
  ) => {
    const assetGroup = accountOptionVal
      .flatMap((account) =>
        account.sites?.flatMap((site) =>
          site.subgroups?.flatMap((subgroup) => subgroup.assetGroups)
        )
      )
      .find((assetGroup) => assetGroup?.id === Number(assetGroupId));

    return assetGroup?.assetCategory ?? [];
  };

  return (
    <>
      {confirmation && (
        <ModalPopup
          descriptionText={createMessage}
          descriptionMessage={createMessage}
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          onPositiveClick={() => {
            setConfirmation(false);
            createCallback();
          }}
          positiveButtonLabel="OK"
          positiveButtonLabelId="OkText"
          hideButton={true}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Formik
        validateOnBlur={true}
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={createValidationSchema(dynamicFeilds)}
        onSubmit={async (values) => {
          try {
            const processedFiles = await getProcessedFiles(values.attachments);

            const filteredFields = dynamicFeilds.filter(
              (f: DynamicFieldType) =>
                f.isVisible === "STANDARD" &&
                f.showInForm === true &&
                f.initialFieldType === "CUSTOM"
            );

            const customField: { [key: string]: any } = {};
            Object.keys(values).forEach((key) => {
              if (
                filteredFields.some(
                  (field: DynamicFieldType) => field.code === key
                )
              ) {
                customField[key] = values[key];
              }
            });

            const paramsObj: InitialData = {
              ...values,
              attachments: processedFiles,
              account: account,
              site: siteId,
              type: type,
              channel: 50,
              customField,
            };
            const cleanedData = cleanObject(paramsObj);
            saveCreateTicket(cleanedData);
          } catch (error) {
            console.error("Unable to process files:", error);
            setErrorDesc("Unable to process files");
            setOpenErrorModal(true);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
              sx={{
                "& .MuiPaper-root": {
                  overflow: "hidden !important",
                  maxWidth: "700px",
                },
              }}
            >
              <DialogTitle sx={{ pb: "8px" }}>
                <MediumTypography
                  labelId={"tick.createTicket"}
                  defaultLabel={"tick.createTicket"}
                  textColor="#FFF"
                  fontSize="20px"
                  fontWeight={700}
                  sxProps={{ lineHeight: 1 }}
                />
                <Box style={{ display: "flex", alignItems: "center" }} gap={1}>
                  <MediumTypography
                    labelId={"tick.reqtext"}
                    defaultLabel="tick.reqtext"
                    textColor=" #9FADBC"
                    fontSize="14px"
                    fontWeight={400}
                  />
                  <span style={{ color: "red" }}>*</span>
                </Box>
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <Box
                sx={{
                  flexGrow: 1,
                  ml: "20px",
                  mr: "20px",
                  borderTop: "2px #a6c5e229 solid",
                }}
              ></Box>

              <DialogContent sx={{ minHeight: "365px" }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item {...muiColumnProps}>
                      <Box>
                        <Box mb={1}>
                          <MediumTypography
                            labelId={"text.account"}
                            defaultLabel="Account"
                            className="input-label"
                          />
                        </Box>

                        <CustomDropdown
                          overrideWidth={fieldWidth}
                          disableCreate={true}
                          options={getOptions(
                            values?.accountOptions as OptionType[]
                          )}
                          selectedValue={account}
                          onSelectedValueChange={(value) => {
                            setFieldValue("account", value);
                            setType(0);
                            setSiteName("");
                            setSubGroupId(0);
                            setDynamicFeilds([]);
                            setAssest(0);
                            setSiteId(0);
                            setApiData((prevApiData: typeof apiData) => ({
                              ...prevApiData,
                              typeOptions: [],
                            }));

                            setApiData({
                              ...apiData,
                              siteOptions: getSiteOptions(
                                Number(value),
                                values.accountOptions as site[]
                              ) as siteDetails[],
                            });
                            setAccountId(Number(value));
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <Box>
                        <Box mb={1}>
                          <MediumTypography
                            labelId={"text.site"}
                            defaultLabel="Site"
                            className="input-label"
                          />
                        </Box>

                        <CustomDropdown
                          overrideWidth={fieldWidth}
                          disableCreate={true}
                          disabled={!account}
                          options={getOptions(values?.siteOptions ?? [])}
                          selectedValue={siteId}
                          onSelectedValueChange={(value) => {
                            setApiData({
                              ...apiData,
                              subGroupOptions: getSubGroupOptions(
                                Number(value),
                                values?.accountOptions ?? []
                              ),
                            });
                            setDynamicFeilds([]);
                            setSiteId(Number(value));
                            setSubGroupId(0);
                            setType(0);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <Box>
                        <Box mb={1}>
                          <MediumTypography
                            labelId={"Onboarding.subgroup"}
                            defaultLabel="Sub Group"
                            className="input-label"
                          />
                        </Box>

                        <CustomDropdown
                          overrideWidth={fieldWidth}
                          disableCreate={true}
                          disabled={!siteId}
                          options={getOptions(values?.subGroupOptions ?? [])}
                          selectedValue={subGroupId}
                          onSelectedValueChange={(value) => {
                            setApiData({
                              ...apiData,
                              typeOptions: getTicketTypeOptions(
                                Number(value),
                                values?.accountOptions ?? []
                              ),
                            });
                            setDynamicFeilds([]);
                            setSubGroupId(Number(value));
                            setType(0);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <Box>
                        <Box mb={1}>
                          <MediumTypography
                            labelId={"text.tickettype"}
                            defaultLabel="Ticket Type"
                            className="input-label"
                          />
                        </Box>

                        <CustomDropdown
                          overrideWidth={fieldWidth}
                          disabled={!subGroupId}
                          disableCreate={true}
                          options={getOptions(
                            values?.typeOptions as OptionType[]
                          )}
                          selectedValue={type}
                          onSelectedValueChange={(value: any) => {
                            setFieldValue("type", value);
                            getFieldsById(Number(value));

                            setType(Number(value));
                            if (Number(value) !== 0) {
                              fetchTicketTypeBySubgroupId(
                                apiUrl,
                                "CATEGORY",
                                "ticketTypeId",
                                value
                              )
                                .then((response: any) => {
                                  toggleLoader(false);
                                  if (response) {
                                    setApiData((prevData: any) => ({
                                      ...prevData,
                                      categoryOptions: response,
                                    }));
                                  }
                                })
                                .catch((error) => {
                                  toggleLoader(false);
                                  setOpenErrorModal(true);
                                  if (isCustomError(error)) {
                                    const apiError = error as ApiErrorResponse;
                                    setErrorDesc(apiError.issue[0].diagnostics);
                                  } else {
                                    setErrorDesc(error.id);
                                  }
                                });
                            }

                            const assetGroupOptions = getAssetGroupOptions(
                              Number(subGroupId),
                              values?.accountOptions ?? []
                            );

                            setApiData((prevData: any) => ({
                              ...prevData,
                              assestGroupOption: assetGroupOptions,
                            }));
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {dynamicFeilds.length > 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      borderTop: "2px #a6c5e229 solid",
                      mt: "24px",
                      mb: "24px",
                    }}
                  ></Box>
                )}

                <Box>
                  <Grid container spacing={2}>
                    {dynamicFeilds.length > 0 && (
                      <Grid item {...muiColumnProps}>
                        <Box mb={1}>
                          <MediumTypography
                            labelId={"StatusText"}
                            defaultLabel="Status"
                            className="input-label"
                          />
                        </Box>
                        <Box
                          display={"inline-block"}
                          className="status-box"
                          bgcolor={"rgba(37, 187, 250, 0.1)"}
                        >
                          <MediumTypography
                            labelId={"newText"}
                            defaultLabel="New"
                            fontSize="12px"
                            textColor={"rgba(37, 187, 250, 1)"}
                          />
                        </Box>
                      </Grid>
                    )}
                    {dynamicFeilds.length > 0 &&
                      dynamicFeilds.map(
                        (field: DynamicFieldType, index: number) =>
                          field.isVisible === "STANDARD" &&
                          field.showInForm === true && (
                            <Grid item {...muiColumnProps} key={index}>
                              <Box>
                                <Box style={{ display: "flex" }} gap={1}>
                                  <MediumTypography
                                    className="input-label"
                                    labelId={field.alias}
                                    defaultLabel={field.alias}
                                  />
                                  {field.isRequired && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                </Box>

                                {(() => {
                                  switch (field.type) {
                                    case "number":
                                    case "text":
                                    case "email":
                                      return (
                                        <>
                                          <TextInput
                                            className="text-input-field"
                                            type={field.type}
                                            variant="outlined"
                                            inputProps={{
                                              readOnly: false,
                                            }}
                                            labelId="common.placeHolderText"
                                            defaultLabelId="--- type here ---"
                                            Value={values[field.code] as string}
                                            handlechange={(value: string) => {
                                              setFieldValue(field.code, value);
                                            }}
                                          />
                                          <FormikErrorOnlyComponent
                                            errors={errors}
                                            field={field.code}
                                          />
                                        </>
                                      );

                                    case "single_select":
                                      const options = getOptions(
                                        field?.customConfig?.options
                                      );
                                      if (field.code === "department") {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.departmentOptions as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as string)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                  if (value === "") {
                                                    setFieldValue(
                                                      "assignee",
                                                      ""
                                                    );
                                                    setFieldValue(
                                                      "assigneeOptions",
                                                      []
                                                    );
                                                  }
                                                  if (value !== "") {
                                                    const assigneeListData =
                                                      values?.departmentOptions?.find(
                                                        (dept: any) =>
                                                          dept.id ===
                                                          Number(value)
                                                      )?.assigneeList ?? [];
                                                    setFieldValue(
                                                      "assigneeOptions",
                                                      assigneeListData
                                                    );
                                                  }
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      }
                                      if (field.code === "asset") {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.assetOptions as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as string)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else if (field.code === "category") {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.categoryOptions as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as string)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                  if (value === "") {
                                                    setFieldValue(
                                                      "subCategory",
                                                      ""
                                                    );
                                                    setFieldValue(
                                                      "subCategoryOptions",
                                                      []
                                                    );
                                                  }
                                                  if (value !== "") {
                                                    fetchTicketTypeBySubgroupId(
                                                      apiUrl,
                                                      "SUBCATEGORY",
                                                      "categoryId",
                                                      Number(value)
                                                    )
                                                      .then((response: any) => {
                                                        toggleLoader(false);
                                                        if (response) {
                                                          setFieldValue(
                                                            "subCategoryOptions",
                                                            response
                                                          );
                                                        }
                                                      })

                                                      .catch((error) => {
                                                        toggleLoader(false);
                                                        setOpenErrorModal(true);
                                                        if (
                                                          isCustomError(error)
                                                        ) {
                                                          const apiError =
                                                            error as ApiErrorResponse;
                                                          setErrorDesc(
                                                            apiError.issue[0]
                                                              .diagnostics
                                                          );
                                                        } else {
                                                          setErrorDesc(
                                                            error.id
                                                          );
                                                        }
                                                      });
                                                  }
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else if (field.code === "subCategory") {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.subCategoryOptions as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as number)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else if (field.code === "assetGroup") {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.assestGroupOption as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as number)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                  if (value === "") {
                                                    setFieldValue(
                                                      "assetCategory",
                                                      ""
                                                    );
                                                    setFieldValue(
                                                      "assestCategoryOption",
                                                      []
                                                    );
                                                    setFieldValue("asset", "");
                                                    setFieldValue(
                                                      "assetOptions",
                                                      []
                                                    );
                                                  }
                                                  if (value !== "") {
                                                    setFieldValue(
                                                      "assestCategoryOption",
                                                      getAssetCategoryOptions(
                                                        Number(value),
                                                        values?.accountOptions ??
                                                          []
                                                      )
                                                    );
                                                    setFieldValue(
                                                      "assetCategory",
                                                      ""
                                                    );

                                                    setFieldValue("asset", "");
                                                    setFieldValue(
                                                      "assetOptions",
                                                      []
                                                    );
                                                  }
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else if (
                                        field.code === "assetCategory"
                                      ) {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.assestCategoryOption as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as number)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                  if (value === "") {
                                                    setFieldValue("asset", "");
                                                    setFieldValue(
                                                      "assetOptions",
                                                      []
                                                    );
                                                  }
                                                  if (value !== "") {
                                                    fetchAssestByAssestGroupAssestCatId(
                                                      apiUrl,
                                                      "ASSET",
                                                      Number(value),
                                                      assestId
                                                    )
                                                      .then((response: any) => {
                                                        toggleLoader(false);
                                                        if (response) {
                                                          setFieldValue(
                                                            "assetOptions",
                                                            response
                                                          );
                                                        }
                                                      })
                                                      .catch((error) => {
                                                        toggleLoader(false);
                                                        setOpenErrorModal(true);
                                                        if (
                                                          isCustomError(error)
                                                        ) {
                                                          const apiError =
                                                            error as ApiErrorResponse;
                                                          setErrorDesc(
                                                            apiError.issue[0]
                                                              .diagnostics
                                                          );
                                                        } else {
                                                          setErrorDesc(
                                                            error.id
                                                          );
                                                        }
                                                      });
                                                  }
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else if (field.code === "assignee") {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                showImage={true}
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(
                                                  values?.assigneeOptions as OptionType[]
                                                )}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as string)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else if (
                                        field.code === "severity" ||
                                        field.code === "priority"
                                      ) {
                                        let newOptions = [];
                                        if (field.code === "severity") {
                                          newOptions = values?.severityOptions;
                                        } else if (field.code === "priority") {
                                          newOptions = values?.priorityOptions;
                                        }

                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                showIcon={true}
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={getOptions(newOptions)}
                                                selectedValue={
                                                  values[field.code] !== null
                                                    ? (values[
                                                        field.code
                                                      ] as string)
                                                    : undefined
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  setFieldValue(
                                                    field.code,
                                                    value
                                                  );
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      } else {
                                        if (Array.isArray(options)) {
                                          return (
                                            <>
                                              <CustomDropdown
                                                overrideWidth={fieldWidth}
                                                disableCreate={true}
                                                options={options}
                                                selectedValue={
                                                  field.customConfig
                                                    ?.defaultOption
                                                }
                                                onSelectedValueChange={(
                                                  value
                                                ) => {
                                                  field.customConfig.defaultOption =
                                                    Number(value);
                                                  setFieldValue(
                                                    field.code,
                                                    field.customConfig
                                                      .defaultOption
                                                  );
                                                }}
                                              />
                                              <FormikErrorOnlyComponent
                                                errors={errors}
                                                field={field.code}
                                              />
                                            </>
                                          );
                                        }
                                      }
                                      break;

                                    case "rich_text":
                                      return (
                                        <Box className="ticket-quill-container ticket-rich-text-box">
                                          <CustomRichText
                                            labelId="common.placeHolderText"
                                            defaultLabelId="--- type here ---"
                                            data={String(
                                              values[field.code] ?? ""
                                            )}
                                            onChangeCallBack={(e) =>
                                              setFieldValue(field.code, e)
                                            }
                                            readOnly={false}
                                          />
                                          <FormikErrorOnlyComponent
                                            errors={errors}
                                            field={field.code}
                                          />
                                        </Box>
                                      );
                                    case "phone_number":
                                      return (
                                        <>
                                          <PhoneNumberInput
                                            value={values[field.code]}
                                            onChange={(value: string) => {
                                              setFieldValue(field.code, value);
                                            }}
                                            labelId="common.placeHolderText"
                                            defaultLabelId="--- type here ---"
                                            disabled={false}
                                            style={{ width: fieldWidth }}
                                          />
                                          <FormikErrorOnlyComponent
                                            errors={errors}
                                            field={field.code}
                                          />
                                        </>
                                      );
                                    case "multiple_select":
                                      const optionmulti = getOptions(
                                        field?.customConfig?.options
                                      );

                                      if (Array.isArray(optionmulti)) {
                                        return (
                                          <>
                                            <CustomDropdown
                                              overrideWidth={fieldWidth}
                                              multiple={true}
                                              disableCreate={true}
                                              options={optionmulti}
                                              selectedValue={
                                                values[field.code] !== null
                                                  ? (values[
                                                      field.code
                                                    ] as string)
                                                  : undefined
                                              }
                                              onSelectedValueChange={(
                                                value
                                              ) => {
                                                setFieldValue(
                                                  field.code,
                                                  value
                                                );
                                              }}
                                              overrideHeight="auto"
                                            />

                                            <FormikErrorOnlyComponent
                                              errors={errors}
                                              field={field.code}
                                            />
                                          </>
                                        );
                                      }
                                      break;
                                    case "date_time":
                                      return (
                                        <>
                                          <DateTimePickerComponent
                                            value={
                                              values[field.code]
                                                ? dayjs(
                                                    values[field.code] as string
                                                  )
                                                : null
                                            }
                                            labelid="accounts.selectPlaceholder"
                                            defaultlabelid="--- select ---"
                                            handlechange={(date: Dayjs) => {
                                              const formattedText = date.format(
                                                "YYYY-MM-DD HH:mm:ss"
                                              );
                                              setFieldValue(
                                                field.code,
                                                formattedText
                                              );
                                            }}
                                            inputFormat="DD MMM YYYY HH:mm:ss"
                                            width={fieldWidth}
                                            textWidth={fieldWidth}
                                            inputClassName="text-input-field"
                                          />
                                          <FormikErrorOnlyComponent
                                            errors={errors}
                                            field={field.code}
                                          />
                                        </>
                                      );
                                    case "date":
                                      return (
                                        <>
                                          <DateTimePickerComponent
                                            dateOnly={true}
                                            value={
                                              values[field.code]
                                                ? dayjs(
                                                    values[field.code] as string
                                                  )
                                                : null
                                            }
                                            labelid="accounts.selectPlaceholder"
                                            defaultlabelid="--- select ---"
                                            handlechange={(date: Dayjs) => {
                                              const formattedText =
                                                date.format("YYYY-MM-DD");
                                              setFieldValue(
                                                field.code,
                                                formattedText
                                              );
                                            }}
                                            inputFormat="DD MMM YYYY"
                                            width={fieldWidth}
                                            textWidth={fieldWidth}
                                            inputClassName="text-input-field"
                                          />
                                          <FormikErrorOnlyComponent
                                            errors={errors}
                                            field={field.code}
                                          />
                                        </>
                                      );
                                    default:
                                      return "";
                                  }
                                })()}
                              </Box>
                            </Grid>
                          )
                      )}
                    {dynamicFeilds.length > 0 && (
                      <Grid item {...muiColumnProps}>
                        <Box mb={1}>
                          <MediumTypography
                            labelId={"ticket.attachments"}
                            defaultLabel="Attachments"
                            className="input-label"
                          />
                        </Box>
                        <FileUpload
                          files={values.attachments}
                          onFilesChange={(files) => {
                            setFieldValue("attachments", files);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </DialogContent>

              <Box
                sx={{
                  flexGrow: 1,
                  ml: "20px",
                  mr: "20px",
                  borderTop: "2px #a6c5e229 solid",
                }}
              ></Box>

              <DialogActions
                className="dialogActions"
                sx={{ pt: "12px", pb: "12px" }}
              >
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-cancel btn-ticket-text mr-md"
                        variantType="contained"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-ticket"
                        variantType="contained"
                        defaultLabelId={"Add"}
                        labelId={"btn.add"}
                        onClick={() => {
                          handleSubmit();
                        }}
                        sxProps={{ mr: "10px !important" }}
                        disabled={
                          dynamicFeilds.length === 0 ||
                          !account ||
                          !siteId ||
                          !subGroupId ||
                          !type
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateTicketModal;
