import { useContext, useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import useAvailableHeight from "../../utils/useAvailableHeight";
import DisplayFieldIcon from "./components/DisplayFieldIcon";
import MediumTypography from "../../components/formlib/MediumTypography";
import SearchBox from "../../components/formlib/SearchBox";
import { ReactComponent as DragIcon } from "../../assets/images/DragIcon.svg";
import { ReactComponent as Add } from "../../assets/images/open.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as Hierarchy } from "../../assets/images/hierarchy.svg";
import { ReactComponent as More } from "../../assets/images/ThreeDots.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useAvailableWidth from "../../utils/useAvailableWidth";
import { Template } from "./types/formBuilder";
import AddTicketTypeForm from "./ticketType/AddTicketTypeForm";
import TemplateFormFields from "./ticketType/TemplateFormFields";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { isCustomError } from "../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../api/UserApi/User";
import { useLocation } from "react-router-dom";
import {
  fetchTemplateById,
  saveDataTemplateById,
  fetchTemplateByIdByCustomField,
} from "../../api/ticketing/TicketingAPI";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

interface TicketTemplateResponse {
  ticketTemplateList: Template[];
  update: boolean;
}

export const getOverridenTemplateFields = (
  templateFields: Template[]
): Template[] => {
  return templateFields.map((item) => {
    let showInFormField = item.showInForm;
    let isRequiredField = item.isRequired;
    let isDisabledField = false;

    if (isRequiredField) {
      showInFormField = true;
    }

    if (item.isMandatory) {
      showInFormField = true;
      isRequiredField = true;
      isDisabledField = true;
    }

    if (item.editableOn === "NONE") {
      showInFormField = false;
      isRequiredField = false;
      isDisabledField = true;
    }

    return {
      ...item,
      showInForm: showInFormField,
      isRequired: isRequiredField,
      isDisabled: isDisabledField,
    };
  });
};

const TicketFormBuilder = () => {
  const navigate = useNavigate();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const availableWidth = useAvailableWidth(100);
  const [requiredFields, setRequiredFields] = useState<Template[]>([]);
  const [optionalFields, setOptionalFields] = useState<Template[]>([]);
  const [initialOptional, setInitialOptional] = useState<Template[]>([]);
  const availableHeight = useAvailableHeight(300);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isClickedCode, setIsClickedCode] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [openCloseSVG, setOpenCloseSVG] = useState<boolean>(false);
  const [tickID, setTickId] = useState<number | null>(null);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const location = useLocation();
  const [refreshTicketTypes, setRefreshTicketTypes] = useState<boolean>(false);

  useEffect(() => {
    if (
      typeof location.state?.id === "number" &&
      location.state?.numbers?.length > 0
    ) {
      getRecordsByCustomFieldId();
    } else {
      getRecords();
    }
  }, [tickID, location.state?.id, location.state?.numbers]);

  const getRecordsByCustomFieldId = (language: string = "en") => {
    toggleLoader(true);

    fetchTemplateByIdByCustomField<TicketTemplateResponse>(
      location.state?.id,
      language,
      location.state?.numbers
    )
      .then((response: TicketTemplateResponse) => {
        toggleLoader(false);
        if (response) {
          const overridenTemplateFields = getOverridenTemplateFields(
            response.ticketTemplateList
          );

          setRequiredFields(
            overridenTemplateFields.filter(
              (fields: Template) => fields.isVisible === "STANDARD"
            )
          );
          setOptionalFields(
            overridenTemplateFields.filter(
              (fields: Template) =>
                fields.isVisible === "OPTIONAL" || fields.isVisible === "CUSTOM"
            )
          );

          setInitialOptional(
            overridenTemplateFields.filter(
              (fields: Template) => fields.isVisible === "OPTIONAL"
            )
          );
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const getRecords = (language: string = "en") => {
    toggleLoader(true);
    if (tickID !== null) {
      fetchTemplateById<TicketTemplateResponse>(tickID, language)
        .then((response: TicketTemplateResponse) => {
          toggleLoader(false);
          if (response) {
            const overridenTemplateFields = getOverridenTemplateFields(
              response.ticketTemplateList
            );

            setRequiredFields(
              overridenTemplateFields.filter(
                (fields: Template) => fields.isVisible === "STANDARD"
              )
            );
            setOptionalFields(
              overridenTemplateFields.filter(
                (fields: Template) =>
                  fields.isVisible === "OPTIONAL" ||
                  fields.isVisible === "CUSTOM"
              )
            );

            setInitialOptional(
              overridenTemplateFields.filter(
                (fields: Template) =>
                  fields.isVisible === "OPTIONAL" ||
                  fields.isVisible === "CUSTOM"
              )
            );
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          if (isCustomError(error)) {
            const apiError = error as ApiErrorResponse;
            setErrorDesc(apiError.issue[0].diagnostics);
          } else {
            setErrorDesc(error.id);
          }
        });
    }
  };

  const setSelectedTicketID = (id: number, toggle: boolean) => {
    setTickId(id);
    setIsToggled(toggle);
  };
  const resetRefreshTicketTypes = () => {
    setRefreshTicketTypes(false);
  };
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value === "") {
      setOptionalFields(initialOptional);
    } else {
      const lowercasedQuery = value.toLowerCase();
      const searchOption = optionalFields?.filter((item) =>
        item.name.toLowerCase().includes(lowercasedQuery)
      );
      setOptionalFields(searchOption);
    }
  };

  const handleOpenFeilds = (type: string, code: string) => {
    if (isClickedCode === code) {
      setOpenCloseSVG(!openCloseSVG);
      setIsClickedCode(code);
      setIsVisible(!isVisible);
    } else {
      setOpenCloseSVG(true);
      setIsClickedCode(code);
      setIsVisible(true);
    }
  };

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    const sourceIdParts = draggableId.split("-");
    const sourceFieldType = sourceIdParts[0];

    if (
      sourceFieldType === "STANDARD" &&
      destination.droppableId === "optionalFields"
    )
      return;

    const sourceList =
      source.droppableId === "requiredFields" ? requiredFields : optionalFields;
    const destinationList =
      destination.droppableId === "requiredFields"
        ? requiredFields
        : optionalFields;

    const item = sourceList[source.index];
    sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, item);

    const updatedRequiredArray = requiredFields.map((item_standard) => ({
      ...item_standard,
      isVisible: "STANDARD",
    }));

    setRequiredFields([...updatedRequiredArray]);

    const updatedOptionalArray = optionalFields.map((item_optional) => ({
      ...item_optional,
      isVisible: "OPTIONAL",
    }));
    setOptionalFields([...updatedOptionalArray]);
  };

  const handleScreenChange = () => {
    saveTemplate(0);
    navigate("/master-data-management", {
      state: {
        pageKey: "CustomFieldScreen",
        toggle: isToggled,
        id: tickID,
        numbers: [],
      },
    });
  };

  const saveTemplate = (val: number) => {
    const final = requiredFields.map((item, i) => {
      return { ...item, sequenceId: i + 1 };
    });

    saveDataTemplateById(tickID, "en", final)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          navigate("/master-data-management", {
            state: {
              origin: "formbuilder",
              toggle: location.state?.toggle,
              id: tickID,
              numbers: [],
            },
          });
          setRefreshTicketTypes(!refreshTicketTypes);
          setIsVisible(!isVisible);
          if (val === 1) {
            getRecords();
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
  };

  const updateTemplateData = (data: any) => {
    const updatedArray = requiredFields.map((item, i) =>
      item.code === data.code ? { ...data } : item
    );
    setRequiredFields(updatedArray);
  };

  const updatedRequiredFields =
    requiredFields?.filter((item) => item.editableOn !== "NONE") || [];

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box
        className="flex__"
        sx={{
          position: "relative",
          justifyContent: "center",
          flexGrow: 1,
          height: availableHeight + 100,
          width: availableWidth - 320,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AddTicketTypeForm
                selectedTicketID={setSelectedTicketID}
                refreshTicketTypes={refreshTicketTypes}
                resetRefreshTicketTypes={resetRefreshTicketTypes}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Box>
                <Box
                  mb={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box>
                    <MediumTypography
                      labelId={"Required.Fields"}
                      defaultLabel="Required Fields"
                      fontWeight={700}
                      fontSize="16px"
                      textColor="#FFFFFF"
                    />
                  </Box>
                  <Box className="hier_container">
                    <Hierarchy />
                    <MediumTypography
                      labelId={"Edit.Workflow"}
                      defaultLabel="Edit Workflow"
                      fontWeight={400}
                      fontSize="16px"
                    />
                    <More />
                  </Box>
                </Box>

                <Droppable droppableId="requiredFields">
                  {(providedReq) => (
                    <Box
                      ref={providedReq.innerRef}
                      {...providedReq.droppableProps}
                      sx={{
                        height: availableHeight + 30,
                        overflowY: "auto",
                      }}
                      className="scroll-on-hover"
                    >
                      {updatedRequiredFields?.map((field, i) => {
                        return (
                          <Draggable
                            key={field.code}
                            draggableId={`${field.initialFieldType}-${field.code}`}
                            index={i}
                          >
                            {(provided) => (
                              <Box
                                className="static_feild_item "
                                key={i}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box className="cutsom_list_main_box">
                                  <Box
                                    display="flex"
                                    justifyContent={"space-between"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleOpenFeilds(field.type, field.code)
                                    }
                                  >
                                    <Box
                                      className="flex__"
                                      sx={{
                                        gap: 2,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box pl={1}>
                                        <DragIcon />
                                      </Box>

                                      <Box className="drag_icon_box">
                                        <DisplayFieldIcon type={field.type} />
                                      </Box>
                                      <Box>
                                        <MediumTypography
                                          labelId={field.name}
                                        />
                                      </Box>
                                    </Box>
                                    <Box
                                      className="flex__"
                                      sx={{
                                        gap: "8px",
                                        alignItems: "center",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      {field.isRequired && (
                                        <Box className="required_text">
                                          <Box>
                                            <MediumTypography
                                              labelId={"text.required"}
                                              defaultLabel="REQUIRED"
                                              textColor="#9FADBC"
                                              fontWeight={500}
                                              fontSize="12px"
                                              sxProps={{
                                                lineHeight: "12px",
                                                letterSpacing: "0.01em",
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      )}

                                      <IconButton
                                        onClick={() =>
                                          handleOpenFeilds(
                                            field.type,
                                            field.code
                                          )
                                        }
                                      >
                                        {isClickedCode === field.code &&
                                        openCloseSVG ? (
                                          <Add />
                                        ) : (
                                          <Close />
                                        )}
                                      </IconButton>
                                    </Box>
                                  </Box>

                                  {isVisible &&
                                    isClickedCode === field.code && (
                                      <Box>
                                        <Divider className="big-divider" />
                                        <TemplateFormFields
                                          updateTemplate={updateTemplateData}
                                          formData={field}
                                          handleCancel={(
                                            type: string,
                                            id: number | undefined | string
                                          ) =>
                                            id &&
                                            handleOpenFeilds(type, field.code)
                                          }
                                        />
                                      </Box>
                                    )}
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        );
                      })}
                      {providedReq.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <Box
                sx={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  backgroundColor: "#1D2125",
                }}
              >
                <Box className="flex__" sx={{ flexDirection: "column" }}>
                  <Box mt={2} mb={2}>
                    <SearchBox
                      labelId={"default.Search"}
                      defaultlabel="Search"
                      backgroundColor="#22272B"
                      sxProps={{
                        width: "100%",
                        minWidth: "75px",
                      }}
                      value={searchValue}
                      onChange={handleSearchChange}
                      cancelRequired={true}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      borderTop: "0.5px grey solid",
                    }}
                  ></Box>
                  <Box sx={{ mr: "10px", ml: "10px" }}>
                    <MediumTypography
                      sxProps={{
                        color: "grey",
                        fontSize: 12,
                      }}
                      defaultLabel={"text.optional"}
                      labelId={"text.optional"}
                    />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      borderTop: "2px #666 solid",
                    }}
                  ></Box>
                </Box>
                <Droppable droppableId="optionalFields">
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        height: availableHeight - 90,
                        overflowY: "auto",
                      }}
                      className="scroll-on-hover"
                    >
                      {optionalFields?.map((field, i) => (
                        <Draggable
                          key={field.code}
                          draggableId={`${field.initialFieldType}-${field.code}`}
                          index={i}
                        >
                          {(provided) => (
                            <Box
                              className="flex_  static_feild_item"
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box className="optional_field_item_list">
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box sx={{ width: "24px", height: "24px" }}>
                                    <DragIcon />
                                  </Box>

                                  <Box className="display_field_icon">
                                    <DisplayFieldIcon type={field.type} />
                                  </Box>
                                  <Box sx={{ paddingLeft: "10px" }}>
                                    <MediumTypography label={field.name} />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
                <Box pt={2} pb={2}>
                  <MediumTypography
                    defaultLabel={"cant.find"}
                    labelId={"cant.find"}
                    textColor="#B6C2CF"
                  />
                  <Box>
                    <MediumTypography
                      defaultLabel={"create.field"}
                      labelId={"create.field"}
                      textColor="#B6C2CF"
                      sxProps={{ cursor: "pointer", color: "#25BAFA" }}
                      onClick={handleScreenChange}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DragDropContext>
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: 30,
          top: "auto",
          bottom: 70,
        }}
      >
        <Box>
          <ButtonComponent
            className="btn-primary btn-ticket"
            variantType="contained"
            labelId={"btn.save"}
            onClick={() => {
              saveTemplate(1);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default TicketFormBuilder;
