import { ManageAccountType } from "../../pages/MasterDataManagement/types/accountTypes";
import { ManageAnomalyType } from "../../pages/MasterDataManagement/types/anomalyTypes";
import { AssetCategoryData } from "../../pages/MasterDataManagement/types/assetCategoryTypes";
import { ChecklistLineItemRecordsType } from "../../pages/MasterDataManagement/types/checklistLineItemTypes";
import { ChecklistRecordsType } from "../../pages/MasterDataManagement/types/checklistTypes";
import { SkillRecordsType } from "../../pages/MasterDataManagement/types/skillTypes";
import { ManageMapChecklistRecordsType } from "../../pages/MasterDataManagement/types/mapChecklistTypes";
import {
  InventoryData,
  ToolsData,
} from "../../pages/MasterDataManagement/types/inventoryTypes";

import { CommonOptionType } from "../../utils/type";
import { ApiMessage, ApiResponseHandler } from "../ApiResponseHandler";
import WorkFlowConfig from "../WorkFlowConfig";
import { PermitData } from "../../pages/MasterDataManagement/types/permitTypes";
import { FrequencyRecordsType } from "../../pages/MasterDataManagement/types/frequencyTypes";
import { FetchAllPermitLineItemType } from "../../pages/MasterDataManagement/types/permitLineItemTypes";
import { ReasonForDeviationData } from "../../pages/MasterDataManagement/types/reasonForDeviation";
import UserApiClient from "../UserApi";

import { responseType } from "../../pages/MasterDataManagement/types/rolesAndPermission";
import WorkOrderConfig from "../WorkOrderConfig";
export interface ValidateApiResponse {
  exportBeanList: MasterDataTypes[];
  errorMessages: string[];
}

export type MasterDataTypes =
  | ManageAccountType
  | AssetCategoryData
  | ManageAnomalyType
  | InventoryData
  | ChecklistRecordsType
  | ManageMapChecklistRecordsType
  | ChecklistLineItemRecordsType
  | SkillRecordsType
  | PermitData
  | FrequencyRecordsType
  | ToolsData
  | FetchAllPermitLineItemType
  | ReasonForDeviationData;

export const fetchAllDataApi = async <T>(
  uri: string,
  language: string,
  filterQuery: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/fetchAll?languageCode=${language}&${filterQuery}`
    )
  );
};

export const fetchAllDataRolePermissionApi = async <T>(
  uri: string
): Promise<T> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().get<T>(`/rolePermissions/fetchAll`)
  );
};

export const saveDataRolePermissionApi = async <T>(
  uri: string,
  type: string,
  param: T
) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<T, responseType>(
      `${uri}/save?type=${type}`,
      param
    )
  );
};

export const saveMapdataRolePermissionApi = async <T>(
  uri: string,
  param: T
) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<T, T>(`${uri}/saveMap`, param)
  );
};

export const updateDataRolePermissionApi = async <T>(
  uri: string,
  type: string,
  id: number,
  name: string
) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance()
      .put<T>(`${uri}/${id}/update?type=${type}&name=${name}
`)
  );
};

export const deleteDataRolePermissionApi = async (
  uri: string,
  id: number,
  type: string
) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().delete(`${uri}/${id}/delete?type=${type}`)
  );
};

export const fetchDataApi = async <T>(
  uri: string,
  id?: number,
  languageCode?: string
): Promise<T> => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().get(`${uri}/${id}/fetch`)
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(`${uri}/metadata`)
  );
};

export const saveDataApi = async <T>(
  uri: string,
  params: T,
  isVersionRequired?: boolean,
  id?: number
) => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().put<T, T>(
        `${uri}/${id}/update?isVersionRequired=${isVersionRequired}`,
        params
      )
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post<T, T>(`${uri}/save`, params)
  );
};

export const customUpdateDataApi = async <T>(fullUri: string, params: T) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().put<T, T>(fullUri, params)
  );
};

export const customSaveDataApi = async <T>(fullUri: string, params: T) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post<T, T>(fullUri, params)
  );
};

export const deleteDataApi = async (uri: string, id: number) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().delete(`${uri}/${id}/delete`)
  );
};

export const uploadCSVFileApi = async (
  fileName: string,
  language: string,
  params: MasterDataTypes[],
  name: string,
  isVersionRequired: boolean
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${name}/import?languageCode=${language}&fileName=${fileName}&isVersionRequired=${isVersionRequired}`,
      params
    )
  );
};

export const validateCSVFileApi = async (
  csvFile: File | null,
  language: string,
  name: string
): Promise<ValidateApiResponse> => {
  const formData = new FormData();

  if (csvFile !== null) {
    formData.append(
      "file",
      new File([csvFile], csvFile.name.toLowerCase(), {
        type: "text/csv",
      })
    );
  } else {
    formData.append("file", "");
  }
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${name}/validateFile?languageCode=${language}`,
      formData
    )
  );
};

export const createDropdownOptionApi = async (
  fieldType: string,
  fieldName: string,
  option: string
): Promise<CommonOptionType[]> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `autoSaveFields?field=${fieldType}&${fieldName}=${option}`
    )
  );
};

export const createAdvancedDropdownOptionApi = async <T>(
  fieldType: string,
  fieldName: string,
  option: string,
  fieldName2?: string,
  option2?: string
): Promise<T[]> => {
  let option2Param = "";
  if (fieldName2 && option2) {
    option2Param = `&${fieldName2}=${option2}`;
  }
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `autoSaveFields?field=${fieldType}&${fieldName}=${option}${option2Param}`
    )
  );
};

export const uploadTemplate = async (
  jasperFile: File | null
): Promise<CommonOptionType[]> => {
  const formData = new FormData();
  const type = "JASPER";

  if (jasperFile !== null) {
    formData.append(
      "file",
      new File([jasperFile], jasperFile.name.toLowerCase(), {
        type: "application/octet-stream",
      })
    );
  } else {
    formData.append("file", "");
  }
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `upload/${type}/template`,
      formData
    )
  );
};
