import { Box } from "@mui/material";
import CustomDatePicker from "../../components/formlib/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Available } from "../../assets/images/Available.svg";
import { ReactComponent as Noshow } from "../../assets/images/Noshow.svg";
import { ReactComponent as Weekoff } from "../../assets/images/Weekoff.svg";
import { ReactComponent as Timeoff } from "../../assets/images/TimeOffIcon.svg";
import MediumTypography from "../../components/formlib/MediumTypography";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./ShiftPlanner.css";
import DayShiftDetails, { ShiftMonthlyDetails } from "./DayShiftDetails";
import { EventInput } from "@fullcalendar/core";
import { useNavigate } from "react-router-dom";
import interactionPlugin from "@fullcalendar/interaction";
import {
  DropdownContext,
  LoaderContext,
  LoaderContextType,
} from "../../layouts/appSidebar";
import { getshiftPlannerDetails } from "../../api/ShiftPlanning/ShiftPlanning";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { VIEW_SHIFTS } from "../../routes/Routing";
import { handleError } from "../../utils/commonFunctions";

const ShiftMonthlyPlanner = () => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [dayGridData, setDayGridData] = useState<ShiftMonthlyDetails[]>([]);
  const calendarRef = useRef<FullCalendar>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const context = useContext(DropdownContext);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const navigate = useNavigate();
  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi && selectedMonth) {
      calendarApi.gotoDate(selectedMonth);
    }
  }, [selectedMonth]);

  useEffect(() => {
    getMonthDetails();
  }, [context.selectedSubgroupId, selectedMonth]);

  const getMonthDetails = () => {
    const month = dayjs(selectedMonth).format("YYYY-MM-DD");
    toggleLoader(true);
    getshiftPlannerDetails(context.selectedSubgroupId, month)
      .then((response: ShiftMonthlyDetails[]) => {
        toggleLoader(false);
        setDayGridData(response);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const events: EventInput[] = dayGridData.map((item) => ({
    id: item.date,
    start: item.date,
    extendedProps: {
      data: item,
      date: item.date,
    },
  }));
  const handleDateClick = (arg: any) => {
    // Get the clicked date and the first and last days of the selected month
    const clickedDate = dayjs(arg.date);
    const startOfMonth = dayjs(selectedMonth).startOf("month");
    const endOfMonth = dayjs(selectedMonth).endOf("month");

    // Check if the clicked date is within the selected month
    if (clickedDate.isBefore(startOfMonth) || clickedDate.isAfter(endOfMonth)) {
      // If not, return without doing anything
      return;
    }

    // If the clicked date is within the selected month, navigate to the shifts view
    navigate(VIEW_SHIFTS, {
      state: {
        date: clickedDate.format("YYYY-MM-DD"),
      },
    });
  };

  const renderEventContent = (arg: any) => {
    const { data, date } = arg.event.extendedProps;

    return (
      <Box className="width__100 position__relative">
        <Box
          className="flex__ width__100 cursor__pointer"
          onClick={() => handleDateClick({ date })}
        >
          <DayShiftDetails
            data={data}
            subGroupId={context.selectedSubgroupId}
            getCallBack={getMonthDetails}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box component="main">
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box component={"section"} className="pt-xlg ">
        <Box className={"flex__"} sx={{ justifyContent: "space-between" }}>
          <CustomDatePicker
            date={dayjs(selectedMonth)}
            disableFutureDate={false}
            hideIcon={true}
            onDateSelect={(newDate: Dayjs) => {
              setSelectedMonth(newDate.toDate());
            }}
            onYearChange={(newDate: Dayjs) => {
              setSelectedMonth(newDate.toDate());
            }}
            inputFormat="MMMM YYYY"
            views={["month", "year"]}
            leftArrowRequired={true}
            textWidth="100%"
            rightArrowRequired={true}
            alignCenter={true}
          />
          <Box
            sx={{
              backgroundColor: "rgba(161, 189, 217, 0.08)",
              borderRadius: "3px",
            }}
            className="flex__ align__items__center pl-md pr-md"
          >
            <Available style={{ width: "16px", height: "16px" }} />
            <MediumTypography
              labelId="AvailableText"
              defaultLabel="Available"
              className="ml-sm"
              fontSize="12px"
            />
            <Box
              sx={{
                borderRight: "1px solid #626F86",
                width: "2px",
                height: "25px",
              }}
              className="ml-sm mr-sm"
            />
            <Noshow style={{ width: "16px", height: "16px" }} />
            <MediumTypography
              labelId="noshowText"
              defaultLabel="No Show"
              className="ml-sm"
              fontSize="12px"
            />
            <Box
              sx={{
                borderRight: "1px solid #626F86",
                width: "2px",
                height: "25px",
              }}
              className="ml-sm mr-sm"
            />
            <Weekoff style={{ width: "16px", height: "16px" }} />
            <MediumTypography
              labelId="weekOffText"
              defaultLabel="Week Off"
              className="ml-sm"
              fontSize="12px"
            />
            <Box
              sx={{
                borderRight: "1px solid #626F86",
                width: "2px",
                height: "25px",
              }}
              className="ml-sm mr-sm"
            />
            <Timeoff style={{ width: "16px", height: "16px" }} />
            <MediumTypography
              labelId="TimeOffText"
              defaultLabel="Time Off"
              className="ml-sm"
              fontSize="12px"
            />
          </Box>
        </Box>
        {dayGridData && (
          <Box id="monthlyPlanner" className="pt-lg">
            <FullCalendar
              aspectRatio={2.5}
              plugins={[interactionPlugin, dayGridPlugin]}
              ref={calendarRef}
              initialView="dayGridMonth"
              height={"auto"}
              contentHeight={"auto"}
              headerToolbar={false}
              editable={false}
              nowIndicator={true}
              initialDate={selectedMonth}
              events={events}
              eventContent={renderEventContent}
              dateClick={handleDateClick}
              slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
                meridiem: false,
              }}
              firstDay={1}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShiftMonthlyPlanner;
