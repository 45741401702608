import React, { FC, useState } from "react";
import { Box } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

import calendarIcon from "../../assets/images/calendarIcon.svg";
import { useIntl } from "react-intl";

interface DateTimePickerComponentProps {
  name?: string;
  labelid: string;
  defaultlabelid: string;
  disabledDate?: boolean;
  value: Dayjs | null;
  handlechange?: (value: Dayjs) => void;
  disableFuture?: boolean;
  inputFormat?: string;
  hideIcon?: boolean;
  handleClose?: () => void;
  minDateTime?: Dayjs;
  minuteStep?: number;
  width?: string;
  textWidth?: string;
}

const DateTimePickerComponent: FC<DateTimePickerComponentProps> = ({
  labelid,
  defaultlabelid,
  disabledDate,
  minDateTime,
  value,
  handlechange,
  handleClose,
  disableFuture,
  inputFormat,
  hideIcon,
  name,
  minuteStep,
  width,
  textWidth,
}) => {
  const rctl = useIntl();
  const internalHandleDateChange = (date: Dayjs | null) => {
    if (handlechange && date != null) {
      handlechange(date);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const closeDatePicker = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  };
  const datePickerIcon = () => {
    return <img src={calendarIcon} alt="Calender Icon" />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        className={"flex__"}
        sx={{
          cursor: "pointer",
          borderRadius: "4px",
          border: "1px solid rgba(166, 197, 226, 0.16)",
          backgroundColor: "#22272B",
          width: width ? width : "max-content",
        }}
      >
        <Box>
          <DateTimePicker
            slotProps={{
              field: {
                clearable: false,
                readOnly: disabledDate,
              },
              textField: {
                disabled: disabledDate,
                placeholder: value
                  ? undefined
                  : rctl.formatMessage({
                      id: labelid,
                      defaultMessage: defaultlabelid,
                    }),
                onClick: disabledDate ? () => {} : handleOpen,
                sx: {
                  width: textWidth ? textWidth : "200px",
                },
              },
              popper: {
                sx: {
                  ".MuiPaper-root": {
                    backgroundColor: "#1D2125",
                    color: "#ffff",
                  },
                  ".MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel":
                    {
                      color: "#ffff",
                    },
                  ".MuiButtonBase-root.MuiIconButton-root": {
                    color: "#ffff",
                  },
                },
              },
              day: {
                sx: {
                  color: "#ffff",
                },
              },
            }}
            slots={{
              openPickerIcon: datePickerIcon,
            }}
            selectedSections={null}
            className={`dateTimePickerStyle datepicker ${
              disabledDate ? "disabledTextColor" : "notDisabledTextColor"
            }`}
            disabled={disabledDate}
            open={open}
            minDateTime={minDateTime}
            onOpen={handleOpen}
            onClose={closeDatePicker}
            value={value}
            ampm={false}
            onChange={internalHandleDateChange}
            disableFuture={disableFuture}
            format={inputFormat ? inputFormat : "MM/DD/YYYY hh:mm a"}
            minutesStep={minuteStep}
            views={["year", "month", "day", "hours", "minutes"]}
            sx={{
              "& fieldset": { border: "none" },
              ".& MuiPaper-root-MuiPickersPopper-paper": {
                backgroundColor: "1D2125",
              },
            }}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
export default DateTimePickerComponent;
