import React, { useState, FC, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import { Box, IconButton } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { useIntl } from "react-intl";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HtmlContent from "../../pages/ticketing/components/HtmlContent";
import { isRichTextEmpty } from "../../utils/commonFunctions";
import MediumTypography from "./MediumTypography";
import QuillMentionEditor from "./QuillMentionEditor";
import Quill from "quill";
import "./CustomRichText.css";

interface CustomRichTextProps
  extends React.ComponentProps<typeof QuillMentionEditor> {
  data: string;
  labelId?: string;
  defaultLabelId?: string;
  onChangeCallBack?: (value: string) => void;
  sxProps?: any;
  inline?: boolean;
}
const CustomRichText: FC<CustomRichTextProps> = ({
  labelId = "common.placeHolderText",
  defaultLabelId = "--- type here ---",
  data,
  onChangeCallBack = () => {},
  sxProps,
  inline = false,
  ...props // Capture additional props
}) => {
  const [value, setValue] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const quillRef = useRef<Quill | null>(null);
  const intl = useIntl();

  useEffect(() => {
    setValue(data);
  }, [data]);

  useEffect(() => {
    if (inline && isEditing && quillRef.current) {
      const editor = quillRef.current;

      // Focus the editor
      editor.focus();

      // Move the cursor to the end of the content
      const length = editor.getLength(); // Get the length of the content
      editor.setSelection(length, length);
    }
  }, [inline, isEditing]);

  const handleChange = (value: string) => {
    setValue(value);
    onChangeCallBack(value); // Call the onChange callback prop with the new value
  };

  const triggerBlur = () => {
    setIsEditing(false);
    if (quillRef?.current) {
      quillRef.current.blur();
    }
  };

  const handleSave = () => {
    if (value !== data) {
      onChangeCallBack(value);
    }
    triggerBlur();
  };

  const handleCancel = () => {
    setValue(data);
    triggerBlur();
  };

  const richTextComponent = (
    <QuillMentionEditor
      quillRef={quillRef}
      value={value}
      onChange={(value) => {
        if (inline) {
          setValue(value);
        } else if (handleChange !== undefined) {
          handleChange(value);
        }
      }}
      placeholder={intl.formatMessage({
        id: labelId,
        defaultMessage: defaultLabelId,
      })}
      {...props}
    />
  );

  if (!inline) return richTextComponent;

  return (
    <>
      {isEditing ? (
        <Box className="added-margin">
          {richTextComponent}
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handleSave}
              onMouseDown={(event) => {
                // Prevent the default blur on mouse down
                event.preventDefault();
              }}
              size="small"
              sx={{
                color: "#9fadbc",
                backgroundColor: "rgb(26, 30, 34)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
                borderRadius: "4px", // Rounded corners
                "&:hover": {
                  backgroundColor: "rgb(23, 25, 28)", // Slightly darker on hover
                },
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              onClick={handleCancel}
              onMouseDown={(event) => {
                // Prevent the default blur on mouse down
                event.preventDefault();
              }}
              size="small"
              sx={{
                color: "#9fadbc",
                backgroundColor: "rgb(26, 30, 34)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
                borderRadius: "4px", // Rounded corners
                "&:hover": {
                  backgroundColor: "rgb(23, 25, 28)", // Slightly darker on hover
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            borderRadius: "4px",
            padding: "16.5px 14px",
            cursor: !props.readOnly ? "pointer" : "auto",
            "&:hover": { backgroundColor: "rgba(34, 39, 43, 1)" },
          }}
          onClick={() => {
            if (!props.readOnly) {
              setIsEditing(true);
            }
          }}
        >
          {isRichTextEmpty(value) ? (
            <MediumTypography
              labelId="inline.noTextPlaceholder"
              defaultLabel="None"
              textColor="#4e5861"
            />
          ) : (
            <HtmlContent className="ticket-html-text">{value}</HtmlContent>
          )}
        </Box>
      )}
    </>
  );
};

export default CustomRichText;
