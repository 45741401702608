import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Popover,
  Grid,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";

import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import dayjs from "dayjs";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { hasPermission } from "../../../utils/checkPermission";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import TableHeaderSelection from "../TableHeaderSelection";
import { ReactComponent as Menu } from "../../../assets/images/HorizontalMenu.svg";
import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import AddEditPermitLineItemModal from "./AddEditPermitLineItemMapModal";
import TextInput from "../../../components/formlib/TextInput";
import "../styles.css";

import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../TableUtility";
import { useLocation } from "react-router-dom";
import {
  deleteDataApi,
  fetchAllDataApi,
  fetchDataApi,
} from "../../../api/MasterDataManagement/Common";
import { HeadCell } from "../types/accountTypes";

import CustomDropdown from "../../../components/formlib/CustomDropdown";
import {
  FetchPermitLineItemMetaDataType,
  FetchAllPermitLineItemType,
  PermitLineItem,
  PermitLineItemsNameTypes,
} from "../types/permitLineItemTypes";
import { getOptions, handleError } from "../../../utils/commonFunctions";

// Configurations

type RecordType = PermitLineItem;
type RecordsType = FetchAllPermitLineItemType;

const apiUri = "permitLineMap";
const deleteUrl = "permitLineItem";

const searchKeys = ["permitLineItem"];

const staticColumn = "";

export interface ManageComponentProps {
  onDataFetch: (data: RecordsType) => void;
  selectedLanguageCode: string;
  onOpenAddModal: boolean;
  handleModalClose: () => void;
  handleModalOpen: () => void;
  onFilter: (filteredData: { [key: string]: string }) => void;
  handlePermitIdChange: (id: number) => void;
}

const ManagePermitLineItemMapComponent: FC<ManageComponentProps> = ({
  onDataFetch,
  selectedLanguageCode,
  onOpenAddModal,
  handleModalClose,
  handleModalOpen,
  onFilter,
  handlePermitIdChange,
}) => {
  const availableWidth = useAvailableWidth(420);
  const availableHeight = useAvailableHeight(320);
  const emptyTableHeight = useAvailableHeight(380);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const containerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [listData, setListData] = useState<RecordsType | null>(null);
  const [tableData, setTableData] = useState<RecordType[]>([]);
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [originalTableData, setOriginalTableData] = useState<RecordType[]>([]);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [modalAccountId, setModalAccountId] = useState<number>();
  const open = Boolean(filterOpen);
  const addEditOpen = Boolean(anchorEl);

  const [permitNameData, setPermitNameData] = useState<
    PermitLineItemsNameTypes[]
  >([]);
  const [selectedPermitId, setSelectedPermitId] = useState<number>(0);

  const dataFieldsWithIcons = reorderedFields?.map((field) => {
    return { ...field, icon: <Sort /> };
  });

  const location = useLocation();

  useEffect(() => {
    fetchMetadata();
  }, [selectedLanguageCode, location?.state]);

  useEffect(() => {
    getRecords(true);
  }, [selectedPermitId, selectedLanguageCode, location?.state]);

  useEffect(() => {
    handlePermitIdChange(selectedPermitId);
  }, [selectedPermitId]);

  const [previousSelectedPermitId, setPreviousSelectedPermitId] =
    useState<number>(0);

  useEffect(() => {
    if (previousSelectedPermitId && permitNameData.length > 0) {
      const permitId = previousSelectedPermitId;
      const permitExists = permitNameData.find(
        (permit) => permit.id === permitId
      );
      if (permitExists) {
        setSelectedPermitId(permitId);
      } else {
        setSelectedPermitId(permitNameData[0].id);
      }
    }
  }, [permitNameData]);

  useEffect(() => {
    if (permitNameData.length > 0) {
      if (!selectedPermitId) {
        setSelectedPermitId(permitNameData[0].id);
      }
      setPreviousSelectedPermitId(selectedPermitId);
    }
  }, [permitNameData, selectedPermitId]);

  const getRecords = (
    resetData: boolean = false,
    language: string = selectedLanguageCode,
    applyFilters: boolean = false
  ) => {
    toggleLoader(true);

    const filterQuery = Object.entries(activeFilters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const fullUri = `${apiUri}/${selectedPermitId}`;

    fetchAllDataApi<RecordsType>(fullUri, language, filterQuery)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setListData(response);

          if (resetData) {
            setTableData(response.permitLineItemList);
            setOriginalTableData(response.permitLineItemList);
          } else {
            setTableData((prevMessages) => [
              ...prevMessages,
              ...response.permitLineItemList,
            ]);
            setOriginalTableData((prevMessages) => [
              ...prevMessages,
              ...response.permitLineItemList,
            ]);
          }
          setReorderedFields(response.dataFields);
          onDataFetch(response);
          if (applyFilters && Object.keys(activeFilters).length > 0) {
            const filteredData = applySearchFilters(
              response.permitLineItemList,
              activeFilters
            );
            setTableData(filteredData as RecordType[]);
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    setActiveFilters({});
  };

  const handleDelete = (id: number) => {
    toggleLoader(true);

    deleteDataApi(deleteUrl, id)
      .then(() => {
        getRecords(true, selectedLanguageCode, true);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    handleClose();
    setModalAccountId(undefined);
  };

  const handleSort = (columnKey: string, direction: string) => {
    const sortedData = sortTableData(
      tableData,
      columnKey,
      direction,
      originalTableData
    );
    setTableData(sortedData as RecordType[]);
  };

  const handleSearchApply = (columnKey: string, value: string) => {
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredData = applySearchFilters(originalTableData, newFilters);
    setTableData(filteredData as RecordType[]);
    onFilter(newFilters);
    setActiveFilters(newFilters);
  };

  const handleSearchCancel = (columnKey: string) => {
    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalTableData,
      activeFilters,
      columnKey
    );
    setTableData(filteredData as RecordType[]);
    onFilter(updatedFilters);
    setActiveFilters(updatedFilters);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id: number) => {
    setModalAccountId(id);
    handleModalOpen();
    handleClose();
  };

  const handleCloseMenu = () => {
    setFilterOpen(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterOpen(event.currentTarget);
  };

  const handleApply = (reorderedCheckedItems: HeadCell[]) => {
    setReorderedFields(reorderedCheckedItems);
    // Perform any additional actions with the reorderedCheckedItems
  };
  const handleConfirmDelete = useCallback(() => {
    if (modalAccountId) {
      handleDelete(modalAccountId);
    }
    setWarningModal(false);
  }, [modalAccountId]);

  const fetchMetadata = () => {
    toggleLoader(true);

    fetchDataApi<FetchPermitLineItemMetaDataType>(apiUri)
      .then(async (res) => {
        if (res) {
          setPermitNameData(res?.permits ?? []);
          toggleLoader(false);
        }
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };
  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Box>
      {onOpenAddModal && (
        <AddEditPermitLineItemModal
          id={modalAccountId}
          open={onOpenAddModal}
          handleClose={() => {
            setModalAccountId(undefined);
            handleModalClose();
          }}
          getDataCallback={() => getRecords(true)}
          permitId={selectedPermitId}
          permitName={
            permitNameData.find((item) => item.id === selectedPermitId)?.name ??
            ""
          }
        />
      )}
      {warningModal && (
        <ModalPopup
          descriptionText={"PermitLineItem.deleteWarningmessage"}
          open={warningModal}
          handleClose={() => setWarningModal(!warningModal)}
          onPositiveClick={handleConfirmDelete}
          onNegativeClick={() => {
            setWarningModal(false);
            handleClose();
            setModalAccountId(undefined);
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {listData && (
        <Box>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId="permitMap.title"
              defaultLabel="Permit"
              fontSize="20px"
            />
            <Box className="flex__" alignItems="center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                sxProps={{ marginRight: "4px" }}
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData?.totalRecords
                    : `${tableData.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />
              <Box
                className="ml-md display-flex-center menu-item"
                onClick={handleMenuClick}
              >
                <Menu />
              </Box>

              <TableHeaderSelection
                data={listData.dataFields}
                anchorEl={filterOpen}
                handleClose={handleCloseMenu}
                open={open}
                onApply={handleApply}
                fixedItem={staticColumn}
              />
            </Box>
          </Box>
          <Box sx={{ pb: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MediumTypography
                  className="input-label"
                  labelId="permitItem.title"
                  defaultLabel="Permit"
                />

                <CustomDropdown
                  disableCreate={true}
                  options={getOptions(permitNameData)}
                  selectedValue={selectedPermitId}
                  onSelectedValueChange={(value) => {
                    setSelectedPermitId(Number(value as string) ?? 0);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MediumTypography
                  className="input-label"
                  labelId="permitMap.type"
                  defaultLabel="Permit Type"
                />

                <TextInput
                  className="text-input-field"
                  type="text"
                  variant="outlined"
                  inputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                  Value={
                    permitNameData?.find(
                      (item) =>
                        item.id?.toString() === selectedPermitId?.toString()
                    )?.permitType ?? ""
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              height: availableHeight,
            }}
            className={"position__relative overflow_Y"}
          >
            {tableData && (
              <>
                <TableContainer
                  component={Paper}
                  sx={{
                    width: availableWidth,

                    height: tableData.length !== 0 ? availableHeight : "auto",
                  }}
                  className={"tableContainer"}
                  onScroll={() => {}}
                  ref={containerRef}
                >
                  <Table>
                    <CustomTableHeader
                      headerNames={dataFieldsWithIcons}
                      searchableKeys={searchKeys}
                      stickyItems={staticColumn}
                      onSortApply={handleSort}
                      onCancelSearch={handleSearchCancel}
                      onSearchApply={handleSearchApply}
                      languageCode={selectedLanguageCode}
                    />
                    <TableBody>
                      {tableData.length !== 0 &&
                        tableData.map((row, rowIndex) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                            }}
                          >
                            {/* Sticky First Column */}
                            <TableCell
                              className="stickyColumn"
                              align={"left"}
                              sx={{
                                backgroundColor:
                                  rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                padding: "4px 12px",
                              }}
                            >
                              <MediumTypography
                                label={(rowIndex + 1).toString()}
                              />
                            </TableCell>

                            {dataFieldsWithIcons.map((cell: HeadCell) => {
                              const cellValue =
                                row[cell.key as keyof RecordType];

                              if (cell.key === "lastUpdated") {
                                return (
                                  <TableCell
                                    // className="p-md"
                                    key={cell.key}
                                    align={"left"}
                                    sx={{
                                      backgroundColor:
                                        rowIndex % 2 === 0
                                          ? "#2B3033"
                                          : "#22272B",
                                      padding: "4px 12px",
                                    }}
                                  >
                                    <Box className="display-flex-center">
                                      <Avatar
                                        className="avatar-style"
                                        src={
                                          row.lastUpdated !== null &&
                                          row.lastUpdated !== undefined
                                            ? row.lastUpdated.imageUrl
                                            : "-"
                                        }
                                        alt="abc"
                                      />
                                      <Box className="ml-sm">
                                        <MediumTypography
                                          label={
                                            row.lastUpdated !== null &&
                                            row.lastUpdated !== undefined
                                              ? row.lastUpdated.name
                                              : "-"
                                          }
                                          sxProps={{
                                            lineHeight: "normal",
                                          }}
                                          className="mt-xs"
                                        />

                                        <MediumTypography
                                          label={
                                            row.lastUpdated !== null &&
                                            row.lastUpdated !== undefined
                                              ? dayjs(
                                                  row.lastUpdated.timestamp
                                                ).format("DD MMM YYYY HH:mm")
                                              : ""
                                          }
                                          fontSize="12px"
                                          textColor={
                                            "rgba(255, 255, 255, 0.64)"
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </TableCell>
                                );
                              }

                              return (
                                <>
                                  <TableCell
                                    // className="p-md"
                                    key={cell.key}
                                    align={"left"}
                                    sx={{
                                      backgroundColor:
                                        rowIndex % 2 === 0
                                          ? "#2B3033"
                                          : "#22272B",
                                      padding: "4px 12px",
                                    }}
                                  >
                                    <MediumTypography
                                      label={
                                        cellValue !== undefined &&
                                        cellValue !== null &&
                                        cellValue !== ""
                                          ? cellValue.toString()
                                          : "-"
                                      }
                                    />
                                  </TableCell>
                                </>
                              );
                            })}

                            {/* Sticky Last Column */}
                            <TableCell
                              className="stickyColumn_right"
                              align={"left"}
                              sx={{
                                backgroundColor:
                                  rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                padding: "4px 12px",
                              }}
                            >
                              <Tooltip title="More">
                                <IconButton
                                  onClick={(event) =>
                                    handleClick(event, rowIndex)
                                  }
                                >
                                  <More />
                                </IconButton>
                              </Tooltip>
                              <Popover
                                anchorEl={anchorEl}
                                open={addEditOpen && selectedRow === rowIndex}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                sx={{
                                  "& .MuiPaper-root": {
                                    backgroundColor: "#2B3033",
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    hasPermission("Onboarding_Admin")
                                      ? handleEdit(row.id)
                                      : () => {}
                                  }
                                  style={{ backgroundColor: "2B3033" }}
                                  disabled={selectedLanguageCode !== "en"}
                                >
                                  <MediumTypography
                                    labelId="btn.edit"
                                    defaultLabel="Edit"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    hasPermission("Onboarding_Admin")
                                      ? (setWarningModal(true),
                                        setModalAccountId(row.id))
                                      : () => {}
                                  }
                                  style={{ backgroundColor: "2B3033" }}
                                >
                                  <MediumTypography
                                    labelId="planner.deleteText"
                                    defaultLabel="Delete"
                                  />
                                </MenuItem>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {tableData.length === 0 && (
                  <Box
                    className="display-flex-center flex__justify__center "
                    sx={{
                      height: emptyTableHeight,
                    }}
                  >
                    <MediumTypography
                      labelId="ManagePermit.emptymsg"
                      defaultLabel="No line items mapped to the selected Permit"
                      fontSize="20px"
                      fontWeight={700}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ManagePermitLineItemMapComponent;
