import { Box, Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import {
  DepartmentMemberBeanList,
  SubgroupDetailsTypes,
  TicketType,
} from "../wizrads.types";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import TextInput from "../../../components/formlib/TextInput";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
  fetchWizardsDataApi,
  MappedCommonTypes,
} from "../../../api/wizardsApi/Common";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { WizardsContext } from "../WizardParentComponent";
import {
  customRequiredFn,
  dropdownValidation,
} from "../assetGroup/AssetGroupWizards";
import MappingModal from "../../../components/formlib/modal/MappingModal";
import TextInputMap from "../../../components/formlib/TextInputMap";

const muiColumnProps = {
  xs: 12,
  sm: 8,
  md: 6,
  lg: 6,
  xl: 6,
};

interface SubGroupProps {
  siteId: number;
  subGroupId: number;
  subGroupName: string;
  childCallBack: () => void;
  AddCallBack: () => void;
  selectedLanguageCode: string;
  isSubgroupFirst: boolean;
}

const initialSubgroupData: SubgroupDetailsTypes = {
  name: "",
  location: "",
  longitude: "",
  latitude: "",
  contactPersonFirstName: "",
  contactPersonLastName: "",
  phoneNumber: "",
  email: "",
  // status: "",
  code: "",
  countryCode: "",
  radius: 0,
  checkInThreshold: 0,
  noShowThreshold: 0,
  checkoutThreshold: 0,
  autoCheckoutThreshold: 0,
  selectedTicketTypeIds: [],
  subgroupStatusList: [],
  ticketTypeList: [],
  selectedSubgroupStatusId: 0,
  departmentUsersList: [],
  selectedDepartmentUsersList: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  location: Yup.string().nullable().required("validation.fieldRequired"),
  longitude: Yup.string().nullable().required("validation.fieldRequired"),
  latitude: Yup.string().nullable().required("validation.fieldRequired"),
  contactPersonFirstName: Yup.string()
    .nullable()
    .required("validation.fieldRequired"),
  contactPersonLastName: Yup.string()
    .nullable()
    .required("validation.fieldRequired"),
  phoneNumber: Yup.string().nullable(),
  email: Yup.string().nullable(),
  selectedSubgroupStatusId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  code: Yup.string().nullable(),
  countryCode: Yup.string().nullable(),
  radius: Yup.number().nullable().required("validation.fieldRequired"),
  checkInThreshold: Yup.number()
    .nullable()
    .required("validation.fieldRequired"),
  noShowThreshold: Yup.number().nullable().required("validation.fieldRequired"),
  checkoutThreshold: Yup.number()
    .nullable()
    .required("validation.fieldRequired"),
  autoCheckoutThreshold: Yup.number()
    .required("validation.fieldRequired")
    .nullable(),
});

const SubgroupDetailsComponent: FC<SubGroupProps> = ({
  siteId,
  subGroupId,
  subGroupName,
  childCallBack,
  AddCallBack,
  selectedLanguageCode,
  isSubgroupFirst,
}) => {
  const [subGroupDetailsData, setSubgroupDetailsData] =
    useState<SubgroupDetailsTypes>(initialSubgroupData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const currentSubGroupName = useRef<string>(subGroupName);
  const availableHeight = useAvailableHeight(270);
  const siteContext = useContext(WizardsContext);
  const [openMapModal, setOpenMapModal] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<SubgroupDetailsTypes>>(null);
  const [ticketTypeArr, setTicketTypeArr] = useState<TicketType[]>([]);
  const [departmentArr, setDepartmentArr] = useState<TicketType[]>([]);
  const [openDepartmentMapModal, setOpenDepartmentMapModal] =
    useState<boolean>(false);
  const [memberBeanList, setMemberBeanList] = useState<
    DepartmentMemberBeanList[]
  >([]);

  useEffect(() => {
    getSubGroupById();
  }, [subGroupId, selectedLanguageCode]);

  useEffect(() => {
    currentSubGroupName.current = subGroupName;
  }, [subGroupName]);

  const getSubGroupById = () => {
    const fetchSitetUrl = `${WORK_FLOW_BASE_URL}subgroup`;

    toggleLoader(true);

    fetchWizardsDataApi<SubgroupDetailsTypes>(
      fetchSitetUrl,
      subGroupId,
      selectedLanguageCode
    )
      .then(async (resp: SubgroupDetailsTypes) => {
        toggleLoader(false);
        if (
          resp?.mappedTicketTypeIds !== null &&
          resp?.ticketTypeList !== null &&
          resp?.ticketTypeList !== undefined &&
          resp.mappedTicketTypeIds !== undefined
        ) {
          const updatedData =
            resp.ticketTypeList?.map((item) => ({
              ...item,
              isMapped: resp?.mappedTicketTypeIds?.includes(item.id),
            })) ?? [];
          setTicketTypeArr(updatedData);
        } else {
          const updatedData =
            resp.ticketTypeList?.map((item) => ({
              ...item,
              isMapped: false,
            })) ?? [];
          setTicketTypeArr(updatedData);
        }

        if (resp.departmentUsersList !== null) {
          const updatedData =
            resp.departmentUsersList?.map((item) => ({
              ...item,
              isMapped: false,
            })) ?? [];
          setDepartmentArr(updatedData);
        }

        if (siteContext && isSubgroupFirst) {
          const siteSubgroupData: SubgroupDetailsTypes = {
            name: siteContext.selelctedSiteDetails.name,
            location: siteContext.selelctedSiteDetails.location,
            longitude: siteContext.selelctedSiteDetails.longitude,
            latitude: siteContext.selelctedSiteDetails.latitude,
            contactPersonFirstName:
              siteContext.selelctedSiteDetails.contactPersonFirstName,
            contactPersonLastName:
              siteContext.selelctedSiteDetails.contactPersonLastName,
            phoneNumber: siteContext.selelctedSiteDetails.phoneNumber,
            email: siteContext.selelctedSiteDetails.email,
            // status: "",
            code: siteContext.selelctedSiteDetails.code,
            countryCode: siteContext.selelctedSiteDetails.countryCode,
            radius: 0,
            checkInThreshold: 0,
            noShowThreshold: 0,
            checkoutThreshold: 0,
            autoCheckoutThreshold: 0,
            subgroupStatusList: resp.subgroupStatusList,
            selectedTicketTypeIds: resp.selectedTicketTypeIds,
            ticketTypeList: resp.ticketTypeList,
            departmentUsersList: resp.departmentUsersList,
            selectedDepartmentUsersList: resp.selectedDepartmentUsersList,
          };
          setSubgroupDetailsData(siteSubgroupData);
          formikRef.current?.setValues(siteSubgroupData);
        } else {
          const res = { ...initialSubgroupData, ...resp };

          const updatedResponse: SubgroupDetailsTypes = {
            ...res,
            id: res.id,
          };
          setSubgroupDetailsData(updatedResponse);
          siteContext?.subgroupOnChange(updatedResponse);
          formikRef.current?.setValues(updatedResponse);
        }

        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (data: SubgroupDetailsTypes) => {
    let params: Omit<SubgroupDetailsTypes, "subgroupStatusList"> = {
      id: data?.id,
      name: data?.name,
      location: data?.location,
      longitude: data?.longitude,
      latitude: data?.latitude,
      contactPersonFirstName: data?.contactPersonFirstName,
      contactPersonLastName: data?.contactPersonLastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      code: data?.code,
      countryCode: data?.countryCode,
      selectedSubgroupStatusId: data?.selectedSubgroupStatusId,
      radius: data?.radius,
      checkInThreshold: data?.checkInThreshold,
      noShowThreshold: data?.noShowThreshold,
      checkoutThreshold: data?.checkoutThreshold,
      autoCheckoutThreshold: data?.autoCheckoutThreshold,
      selectedTicketTypeIds: data?.selectedTicketTypeIds,
      ticketTypeList: data.ticketTypeList,
      departmentUsersList: data.departmentUsersList,
      selectedDepartmentUsersList: data.selectedDepartmentUsersList,
    };

    const url = `${WORK_FLOW_BASE_URL}${siteId}/subgroup/save`;

    // CreateSiteDetails(selectedAcc, params)
    customSaveDataApi(url, params)
      .then(() => {
        currentSubGroupName.current = data.name;
        siteContext?.subgroupOnChange(data);
        childCallBack();
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
    // Add your API call logic
  };

  const updateData = (data: SubgroupDetailsTypes) => {
    let params: Omit<SubgroupDetailsTypes, "subgroupStatusList"> = {
      id: data?.id,
      name: data?.name,
      location: data?.location,
      longitude: data?.longitude,
      latitude: data?.latitude,
      contactPersonFirstName: data?.contactPersonFirstName,
      contactPersonLastName: data?.contactPersonLastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      code: data?.code,
      countryCode: data?.countryCode,
      selectedSubgroupStatusId: data?.selectedSubgroupStatusId,
      radius: data?.radius,
      checkInThreshold: data?.checkInThreshold,
      noShowThreshold: data?.noShowThreshold,
      checkoutThreshold: data?.checkoutThreshold,
      autoCheckoutThreshold: data?.autoCheckoutThreshold,
      selectedTicketTypeIds: data?.selectedTicketTypeIds,
      ticketTypeList: data.ticketTypeList,
      departmentUsersList: data.departmentUsersList,
      selectedDepartmentUsersList: data.selectedDepartmentUsersList,
    };
    const SubGroupIdExist = subGroupId === 0 ? undefined : subGroupId;
    const updateUrl = `${WORK_FLOW_BASE_URL}subgroup/${SubGroupIdExist}/update`;
    // if (data.id) {
    customUpdateDataApi(updateUrl, params)
      .then(() => {
        getSubGroupById();
        currentSubGroupName.current = data.name;
        siteContext?.subgroupOnChange(data);
        childCallBack();
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };
  const handleApply = (mappedData: MappedCommonTypes[]) => {
    setOpenMapModal(false);

    const updatedArray = ticketTypeArr.map((item) => {
      const match = mappedData.find(
        (obj) => obj.id === item.id && obj.isSelected
      );
      return {
        ...item,
        isSelected: match ? true : false,
      };
    });

    setTicketTypeArr(updatedArray);

    formikRef.current?.setFieldValue(
      "selectedTicketTypeIds",
      mappedData.map((obj) => obj.id)
    );
  };

  const handleDepartmentApply = (mappedData: MappedCommonTypes[]) => {
    setOpenDepartmentMapModal(false);

    const selectedDefaultDepartmentArrayList = mappedData.map((item) => ({
      deptId: item.id,
      userId: item.defaultUserId,
      isDefaultDepartment: item.isDefaultDept,
    }));

    const updatedArray = departmentArr.map((item) => {
      const match = mappedData.find(
        (obj) => obj.id === item.id && obj.isSelected
      );
      return {
        ...item,
        isSelected: match ? true : false,
        defaultUserId: match ? match.defaultUserId : item.defaultUserId,
        isDefaultDept: match ? match.isDefaultDept : item.isDefaultDept,
      };
    });

    setDepartmentArr(updatedArray);

    formikRef.current?.setFieldValue(
      "selectedDepartmentUsersList",
      selectedDefaultDepartmentArrayList
    );
  };

  return (
    <Box>
      {openMapModal && (
        <MappingModal
          titleLabelId="ticketType.mappingTitle"
          handleClose={() => setOpenMapModal(false)}
          open={openMapModal}
          onApply={handleApply}
          availableLabel="ticketType.available"
          mappingDetailsLabel="ticketType.mappingDetails"
          mappedList={ticketTypeArr}
          textInputRequired={false}
        />
      )}
      {openDepartmentMapModal && (
        <MappingModal
          titleLabelId="Department.mappingTitle"
          handleClose={() => setOpenDepartmentMapModal(false)}
          open={openDepartmentMapModal}
          onApply={handleDepartmentApply}
          availableLabel="Department.available"
          mappingDetailsLabel="Department.mappingDetails"
          mappedList={departmentArr}
          textInputRequired={false}
          isDropdownRequired={true}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <Box className="flex__">
          {subGroupId !== 0 && (
            <MediumTypography
              labelId="subgroupText"
              defaultLabel="Subgroup :"
              fontSize="20px"
              fontWeight={600}
              className="mr-sm"
            />
          )}

          <MediumTypography
            label={currentSubGroupName.current}
            fontSize="20px"
            fontWeight={700}
          />
        </Box>
        {selectedLanguageCode === "en" && (
          <ButtonComponent
            className="btn-primary btn-submit"
            variantType="contained"
            defaultLabelId={"Add New"}
            labelId={"btn.AddNew"}
            onClick={() => {
              setSubgroupDetailsData(initialSubgroupData);
              AddCallBack();
              currentSubGroupName.current = subGroupName;
            }}
          />
        )}
      </Box>
      <Box>
        <Formik
          initialValues={subGroupDetailsData}
          enableReinitialize
          validateOnChange
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (subGroupId !== 0) {
              updateData(values);
            } else {
              saveData(values);
            }
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            dirty,
          }) => {
            return (
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    overflowY: "auto",
                    height: availableHeight,
                    WebkitOverflowScrolling: "touch",
                    "-webkit-overflow-scrolling": "touch", // iOS specific - enables momentum scrolling
                    "scroll-behavior": "smooth", // Optional: Adds smooth scrolling behavior
                    // "&::-webkit-scrollbar": {
                    //   display: "none", // Hide scrollbar on Chrome, Safari, and Opera
                    // },
                  }}
                >
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.name"
                      defaultLabel="Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.code"
                      defaultLabel="Code"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.code}
                      handlechange={(value: string) => {
                        setFieldValue("code", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="code"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.location"
                      defaultLabel="Location"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.location}
                      handlechange={(value: string) => {
                        setFieldValue("location", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="location"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.latitude"
                      defaultLabel="Latitude"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.latitude}
                      handlechange={(value: string) => {
                        setFieldValue("latitude", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="latitude"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.longitude"
                      defaultLabel="Longitude"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.longitude}
                      handlechange={(value: string) => {
                        setFieldValue("longitude", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="longitude"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonFirstName"
                      defaultLabel="Contact Person First Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.contactPersonFirstName}
                      handlechange={(value: string) => {
                        setFieldValue("contactPersonFirstName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="contactPersonFirstName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonLastName"
                      defaultLabel="Contact Person Last Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.contactPersonLastName}
                      handlechange={(value: string) => {
                        setFieldValue("contactPersonLastName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="contactPersonLastName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonPhoneNumber"
                      defaultLabel="Contact Person Phone Number"
                    />
                    <PhoneNumberInput
                      value={`${values.countryCode || ""}${
                        values.phoneNumber || ""
                      }`} // Display full value for UI, concatenating code & number
                      onChange={(value: string, countryData: any) => {
                        const countryCode = countryData.dialCode; // Get the country code
                        const phoneNumber = value.startsWith(countryCode)
                          ? value.slice(countryCode.length) // Remove country code from the start of the value
                          : value; // Use the value directly if no country code prefix

                        setFieldValue("countryCode", countryCode); // Store only the country code separately
                        setFieldValue("phoneNumber", phoneNumber); // Store only the raw phone number without country code
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      disabled={false}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="phoneNumber"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonEmail"
                      defaultLabel="Contact Person Email"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.email}
                      handlechange={(value: string) => {
                        setFieldValue("email", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="email"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.status"
                      defaultLabel="Status"
                    />
                    <CustomDropdown
                      disableCreate={true}
                      sxStyles={{
                        textField: {
                          padding: "0.5px 4px 0px 5px",
                        },
                      }}
                      options={getOptions(values.subgroupStatusList)}
                      selectedValue={values.selectedSubgroupStatusId}
                      onSelectedValueChange={(value) => {
                        const statusId = value as string;
                        setFieldValue("selectedSubgroupStatusId", statusId);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedSubgroupStatusId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.radiusName"
                      defaultLabel="Attendance Radius (Meter)"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.radius.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("radius", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="radius"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.checkinthreshold"
                      defaultLabel="Check In Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.checkInThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("checkInThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="checkInThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.checkoutthreshold"
                      defaultLabel="Check Out Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.checkoutThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("checkoutThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="checkoutThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.noShowThreshold"
                      defaultLabel="No Show Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.noShowThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("noShowThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="noShowThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.autoCheckOutThreshold"
                      defaultLabel="Auto Check Out Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.autoCheckoutThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("autoCheckoutThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="autoCheckoutThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Map Ticket Type"
                      defaultLabel="Map Ticket Type"
                    />

                    <TextInputMap
                      fieldValue={(ticketTypeArr as TicketType[])
                        .filter((item) => item.isSelected)
                        .map((item) => item.name)}
                      buttonCallBackClick={() => setOpenMapModal(true)}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedTicketTypeIds"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="MapDepartment.text"
                      defaultLabel="Map Department"
                    />

                    <TextInputMap
                      fieldValue={(departmentArr as TicketType[])
                        .filter((item) => item.isSelected)
                        .map((item) => item.name)}
                      buttonCallBackClick={() =>
                        setOpenDepartmentMapModal(true)
                      }
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedTicketTypeIds"
                    />
                  </Grid>
                </Grid>
                <Box className="flex__ justifyContent-FlexEnd mt-md">
                  {selectedLanguageCode === "en" && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      defaultLabelId={"Save"}
                      labelId={subGroupId !== 0 ? "btn.update" : "btn.save"}
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default SubgroupDetailsComponent;
