import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../../MasterDataManagement/styles.css";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  onPositiveClick: () => void;
  headerElement: React.ReactNode;
  contentElement: React.ReactNode;
  hideActions?: boolean;
  size?: Breakpoint;
}

const CommonModal: FC<ModalProps> = ({
  open,
  handleClose,
  onPositiveClick,
  headerElement,
  contentElement,
  hideActions = false,
  size = "sm",
}) => {
  return (
    <Dialog
      className="account-dialog main-wrapper-box"
      open={open}
      fullWidth
      maxWidth={size}
    >
      <DialogTitle className="padding_20">{headerElement}</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="closeIcon"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        dividers
        className="padding_20"
        sx={{
          borderBottom: hideActions ? "none !important" : undefined,
        }}
      >
        {contentElement}
      </DialogContent>

      {!hideActions && (
        <DialogActions className="dialogActions">
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-ticket mr-md"
                  variantType="contained"
                  defaultLabelId={"Ok"}
                  labelId={"OkText"}
                  onClick={onPositiveClick}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CommonModal;
