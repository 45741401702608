function hasPermission(screenName: string): boolean {
  const userRoles = JSON.parse(localStorage.getItem("userRoles") || "[]");
  for (const role of userRoles) {
    for (const permission of role.permission) {
      if (permission.permissionName === "Param") {
        return true;
      } else if (permission.permissionName === screenName) {
        return true;
      }
    }
  }
  return false;
}

function hasRoleId(roleId: number): boolean {
  const userRoles = JSON.parse(localStorage.getItem("userRoles") ?? "[]");
  for (const role of userRoles) {
    for (const permission of role.permission) {
      if (permission.permissionName === "Param" || role.id === roleId) {
        return true;
      }
    }
  }
  return false;
}

export { hasPermission, hasRoleId };
