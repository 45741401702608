import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";

export interface CardDetailsType {
  code: string;
  type: string;
  assetCatFreqDesc: string;
  workOrderUniqueId: number;
  asset: string;
  frequencyDisplay: string;
  assetNameAndCount: string;
  lastUpdated: string;
}

export interface AssigneeType {
  id: number;
  name: string;
  title: string;
  image: string;
  status: string;
  isPrimary?: boolean;
  availability?: string;
  date?: string;
}
export interface ApproverType {
  id: number;
  name: string;
  title: string;
  image: string;
  roleId: number;
  roleName: string;
}

export interface WorkOrderInfoType {
  scheduledStartDateTime: Date | null | string;
  scheduledEndDateTime: string | null;
  actualStartDateTime: string | null;
  actualEndDateTime: string | null;
  plannedTables: number;
  actualTables: number;
  plannedModules: number;
  actualModules: number;
  cycleName: string;
  rejectedTables: number;
  rejectedModules: number;
  leadAssignee: AssigneeType;
  numberOfWorkingHours: number;
  cleaningCompletion?: string;
}

export interface CleaningInfoBeanType {
  tankers?: number;
  tankerCapacity?: number;
  waterConsumed?: number;
  tdsLevel?: number;
  robotsAvailableAutomatic?: number;
  robotsAvailableSemiAutomatic?: number;
  robotsFunctionalAutomatic?: number;
  robotsFunctionalSemiAutomatic?: number;
  remarks: string;
  cleaningType: string;
  laboursAllocated: number;
  selectedCleaningTypeId: number;
  selectedCleaningMethodId: number;
  selectedReasonForDeviationId: number;
  selectedCleaningTypeName: string;
  otherReason?: string;
  impactedHours: number;
  selectedCleaningTypeCode: string;
}

export interface AdditionalInfoType {
  cycleCountInc: number;
  cumulativeCycleCount: number;
  rainFall: number;
  grassImpact?: boolean;
  vendorId: number;
  vendorName?: string;
  grassImpactTable?: boolean;
  contractLineItem: string;
  lastUpdatedBy: AssigneeType;
}

export interface StatusDataType {
  status: StatusType | string;
  code: StatusType | string;
  transitionDateTime: string;
  name: AssigneeType;
  duration: string;
  approver1?: string;
  approver2?: string;
  approver3?: string;
  approverRole1?: string;
  approverRole2?: string;
  approverRole3?: string;
  remarks?: string;
}

export interface VendorListType {
  id: number;
  name: string;
  status: string;
}

export interface ReasonForDeviationType {
  id: number;
  name: string;
  code: string;
}

export interface CleaningMethodType {
  id: number;
  name: string;
}

export interface ImageType {
  id?: number;
  comments: string;
  createdDateTime: string;
  geoStamp: GeoStampType;
  document: string;
}

export type GeoStampType = {
  latitude: string;
  longitude: string;
};

export interface ModuleCleaning {
  woId: number;
  isEdit: boolean;
  isEditCard: boolean;
  status: StatusDataType[];
  cardDetailsInfo: CardDetailsType;
  workOrderInfo: WorkOrderInfoType;
  cleaningInfoBean: CleaningInfoBeanType;
  additionalInfoBean: AdditionalInfoType;
  assignee: AssigneeType[];
  cleaningMethod: CleaningMethodType[];
  vendorList: VendorListType[];
  reasonForDeviationList: ReasonForDeviationType[];
  afterImage?: ImageType[];
  beforeImage?: ImageType[];
  dumpImage?: ImageType[];
  approver1?: ApproverType | null;
  approver2?: ApproverType | null;
  approver3?: ApproverType | null;
  definedScheduleStatus: StatusDataProps[];
  scheduleExecution: ScheduleExecutionType;
  version: number;
}
export interface ActivityMessage {
  fromAssigneeId: number;
  message: string;
  messageId: number;
  parentId: number;
  sentDate: string;
  toAssigneeId: number[];
  taggedMessage: string;
  images?: string[];
}

export interface ActivityTypes {
  messages: ActivityMessage[];
  assignedUsers: AssigneeType[];
  siteUsers: AssigneeType[];
  msgCountPerPage: number;
  totalMessages: number;
  image?: string;
}

export interface ActivityMessageType {
  fromAssigneeId: number;
  toAssigneeId: number[];
  message: string;
  parentId: number;
  messageId: number;
  sentDate: string;
  subActivityList: string[];
}

export interface StatusDataProps {
  id: number;
  code: StatusType | string;
  name: StatusType | string;
  isUpdatedStatus: boolean;
  transitionDateTime: string;
  duration: string;
  assignee: AssigneeType;
  remarks: string;
}

export type ScheduleExecutionType = {
  [key: string]: string[];
};

export type StatusType =
  | "SCHEDULED"
  | "DISPATCHED"
  | "INPROGRESS"
  | "ONHOLD"
  | "COMPLETED"
  | "REJECTED"
  | "PAUSED"
  | "CLOSED"
  | "REVOKE"
  | "PAUSEREQUESTED"
  | "ADDITIONALSTATUS"
  | "APPROVE"
  // | "MARKCOMPLETED"
  | "LEVEL1"
  | "LEVEL2"
  | "LEVEL3"
  | "PENDING"
  | "ON HOLD"
  | "NEW"
  | "FINAL RCA";

export type UpdateStatusType = {
  code: StatusType | string;
  approver1: number;
  approver2: number;
  approver3: number;
  peformedBy: number;
  remarks: string;
  isRCARequired?: boolean;
};

export const getModuleCleaningApi = async (
  cardId: number
): Promise<ModuleCleaning> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(`workorder/mc/${cardId}`)
  );
};

export const saveModuleCleaningApi = async (
  cardId: number,
  params: ModuleCleaning
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<ModuleCleaning, ModuleCleaning>(
      `workorder/mc/save/${cardId}`,
      params
    )
  );
};

export const getWorkOrderActivities = async (
  cardId: number,
  pageNumber: number,
  subGroupId: number
): Promise<ActivityTypes> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/${cardId}/activity?subGroupId=${subGroupId}&pageNumber=${pageNumber}`
    )
  );
};

export const saveActivityMessage = async (
  cardId: number,
  params: ActivityMessageType
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<
      ActivityMessageType,
      ActivityMessageType
    >(`workorder/${cardId}/saveActivity`, params)
  );
};

export const updateWorkOrderStatus = async (
  cardId: number,
  woUniqueId: number,
  params: UpdateStatusType
): Promise<StatusDataType[]> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `workOrder/${cardId}/updateStatus?woUniqueId=${woUniqueId}`,
      params
    )
  );
};
