import * as React from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import "./css/styles.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import MediumTypography from "../../components/formlib/MediumTypography";
import SearchBox from "../../components/formlib/SearchBox";
import { ReactComponent as More } from "../../assets/images/ThreeDots.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import TableHeaderSelection from "./components/TableHeaderSelection";
import {
  DefaultFilterType,
  FilteredDataType,
  FilterModeType,
  NumericOptionType,
  RecordsFilterType,
  SavedFilterType,
  SortType,
  SpecialFilterType,
  StatusDataType,
  TableHeaderCellType,
  TableRowType,
  UserType,
} from "./types/Types";
import FilterDropdown from "./components/FilterDropdown";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import { isCustomError } from "../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../api/UserApi/User";
import ErrorModal from "../../components/formlib/modal/ErrorModal";

import { debounce } from "lodash";
import CustomDropdown from "../../components/formlib/CustomDropdown";
import {
  convertToNumberArray,
  convertToRGBA,
  getOptions,
} from "../../utils/commonFunctions";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteFilterDataApi,
  fetchListViewMetaDataApi,
  listViewRecordsApi,
  fetchOptionsApi,
  saveTableHeadersApi,
} from "../../api/ticketing/TicketingAPI";
import FilterSaveModal from "./components/FilterSaveModal";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import useAvailableHeight from "../../utils/useAvailableHeight";
import CreateTicketModal from "./create_ticket/CreateTicketModal";
import { ReactComponent as NotAvialableIcon } from "../../assets/images/woNotFound.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/DownloadIcon.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { TICKET } from "../../routes/Routing";
import { hasTicketEditPermission } from "./helpers/helper";

const VirtuosoTableComponents: TableComponents<TableRowType> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
      className="table-container"
    />
  )),
  Table: (props) => <Table {...props} />,
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const TruncatedTableCell: React.FC<{
  text: string;
  column?: string;
  row?: number;
}> = ({ text, column, row }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    checkTruncation();
  }, [text]);

  return (
    <Tooltip
      title={
        isTruncated ? (
          <MediumTypography label={text} className="table-cell-value" />
        ) : (
          ""
        )
      }
      arrow
    >
      <Box ref={textRef} className="truncate-box">
        <MediumTypography
          label={text}
          className="table-cell-value"
          sxProps={{
            color: column === "summary" ? "#25bafa !important" : undefined,
          }}
        />
      </Box>
    </Tooltip>
  );
};

export const generateCode = (input: string) => {
  if (!input || typeof input !== "string") return "NA";

  // Filter out non-alphabetic words and split by spaces
  const words = input.split(/\s+/).filter((word) => /^[a-zA-Z]+$/.test(word));

  if (words.length === 0) return "NA";

  if (words.length === 1) {
    // Return first two letters of the single word
    return words[0].substring(0, 2).toUpperCase();
  }

  // Return the first letter of the first two words
  return (words[0][0] + words[1][0]).toUpperCase();
};

const updateFilterSelectionsById = (
  data: DefaultFilterType[],
  id: number,
  selections: number[]
) => {
  return data.map((item) => {
    if (item.id === id) {
      // Create a new object for the updated item
      return {
        ...item,
        selectedOptionIds: selections,
      };
    }
    return item;
  });
};

const updateFilterOptionsByCode = (
  data: DefaultFilterType[],
  code: string,
  options: NumericOptionType[]
) => {
  let isSelectionExists = false;
  const updatedData = data.map((item) => {
    if (item.code === code) {
      const newSelectedOptionIds = item.selectedOptionIds.filter((soId) =>
        options.find((optionItem) => optionItem.id === soId)
      );
      isSelectionExists = newSelectedOptionIds.length > 0;

      // Create a new object for the updated item
      return {
        ...item,
        values: options,
        selectedOptionIds: newSelectedOptionIds,
      };
    }
    return item;
  });

  return { updatedData, isSelectionExists };
};

const clearFilterSelections = (data: DefaultFilterType[]) => {
  return data.map((item) => ({
    ...item,
    selectedOptionIds: [],
  }));
};

const getSelectedFilters = (data: DefaultFilterType[]): FilteredDataType[] => {
  return data
    .map(({ name, values, ...item }) => item)
    .filter((item) => item.selectedOptionIds.length > 0);
};

const getAppliedFilters = (data: FilteredDataType[]) => {
  return data.reduce((acc, curr) => {
    if (!acc[curr.code]) {
      acc[curr.code] = [];
    }
    acc[curr.code].push(...curr.selectedOptionIds);
    return acc;
  }, {} as { [key: string]: number[] });
};

export default function TicketListView() {
  // Loader
  const { toggleLoader, loader } = useContext(
    LoaderContext
  ) as LoaderContextType;

  // Data
  const [tableHeaders, setTableHeaders] = useState<TableHeaderCellType[]>([]);
  const [tableRows, setTableRows] = useState<TableRowType[]>([]);

  // General Filters
  const [filters, setFilters] = useState<DefaultFilterType[]>([]);

  // Saved Filters
  const [savedFilters, setSavedFilters] = useState<SavedFilterType[]>([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<number | null>(
    null
  );

  // Special Filters
  const [specialFilters, setSpecialFilters] = useState<SpecialFilterType>({});
  const [selectedSpecialFilter, setSelectedSpecialFilter] = useState<
    number | null
  >(null);

  const [filterSidebarMode, setFilterSidebarMode] =
    useState<FilterModeType>(null);

  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  // Search
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tempSearchTerm, setTempSearchTerm] = useState("");

  // Sorting
  const [sortBy, setSortBy] = useState<SortType>({});

  const [isSaveAs, setIsSaveAs] = useState<boolean>(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState({
    filtered: 0,
    total: 0,
  });

  // Anchor Elements
  const [updateTableHeadersEl, setUpdateTableHeadersEl] =
    useState<null | HTMLElement>(null);
  const [filterDropdownEl, setFilterDropdownEl] = useState<null | HTMLElement>(
    null
  );

  // Modals
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [openFilterSaveModal, setOpenFilterSaveModal] =
    useState<boolean>(false);

  const [openNewTicket, setOpenNewTicket] = useState<boolean>(false);

  // Ref to track the first render
  const isFirstRender = useRef(true);

  // Heights
  const tableHeight = useAvailableHeight(170);
  const sidebarHeight = useAvailableHeight(116);

  // Derived States
  let updatedTableHeaders = tableHeaders.filter(
    (header) =>
      header.code !== "id" &&
      header.dataType !== "rich_text" &&
      header?.sequence &&
      header.sequence > 0
  );

  const fixedColumns = ["ticketUniqueId", "status", "summary"];

  // Move fixed columns to the start
  const fixedHeaders = fixedColumns
    .map((col) => updatedTableHeaders.find((header) => header.code === col))
    .filter((header): header is TableHeaderCellType => header !== undefined);

  const remainingHeaders = updatedTableHeaders.filter(
    (header) => !fixedColumns.includes(header.code)
  );

  updatedTableHeaders = [...fixedHeaders, ...remainingHeaders];

  const isMetadataAvailable = tableHeaders.length > 0;

  let recordsCountType: "ALL" | "SOME" | "NONE" = "NONE";

  if (isFilterApplied) {
    recordsCountType = "SOME";
  } else if (recordsCount.total > 0) {
    recordsCountType = "ALL";
  }

  const selectedFilters = getSelectedFilters(filters);
  const appliedFilters = getAppliedFilters(selectedFilters);

  const resetFilter = () => {
    // Clear filters
    setFilters(clearFilterSelections(filters));
    setSelectedSavedFilter(null);
    setSelectedSpecialFilter(null);

    setIsFilterApplied(false);

    // Fetch Data
    resetPageData();
    fetchMetadata({
      filterId: undefined,
      specialFilterId: undefined,
      generalFilters: undefined,
    });
    fetchRecordsData(true, {
      filterId: undefined,
      specialFilterId: undefined,
      generalFilters: undefined,
    });
  };

  useEffect(() => {
    fetchMetadata();
    fetchRecordsData();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      return; // Skip the effect on the first render
    }

    resetPageData();
    fetchRecordsData(true, {
      search: searchTerm,
    });
  }, [searchTerm]);

  useEffect(() => {
    if (isFirstRender.current) {
      return; // Skip the effect on the first render
    }

    resetPageData();
    fetchRecordsData(true, {
      sortBy: sortBy?.key,
      sortDir: sortBy?.direction,
    });
  }, [sortBy]);

  // API calls
  const fetchMetadata = (filterDataParams: RecordsFilterType = {}) => {
    toggleLoader(true);

    const filterData: RecordsFilterType = {};

    if (filterSidebarMode === "custom" && selectedSavedFilter !== null) {
      filterData.filterId = selectedSavedFilter;
    } else if (
      filterSidebarMode === "special" &&
      selectedSpecialFilter !== null
    ) {
      filterData.specialFilterId = selectedSpecialFilter;
    }

    const updatedFilterData = { ...filterData, ...filterDataParams };

    fetchListViewMetaDataApi(updatedFilterData)
      .then((res) => {
        // Table header data
        setTableHeaders(res.fieldsList);

        // Default filters
        setFilters(res.defaultFilters);

        // Saved filters
        setSavedFilters(res.savedFilters);
        setSelectedSavedFilter(res.selectedFilterId);

        // Special Filters
        setSpecialFilters(res.specialFilters);
        setSelectedSpecialFilter(res.selectedSpecialFilterId);
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  const fetchRecordsData = async (
    reset = false,
    filterDataParams: RecordsFilterType = {},
    type: "fetchData" | "export" = "fetchData"
  ) => {
    if (!reset && (isLoading || !hasMore)) return; // Prevent duplicate requests
    setIsLoading(true);
    toggleLoader(true);

    const filterData: RecordsFilterType = {
      page: reset ? 0 : currentPage,
      pageSize: 500,
      sortBy: sortBy?.key,
      sortDir: sortBy?.direction,
      search: searchTerm,
    };

    if (isFilterApplied) {
      if (filterSidebarMode === "custom" && selectedSavedFilter !== null) {
        filterData.filterId = selectedSavedFilter;
      } else if (
        filterSidebarMode === "special" &&
        selectedSpecialFilter !== null
      ) {
        filterData.specialFilterId = selectedSpecialFilter;
      } else if (filterSidebarMode === "general") {
        filterData.generalFilters = appliedFilters;
      }
    }

    const updatedFilterData = { ...filterData, ...filterDataParams };

    listViewRecordsApi(type, updatedFilterData)
      .then((res) => {
        if (type === "fetchData") {
          setTableRows((prevData) =>
            reset ? res.data : [...prevData, ...res.data]
          );
          setHasMore(
            reset ? 0 < res.totalPages - 1 : currentPage < res.totalPages - 1
          );
          setCurrentPage((prevPage) => (reset ? 1 : prevPage + 1)); // Increment page for next load
          setRecordsCount({
            filtered: res.totalFilteredRecords,
            total: res.totalRecords,
          });
        } else if (type === "export") {
          handleDownload(res);
        }
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => {
        setIsLoading(false);
        toggleLoader(false);
      });
  };

  const saveTableHeaders = (params: number[]) => {
    toggleLoader(true);

    saveTableHeadersApi<number[], number[]>(params)
      .then(() => {
        resetPageData();
        fetchMetadata();
        fetchRecordsData(true);
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      })
      .finally(() => toggleLoader(false));
  };

  const fetchOptions = (
    field: string,
    callbackFn: (optionResponse?: NumericOptionType[]) => void,
    selectedIds1: number[],
    selectedIds2?: number[]
  ) => {
    fetchOptionsApi<NumericOptionType[]>(field, selectedIds1, selectedIds2)
      .then((res) => {
        callbackFn(res);
      })
      .catch((err) => {
        if (isCustomError(err)) {
          const apiError = err as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          err?.id
            ? setErrorDesc(err.id)
            : setErrorDesc("errors.somethingWrong");
        }
        setOpenErrorModal(true);
      });
  };

  const updateFilterOptionsAndSelections = (
    filterItem: DefaultFilterType,
    selectedIds: number[]
  ) => {
    // Update filter selections
    let updatedFilterData = updateFilterSelectionsById(
      filters,
      filterItem.id,
      selectedIds
    );

    if (filterItem.code === "assetGroup") {
      if (selectedIds.length > 0) {
        fetchOptions(
          "ASSETCATEGORY",
          (optionResponse) => {
            const updateResult = updateFilterOptionsByCode(
              updatedFilterData,
              "assetCategory",
              optionResponse ?? []
            );

            updatedFilterData = updateResult.updatedData;

            if (!updateResult.isSelectionExists) {
              updatedFilterData = updateFilterOptionsByCode(
                updatedFilterData,
                "asset",
                []
              ).updatedData;
            }

            setFilters(updatedFilterData);
          },
          selectedIds
        );
      } else {
        updatedFilterData = updateFilterOptionsByCode(
          updatedFilterData,
          "assetCategory",
          []
        ).updatedData;

        updatedFilterData = updateFilterOptionsByCode(
          updatedFilterData,
          "asset",
          []
        ).updatedData;

        setFilters(updatedFilterData);
      }
    } else if (filterItem.code === "assetCategory") {
      const selectedAssetGroupIds =
        updatedFilterData.find((item) => item.code === "assetGroup")
          ?.selectedOptionIds ?? [];

      if (selectedAssetGroupIds.length > 0 && selectedIds.length > 0) {
        fetchOptions(
          "ASSET",
          (optionResponse) => {
            updatedFilterData = updateFilterOptionsByCode(
              updatedFilterData,
              "asset",
              optionResponse ?? []
            ).updatedData;

            setFilters(updatedFilterData);
          },
          selectedAssetGroupIds,
          selectedIds
        );
      } else {
        updatedFilterData = updateFilterOptionsByCode(
          updatedFilterData,
          "asset",
          []
        ).updatedData;
        setFilters(updatedFilterData);
      }
    } else {
      setFilters(updatedFilterData);
    }
  };

  // Debounced handler
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(() => value);
    }, 400),
    []
  );

  useEffect(() => {
    // Cancel the debounce when the component unmounts
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onSearchChange = (value: string) => {
    setTempSearchTerm(value); // Immediate update
    debouncedSearch(value); // Debounced update
  };

  const handleDeleteFilter = (id: number) => {
    toggleLoader(true);
    deleteFilterDataApi("filter", id)
      .then(() => {
        setFilterSidebarMode(null);
        resetFilter();
      })
      .catch((error) => {
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      })
      .finally(() => toggleLoader(false));
  };

  const handleConfirmDelete = useCallback(() => {
    if (selectedSavedFilter) {
      handleDeleteFilter(selectedSavedFilter);
    }
    setWarningModal(false);
  }, [selectedSavedFilter]);

  const resetPageData = () => {
    setCurrentPage(0);
    setHasMore(true);
    // setTableRows([]);
  };

  const handleSort = useCallback(
    (key: string) => {
      if (sortBy?.key === key) {
        if (sortBy?.direction === "asc") {
          setSortBy({ key, direction: "desc" });
        } else if (sortBy?.direction === "desc") {
          setSortBy({}); // reset sort
        } else {
          setSortBy({ key, direction: "asc" });
        }
      } else {
        setSortBy({ key, direction: "asc" });
      }
    },
    [sortBy]
  );

  const fixedHeaderContent = () => (
    <TableRow>
      {updatedTableHeaders.map((header) => {
        let cellWidth = undefined;

        if (header.code === "reporterEmail") {
          cellWidth = "280px !important";
        } else if (["site", "subGroup", "assetGroup"].includes(header.code)) {
          cellWidth = "300px !important";
        } else if (
          [
            "severity",
            "priority",
            "state",
            "channel",
            "targetDate",
            "closedDate",
          ].includes(header.code)
        ) {
          cellWidth = "150px !important";
        }

        return (
          <TableCell
            key={header.code}
            variant="head"
            className="table-header-cell"
            sx={{
              width: cellWidth,
            }}
          >
            <Box className="table-header-box">
              <TableSortLabel
                active={sortBy?.key === header.code}
                direction={
                  sortBy?.key === header.code && sortBy?.direction
                    ? sortBy?.direction
                    : "asc"
                }
                onClick={() => handleSort(header.code)}
                className="table-sort-label"
              >
                <MediumTypography
                  label={header.name ?? ""}
                  className="table-header-cell-value"
                />
              </TableSortLabel>
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );

  const rowContent = (_index: number, row: TableRowType) => (
    <>
      {updatedTableHeaders.map((header) => {
        let cellValue = "";

        if (row[header.code]) {
          if (
            header.code === "assignee" ||
            header.code === "reporterId" ||
            header.code === "modifiedBy"
          ) {
            cellValue = (row[header.code] as UserType)?.name ?? "Unassigned";
          } else if (
            header.code === "severity" ||
            header.code === "priority" ||
            header.code === "state" ||
            header.code === "channel"
          ) {
            cellValue = (row[header.code] as UserType)?.name ?? "";
          } else if (header.code === "status") {
            cellValue =
              (row[header.code] as Partial<StatusDataType>)?.name ?? "";
          } else if (header.dataType === "duration") {
            cellValue =
              moment
                .duration(row[header.code] as string)
                .asHours()
                .toString() + " Hours";
          } else {
            cellValue = row[header.code]?.toString() ?? "";
          }
        }

        let textComponent = (
          <TruncatedTableCell text={cellValue} column={header.code} />
        );

        let content;

        if (
          row[header.code] &&
          (header.code === "assignee" ||
            header.code === "reporterId" ||
            header.code === "modifiedBy")
        ) {
          content = (
            <Box
              className="display-flex-center"
              sx={{ opacity: (row[header.code] as UserType)?.name ? 1 : 0.3 }}
            >
              <Avatar
                src={(row[header.code] as UserType)?.image ?? ""}
                alt="Profile Photo"
                className="small-avatar"
              />
              <Box className="ml-sm">{textComponent}</Box>
            </Box>
          );
        } else if (row[header.code] && header.code === "ticketUniqueId") {
          content = (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Box className="named-icon">
                {row?.["type"] ? (
                  <Tooltip
                    title={
                      <MediumTypography
                        label={(row?.["type"] as string) ?? ""}
                      />
                    }
                    arrow
                    placement="top"
                  >
                    <Box>
                      <MediumTypography
                        label={generateCode(row?.["type"] as string)}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <MediumTypography
                    label={generateCode(row?.["type"] as string)}
                  />
                )}
              </Box>
              <Link
                to={TICKET + "/" + row[header.code]}
                className="ticket-anchor ticket-link"
                target="_blank"
              >
                <MediumTypography labelId={row[header.code] as string} />
              </Link>
            </Box>
          );
        } else if (row[header.code] && header.code === "status") {
          content = (
            <Box
              sx={{
                padding: "4px 8px 3px",
                borderRadius: "2px",
                bgcolor: convertToRGBA(
                  (row[header.code] as Partial<StatusDataType>)?.colour,
                  0.1
                ),
              }}
              display={"inline-block"}
            >
              <MediumTypography
                label={cellValue}
                textColor={
                  (row[header.code] as Partial<StatusDataType>)?.colour
                }
              />
            </Box>
          );
        } else if (
          row[header.code] &&
          (header.code === "severity" ||
            header.code === "priority" ||
            header.code === "state" ||
            header.code === "channel")
        ) {
          content = (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Box
                sx={{ width: "24px", height: "24px" }}
                display={"flex"}
                alignItems={"center"}
              >
                {(row[header.code] as UserType)?.image && (
                  <img
                    width={"100%"}
                    src={(row[header.code] as UserType).image}
                    alt=""
                  />
                )}
              </Box>
              <MediumTypography
                label={cellValue}
                className="table-cell-value"
              />
            </Box>
          );
        } else {
          content = textComponent;
        }

        return (
          <TableCell
            key={header.code}
            sx={{
              backgroundColor: _index % 2 === 0 ? "#2B3033" : "#22272B",
            }}
            className="table-body-cell"
          >
            {content}
          </TableCell>
        );
      })}
    </>
  );

  const getFilterHeaderUI = useCallback(() => {
    if (filterSidebarMode === "custom")
      return (
        <MediumTypography
          label={
            savedFilters.find((item) => item.id === selectedSavedFilter)
              ?.name ?? "SAVED FILTER"
          }
          defaultLabel="SAVED FILTER"
          sxProps={{ color: "rgba(117, 117, 117, 1)", mb: 1 }}
        />
      );

    if (filterSidebarMode === "special")
      return (
        <MediumTypography
          labelId="special_filters_cap"
          defaultLabel="SPECIAL FILTERS"
          sxProps={{ color: "rgba(117, 117, 117, 1)", mb: 1 }}
        />
      );

    return (
      <MediumTypography
        labelId="filters"
        defaultLabel="FILTERS"
        sxProps={{ color: "rgba(117, 117, 117, 1)", mb: 1 }}
      />
    );
  }, [filterSidebarMode, savedFilters, selectedSavedFilter]);

  const getFilterBodyUI = () => {
    if (filterSidebarMode === "general" || filterSidebarMode === "custom")
      return filters.map((filter) => (
        <Box key={filter.id} className="filter-box" onClick={() => {}}>
          <MediumTypography
            label={filter.name}
            sxProps={{ color: "rgba(159, 173, 188, 1)", mb: 1 }}
          />
          <CustomDropdown
            labelId="accounts.selectPlaceholder"
            defaultLabel="--- select ---"
            overrideHeight="auto"
            multiple={true}
            disableSearch={false}
            disableCreate={true}
            showImage={
              filter.code === "assignee" ||
              filter.code === "reporterId" ||
              filter.code === "reporterEmail"
            }
            showIcon={
              filter.code === "severity" ||
              filter.code === "priority" ||
              filter.code === "state" ||
              filter.code === "channel"
            }
            options={getOptions(filter.values)}
            selectedValue={filter.selectedOptionIds}
            onSelectedValueChange={(value) => {
              const selectedIds = convertToNumberArray(value);
              updateFilterOptionsAndSelections(filter, selectedIds);
            }}
          />
        </Box>
      ));

    if (filterSidebarMode === "special")
      return (
        <Box
          className="special-filter-box ticket-list-box"
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          {Object.keys(specialFilters).map((specialFilterKey) => (
            <Box key={specialFilterKey}>
              <MediumTypography
                labelId={specialFilterKey}
                defaultLabel={specialFilterKey}
                sxProps={{ color: "rgba(159, 173, 188, 1)", pb: 1 }}
              />
              <List>
                {specialFilters?.[specialFilterKey]?.map((filter) => (
                  <ListItem key={filter.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setSelectedSpecialFilter(filter.id);

                        setIsFilterApplied(true);

                        // Fetch Data
                        resetPageData();
                        fetchMetadata({
                          filterId: undefined,
                          specialFilterId: filter.id,
                          generalFilters: undefined,
                        });
                        fetchRecordsData(true, {
                          filterId: undefined,
                          specialFilterId: filter.id,
                          generalFilters: undefined,
                        });
                      }}
                      selected={selectedSpecialFilter === filter.id}
                      className="filter-list-item"
                    >
                      <ListItemText
                        primary={
                          <MediumTypography
                            label={filter.name}
                            sxProps={{
                              color: "rgba(159, 173, 188, 1)",
                            }}
                          />
                        }
                        sx={{
                          color:
                            selectedSpecialFilter === filter.id
                              ? "primary.main"
                              : "text.primary",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      );

    return <></>;
  };

  const getFilterFooterUI = () => {
    if (filterSidebarMode === "general") {
      return (
        <Box className="sidebar-form-button">
          <Divider />
          <Box display={"flex"} justifyContent={"space-evenly"} mt={2}>
            <ButtonComponent
              className="btn-primary btn-ticket-text"
              variantType="contained"
              defaultLabelId={"Save"}
              labelId={"Save"}
              onClick={() => {
                setOpenFilterSaveModal(true);
              }}
              disabled={selectedFilters.length === 0}
            />
            <ButtonComponent
              className="btn-primary btn-ticket"
              variantType="contained"
              defaultLabelId={"Apply"}
              labelId={"Apply"}
              onClick={() => {
                setIsFilterApplied(true);

                // Fetch Data
                resetPageData();
                fetchRecordsData(true, {
                  filterId: undefined,
                  specialFilterId: undefined,
                  generalFilters: appliedFilters,
                });
              }}
              disabled={selectedFilters.length === 0}
            />
          </Box>
        </Box>
      );
    } else if (filterSidebarMode === "custom") {
      return (
        <Box className="sidebar-form-button">
          <Divider />
          <Box display={"flex"} justifyContent={"space-evenly"} mt={2}>
            <ButtonComponent
              className="btn-primary btn-ticket-text btn-delete"
              variantType="contained"
              defaultLabelId={"Delete"}
              labelId={"planner.deleteText"}
              onClick={() => {
                setWarningModal(true);
              }}
            />
            <ButtonComponent
              className="btn-primary btn-ticket"
              variantType="contained"
              defaultLabelId={"Save"}
              labelId={"Save"}
              onClick={() => {
                setOpenFilterSaveModal(true);
              }}
              disabled={selectedFilters.length === 0}
            />
            <ButtonComponent
              className="btn-primary btn-ticket"
              variantType="contained"
              defaultLabelId={"Save As"}
              labelId={"btn.saveAs"}
              onClick={() => {
                setIsSaveAs(true);
                setOpenFilterSaveModal(true);
              }}
              disabled={selectedFilters.length === 0}
            />
          </Box>
        </Box>
      );
    }
    return null;
  };

  const handleDownload = (responseData: any) => {
    try {
      const timestamp = moment().format("YYYY-MM-DD HH-mm");
      const filename = `Ticket List ${timestamp}.csv`;

      const blob = new Blob([responseData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      // Trigger download without appending to the DOM
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
    } catch (error) {
      setOpenErrorModal(true);
      setErrorDesc("somethingWrongError");
    }
  };

  useEffect(() => {
    // Mark the first render as complete after all useEffects are initialized
    isFirstRender.current = false;
  }, []);

  return (
    <Box id="ticketing_list_view" className="ticketing-list-view">
      {openNewTicket && (
        <CreateTicketModal
          handleClose={() => {
            setOpenNewTicket(false);
          }}
          createCallback={() => {
            setOpenNewTicket(false);
            resetPageData();
            fetchRecordsData(true);
          }}
          open={openNewTicket}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openFilterSaveModal && (
        <FilterSaveModal
          open={openFilterSaveModal}
          selectedFilter={
            isSaveAs
              ? undefined
              : savedFilters.find((item) => item.id === selectedSavedFilter)
          }
          subGroupIds={
            filters
              .find((item) => item.code === "subGroup")
              ?.values?.map((item) => item.id) || []
          }
          handleClose={() => {
            setIsSaveAs(false);
            setOpenFilterSaveModal(false);
          }}
          filterData={selectedFilters}
          saveCallback={(savedFilter) => {
            setSavedFilters((prevFilters) => {
              let isReplaced = false;
              const newFilters = prevFilters.map((item) => {
                if (item.id === savedFilter.id) {
                  isReplaced = true;
                  return savedFilter;
                }
                return item;
              });

              if (!isReplaced) return [...newFilters, savedFilter];
              return newFilters;
            });

            setFilterSidebarMode("custom");
            setSelectedSavedFilter(savedFilter.id);
            setSelectedSpecialFilter(null);
            setFilterDropdownEl(null);

            setIsFilterApplied(true);

            // Fetch Data
            resetPageData();
            fetchMetadata({
              filterId: savedFilter.id,
              specialFilterId: undefined,
              generalFilters: undefined,
            });
            fetchRecordsData(true, {
              filterId: savedFilter.id,
              specialFilterId: undefined,
              generalFilters: undefined,
            });
          }}
        />
      )}
      {warningModal && (
        <ModalPopup
          descriptionText={"filter.deleteConfirmation"}
          open={warningModal}
          handleClose={() => setWarningModal(false)}
          onPositiveClick={handleConfirmDelete}
          onNegativeClick={() => {
            setWarningModal(false);
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}

      <Box className="main-content">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          className="mb-md"
          gap={2}
        >
          <Box>
            {isMetadataAvailable && (
              <SearchBox
                labelId="ticket.search"
                defaultlabel="Search by ID / Summary"
                backgroundColor="#22272B"
                sxProps={{
                  width: "auto",
                  minWidth: "300px",
                }}
                onChange={onSearchChange}
                value={tempSearchTerm}
              />
            )}
          </Box>
          <Box display={"flex"} gap={4} alignItems={"center"}>
            <Box>
              {recordsCountType === "ALL" && (
                <MediumTypography
                  labelId="listView.allCountsText"
                  labelValues={{
                    totalCount: recordsCount.total,
                  }}
                  defaultLabel={`Showing total ${recordsCount.total} records`}
                  className="header-matched-records-text"
                />
              )}
              {recordsCountType === "SOME" && (
                <MediumTypography
                  labelId="listView.someCountsText"
                  labelValues={{
                    filteredCount: recordsCount.filtered,
                    totalCount: recordsCount.total,
                  }}
                  defaultLabel={`Showing ${recordsCount.filtered} matched records / total ${recordsCount.total} records`}
                  className="header-matched-records-text"
                />
              )}
            </Box>

            {isMetadataAvailable && (
              <>
                <Box
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (isFilterApplied && filterSidebarMode === null) {
                      if (selectedSavedFilter) {
                        setFilterSidebarMode("custom");
                      } else if (selectedSpecialFilter) {
                        setFilterSidebarMode("special");
                      } else {
                        setFilterSidebarMode("general");
                      }
                    } else {
                      setFilterDropdownEl(event.currentTarget);
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {!isFilterApplied && (
                    <FilterListIcon className="header-filter-icon" />
                  )}
                  {isFilterApplied && (
                    <FilterAltIcon className="header-filter-icon applied" />
                  )}
                  <MediumTypography
                    labelId="filter"
                    defaultLabel="Filter"
                    className="header-filter-text"
                    sxProps={{
                      color: isFilterApplied ? "#25bafa !important" : undefined,
                    }}
                  />
                </Box>
                <FilterDropdown
                  options={savedFilters}
                  anchorEl={filterDropdownEl}
                  handleClose={() => setFilterDropdownEl(null)}
                  open={Boolean(filterDropdownEl)}
                  selectedFilter={selectedSavedFilter}
                  onFilterChange={(value: number) => {
                    // Custom Filter (Saved)
                    setFilterSidebarMode("custom");
                    setSelectedSavedFilter(value);
                    setSelectedSpecialFilter(null);
                    setFilterDropdownEl(null);

                    setIsFilterApplied(true);

                    // Fetch Data
                    resetPageData();
                    fetchMetadata({
                      filterId: value,
                      specialFilterId: undefined,
                      generalFilters: undefined,
                    });
                    fetchRecordsData(true, {
                      filterId: value,
                      specialFilterId: undefined,
                      generalFilters: undefined,
                    });
                  }}
                  onCreateClick={() => {
                    // General Filter
                    setIsFilterApplied(false);
                    setFilters(clearFilterSelections(filters));
                    setFilterSidebarMode("general");
                    setSelectedSavedFilter(null);
                    setSelectedSpecialFilter(null);
                    setFilterDropdownEl(null);
                  }}
                  onSpecialClick={() => {
                    // Special Filter
                    setIsFilterApplied(false);
                    setFilters(clearFilterSelections(filters));
                    setFilterSidebarMode("special");
                    setSelectedSavedFilter(null);
                    setFilterDropdownEl(null);
                  }}
                />

                {isFilterApplied && (
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    onClick={() => {
                      resetFilter();
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <FilterAltOffIcon className="header-filter-icon" />
                    <MediumTypography
                      labelId="common.reset"
                      defaultLabel="Reset"
                      className="header-filter-text"
                    />
                  </Box>
                )}

                <Box
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                  onClick={() => {
                    fetchRecordsData(
                      true,
                      { page: 0, pageSize: 100000 },
                      "export"
                    );
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <DownloadIcon className="header-filter-icon" />
                  <MediumTypography
                    labelId="file.export"
                    defaultLabel="Export"
                    className="header-filter-text"
                  />
                </Box>
              </>
            )}

            {hasTicketEditPermission() && (
              <ButtonComponent
                className="btn-primary btn-ticket"
                variantType="contained"
                defaultLabelId={"New Ticket"}
                labelId={"btn.newTicket"}
                onClick={() => {
                  setOpenNewTicket(true);
                }}
              />
            )}

            {isMetadataAvailable && (
              <>
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setUpdateTableHeadersEl(event.currentTarget);
                  }}
                  sx={{ padding: 0 }}
                >
                  <More className="header-filter-icon more-icon" />
                </IconButton>
                <TableHeaderSelection
                  data={tableHeaders}
                  anchorEl={updateTableHeadersEl}
                  handleClose={() => setUpdateTableHeadersEl(null)}
                  open={Boolean(updateTableHeadersEl)}
                  onApply={(selectedHeaders) => {
                    saveTableHeaders(
                      selectedHeaders.flatMap((item) => item.id)
                    );
                  }}
                  fixedItems={fixedColumns}
                />
              </>
            )}
          </Box>
        </Box>
        <Paper
          sx={{ height: tableHeight, width: "100%" }}
          className="table-paper"
        >
          {tableRows.length > 0 && (
            <TableVirtuoso
              data={tableRows}
              components={{
                ...VirtuosoTableComponents,
                Table: (props) => (
                  <Table
                    {...props}
                    sx={{
                      borderCollapse: "separate",
                      tableLayout: "fixed",
                    }}
                    className="table-paper multi-sticky-columns-table"
                  />
                ),
              }}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={(index, row) => rowContent(index, row)}
              endReached={() => {
                fetchRecordsData();
              }} // Trigger loading when the end is reached
            />
          )}

          {/* {!loader && isLoading && (
            <Box className="ticketNotAvialable">
              <MediumTypography
                className="noTicketTitle"
                labelId="loadingText"
                defaultLabel={`Loading...`}
              />
            </Box>
          )} */}

          {!isFirstRender.current &&
            !loader &&
            !isLoading &&
            tableRows.length === 0 && (
              <Box className="ticketNotAvialable">
                <NotAvialableIcon width={`50px`} height={`50px`} />
                <MediumTypography
                  className="noTicketTitle"
                  labelId="listView.noneCountsText"
                  defaultLabel={`No tickets found`}
                />
              </Box>
            )}
        </Paper>
      </Box>
      {filterSidebarMode && (
        <Box
          className="right-sidebar"
          sx={{
            height: sidebarHeight,
            overflow: "scroll",
          }}
        >
          <Box>
            <Box className="sidebar-filter-header">
              <Box display={"flex"} justifyContent={"space-between"}>
                {getFilterHeaderUI()}
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setFilterSidebarMode(null);
                  }}
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
            </Box>
            <Box>
              <Box display={"flex"} gap={2} flexDirection={"column"} mt={2}>
                {getFilterBodyUI()}
              </Box>
            </Box>
          </Box>
          {getFilterFooterUI()}
        </Box>
      )}
    </Box>
  );
}
